import { GrGroup } from "react-icons/gr";
import { IoDocumentTextOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../Button";

export const StudentTable = styled.table`
  font-family: "Roboto";
  border-collapse: collapse;
  width: 100%;
  padding: 10px;

  border-radius: 10px;

  margin-top: 29px;
  background-color: #d9d9d9;

  &tbody {
    border-radius: 10px;
    overflow: hidden;
  }
  & td,
  th {
    padding: 8px;
    border-radius: 10px;
  }
  /* tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  & tr:hover {
    background-color: #ddd;
  } */

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #d9d9d9;
  }
`;

export const CheckBoxInput = styled.input``;

export const AddStudentLabel = styled.label`
  font-family: "Roboto";
  border-collapse: collapse;

  & ~ & {
    background-color: #f2f2f2;
  }
`;

export const AddStudentButton = styled(Button)``;

//ButtonsContainer
export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center;
  justify-content: space-evenly; */

  max-width: 800px;

  margin-top: 10px;
`;

export const AddFileInput = styled.input.attrs({
  type: "file",
})`
  margin-left: 10px;
`;

export const CoursePanel = styled.div`
  box-sizing: border-box;
  width: 322px;
  height: 194px;
  background-color: #9a5656;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0px 0px;
  transition: background-color 0.3s ease-in-out;
  //
`;
export const CoursePanelHeader = styled.div`
  color: white;
  font-size: x-large;
  font-family: "Roboto";
  height: 105px;
  text-align: center;
  position: relative;
`;
export const CoursePanelFootr = styled.div`
  height: 87px;
  left: 0px;
  bottom: -86px;

  background: #ecf0f1;
  display: flex;
  flex-direction: row;

  color: black;
`;

export const CourseDetailed = styled.div`
  width: 50%;
  justify-content: center;

  padding: 10px 0 0 10px;
`;

export const CourseDetailPt2 = styled.div`
  float: right;
  width: 50%;
  padding: 10px 0 0 20px;
`;

export const StudentIcon = styled(GrGroup)`
  font-size: x-large;
  padding-left: 30px;
`;
export const StudentAssignmentIcon = styled(IoDocumentTextOutline)`
  font-size: x-large;
  padding-left: 30px;
`;

export const LinkAllCourse = styled(NavLink)`
  text-align: end;
  color: grey;
  text-decoration: none;
  padding: 1em
`;

export const CourseLayoutContainer = styled.div`
  width: 1200px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const PreviousButtonContainer = styled.div`
  display: flex;
  margin: 10px;
  position: sticky;
  bottom: 20px;
`;

// type ColorPickerProps = {
//   value: string;
//   onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   onClose: () => void;
// };

export const ColorPickerContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ColorPickerInput = styled.input`
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const ColorPickerButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const AddStudentInput = styled.input`
  box-sizing: border-box;
  text-align: center;
  width: 250px;
  height: 35px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
  margin: 10px;
  transition: all 0.3s ease 0s;
`;

export const NewStudentLabel = styled.label`
  font-family: "Roboto";
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

//CourseTab Management

export const CourseTabPanel = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 50px 20px 50px;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.div<TabProps>`
  cursor: pointer;
  /* background-color: #d9d9d9; */
  background-color: ${(props) => (props.active ? "#2E6EA5" : "#d9d9d9")};
  color: ${(props) => (props.active ? "white" : "black")};
  padding: 20px;
  font-weight: 700;
  display: flex;
  border-radius: 9px;
`;

export const TabContent = styled.div`
  padding: 10px;
  border: 1px solid gray;
  border-top: none;
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  /* padding: 20px; */
`;
export const PanelBody = styled.div`
  width: 100%;
  max-width: 790px;
  box-sizing: border-box;
  padding: 20px;
`;

// background-color: ${(props) => (props.active ? "gray" : "lightgray")};
// color: ${(props) => (props.active ? "white" : "black")};

//table-div
export const ResponsiveTable = styled.ul`
  padding: 0;
  li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #d9d9d9;

    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }
  .col-1 {
    flex-basis: 20%;
  }
  .col-2 {
    flex-basis: 25%;
  }
  .col-3 {
    flex-basis: 20%;
  }
  .col-4 {
    flex-basis: 30%;
  }

  @media all and (max-width: 900px) {
    .table-header {
      display: none;
    }
    .table-row {
    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
`;
// export const TableHeader = styled.li`
//   background-color: #34495e;
//   font-size: 14px;
//   text-transform: uppercase;
//   letter-spacing: 0.03em;
// `;
