import { apiSlice } from "../api-slice";
import { Tag } from "../types";
import { Course } from "./types";

export const studentCourseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourses: builder.query<Course[], void>({
      query: () => ({ url: `student/course` }),
      transformResponse(res: { data: Course[] }, meta, arg) {
        return res.data;
      },
      providesTags: [Tag.COURSE],
    }),
    getRecentCourses: builder.query<Course[], void>({
      query: () => ({ url: `student/course/recent` }),
      transformResponse(res: { data: Course[] }, meta, arg) {
        return res.data;
      },
      providesTags: [Tag.COURSE],
    }),
    getCourse: builder.query<Course, number | string>({
      query: (courseId) => ({
        url: `student/course/${courseId}`,
      }),
      transformResponse(res: { data: Course }, meta, arg) {
        return res.data;
      },
      providesTags: (result, error, courseId) => {
        const tags = [{ type: Tag.COURSE, id: courseId }];
        if (result)
          tags.push({ type: Tag.COURSE_TEMPLATE, id: result.template.id });
        return tags;
      },
    }),
  }),
});

export const {
  useGetCourseQuery,
  useGetRecentCoursesQuery,
  useGetCoursesQuery,
} = studentCourseApiSlice;
