
const colors = ["red","green","blue","orange","violet"]
const highlight = [" #ffb7b7", "#a8d1ff", "#fff2a8", " #E0BBE4", "#8ABF96"]
const formats = [
    [
        {
            className:"ql-font",
            options:['serif','monospace']
        },
        {
            className:"ql-size",
            options:["small","large","huge"]
        }
    ],
    [
        {className:"ql-bold"},{className:"ql-italic"},{className:"ql-underline"},{className:"ql-strike"}
    ],
    [
        {
            className:"ql-color",
            options:colors
        },
        {
            className:"ql-background",
            options:highlight
        }
    ],
  
    [
        {
            className:"ql-list",
            value:"ordered"
        },
        {
            className:"ql-list",
            value:"bullet"
        },
        {
            className:"ql-indent",
            value:"-1"
        },
        {
            className:"ql-indent",
            value:"+1"
        }
    ],
    [
        {
            className:'ql-align',
            options:['right','center','justify']
        }
    ],
    [
        {className:'ql-link'},{className:'ql-image'},{className:'ql-formula'}
    ],
    

]

export default formats