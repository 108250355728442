import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { NextButton, PreviusButton } from "../../../../components/Button/Button";
import QuestionHeader from "../../../../components/QuestionHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import SectionHeader from "../../../../components/SectionHeader";
import { Step, StepInput } from "../../../../components/SolutionStep";
import {
  getAssignmentStatus,
  useGetStudentAssignmentQuery,
  useSubmitAttemptMutation,
  Problem as ProblemType,
  Assignment,
} from "../../../../features/api/student/assignment";
import { selectInputExpression, selectMessageDisplay } from "../../../../features/topic/selectors";
import { setProblemInput, setProblemMessageDisplay } from "../../../../features/topic/topic-slice";
import { getExamplePage, getProblemPage } from "../NavButtons";
import { ButtonsContainer, Container, StepsContainer } from "./style";
import { App, Result, Spin } from "antd";
import { useState } from "react";
import { set } from "msw/lib/types/context";

export default function ({ assignmentId }: { assignmentId: number }) {
  const params = useParams();
  const problemNumber = Number(params.problemNo);
  const navigate = useNavigate();

  const {
    data: assignment,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentAssignmentQuery(assignmentId);

  if (isSuccess) {
    const { examples, problems } = assignment;

    // when problem number out of range
    if (problemNumber && !(problemNumber > 0 && problemNumber <= problems.length)) {
      navigate(getProblemPage(assignmentId, 1));
    }

    return (
      <>
        {problems.map((problem, index) => (
          <Problem
            key={index}
            problemIndex={index}
            problem={problem}
            assignment={assignment}
            visible={index === problemNumber - 1}
          />
        ))}
        <ButtonsContainer>
          <PreviusButton
            onClick={() => {
              const prev = problemNumber - 1;
              if (prev > 0) navigate(getProblemPage(assignmentId, prev));
              else {
                navigate(getExamplePage(assignmentId, examples.length));
              }
            }}
          >
            Back
          </PreviusButton>
          {problemNumber < problems.length && (
            <NextButton
              onClick={() => {
                navigate(getProblemPage(assignmentId, problemNumber + 1));
              }}
            >
              Next
            </NextButton>
          )}
        </ButtonsContainer>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <Spin size="large" />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container>
        <Result status="warning" title="We encountered problem loading your assignment." />
      </Container>
    );
  }

  return (
    <Container>
      <Result status="error" title="Unexpected error occurred. Please try again." />
    </Container>
  );
}

export function Problem({
  problemIndex,
  problem,
  assignment,
  visible,
}: {
  problemIndex: number;
  problem: ProblemType;
  assignment: Assignment;
  visible: boolean;
}) {
  const { message } = App.useApp();
  const dispatch = useAppDispatch();
  const [submitAttempt, { isLoading: isSubmitting }] = useSubmitAttemptMutation();

  const currentInput = useAppSelector((state) =>
    selectInputExpression(state, {
      topicId: assignment.id.toString(),
      problemId: problem.id.toString(),
    }),
  );
  const currentMessageDisplay = useAppSelector((state) =>
    selectMessageDisplay(state, {
      topicId: assignment.id.toString(),
      problemId: problem.id.toString(),
    }),
  );

  // find index of step that is not solved yet, i.e. step that user currently solves
  let inputStepIndex;
  if (problem.status === "correct") {
    inputStepIndex = problem.totalSteps;
  } else {
    inputStepIndex = problem.steps.length - 1;
  }

  const inputStep = inputStepIndex < problem.steps.length ? problem.steps[inputStepIndex] : null;
  const [solution, setSolution] = useState("");

  const handleStepSubmit = (stepId: number, expression: string) => {
    if (getAssignmentStatus(assignment) === "open") {
      submitAttempt({
        assignmentId: assignment.id,
        problemId: problem.id,
        stepId: stepId,
        expression : solution,
      })
        .unwrap()
        .catch((e) => {
          message.error(`An error occurred when submitting your response. ${e.data.message}`);
        });
        setSolution("");
    } else {
      message.info("Assignment is closed. New submissions are not accepted");
    }
  };

  return (
    <Container style={{ display: visible ? "block" : "none" }}>
      <SectionHeader style={{ textAlign: "left", marginBottom: "40px" }}>
        Problem #{problemIndex+1}
      </SectionHeader>
      <QuestionHeader>{problem.question}</QuestionHeader>
      <SecondaryHeader style={{ marginTop: "40px" }}>Enter steps for solution</SecondaryHeader>
      <div style={{margin: "5px 20px 5px 10px", display: ""}} className={"small"}>
        <span><strong>Assignment details:</strong></span>
        <ul>
          <li>Maximum amount of steps: <strong>{problem.totalSteps}</strong></li>
          {/* {assignment.allowedAttempts && assignment.allowedAttempts > 0 && <li>Allowed attempts per step: <strong>{assignment.allowedAttempts}</strong></li>} */}
          {assignment.allowedStepsAhead && assignment.allowedStepsAhead > 0 && <li>Allowed steps ahead: <strong>{assignment.allowedStepsAhead}</strong></li>}
          {/* {assignment.similarExpTolerance && assignment.similarExpTolerance ? <li>Similar expression tolerance: <strong>allowed</strong></li>:<li>Similar expression tolerance: <strong>not allowed</strong></li>} */}
        </ul>
      </div>
      <StepsContainer>
        {problem.steps.slice(0, inputStepIndex).map((step, index) => {
          return (
            <Step
              key={index}
              id={index}
              expression={step.attempts.length ? step.attempts[step.attempts.length - 1].expression : step.status}
              styling="primary"
            ></Step>
          );
        })}
        {getAssignmentStatus(assignment) === "open" && inputStep && (
          <StepInput
            id={inputStep.id}
            index={inputStepIndex}
            expression={solution}
            isIncorrect={inputStep.attempts.length > 0} // inputStep.status === "incorrect"
            isUpdating={isSubmitting}
            hintMessage={inputStep.hint}
            mistakeMessage={
              inputStep.attempts.length > 0 // get last mistake message
                ? inputStep.attempts[inputStep.attempts.length - 1].explanation
                : undefined
            }
            messageDisplay={currentMessageDisplay}
            setMessageDisplay={(display) => {
              dispatch(
                setProblemMessageDisplay({
                  topicId: assignment.id.toString(),
                  problemId: problem.id.toString(),
                  display: display,
                }),
              );
            }}
            changeInput={setSolution}
            // changeInput={(solution: string) => {
            //   dispatch(
            //     setProblemInput({
            //       topicId: assignment.id.toString(),
            //       problemId: problem.id.toString(),
            //       expression: solution,
            //     }),
            //   );
            // }}
            onSubmit={handleStepSubmit}
          />
        )}
      </StepsContainer>
    </Container>
  );
}
