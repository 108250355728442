import { Button, Empty, Form, message, Modal, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useGetCourseTemplatesQuery } from "../../../features/api/professor/course";
import { CourseForm, OutputFormData } from "./CourseForm";
import { EditFormData } from "./CourseEdit";
import { getAllCourseTemplatePath } from "../nav";
import { useNavigate } from "react-router-dom";
import { useGetMeQuery } from "../../../features/api/user-api";
import { UserRole } from "../../../features/api/types";

interface TemplateData {
  courseTemplateId: number;
}

export type CreateFromData = TemplateData & EditFormData;

export interface ModalCourseCreateProps {
  open: boolean;
  initialValues?: Partial<CreateFromData>;
  onSubmit: (values: CreateFromData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalCourseCreate({
  open,
  initialValues,
  onSubmit,
  onCancel,
  isLoading,
}: ModalCourseCreateProps) {
  const [templateForm] = Form.useForm<TemplateData>();
  const [courseForm] = Form.useForm<OutputFormData>();

  const { courseTemplateId: initialCourseTemplateId, ...initialCourseValues } = initialValues || {};
  const courseTemplateId: number | undefined = Form.useWatch("courseTemplateId", templateForm);

  const handleSubmit = async () => {
    try {
      const templateValues = await templateForm.validateFields();
      const courseValues = await courseForm.validateFields();

      onSubmit({
        courseTemplateId: templateValues.courseTemplateId,
        semester: courseValues.semester.trim(),
        section: courseValues.section.trim(),
        year: courseValues.year.year().toString(),
        color:
          typeof courseValues.color === "string" ? courseValues.color : courseValues.color.toHex(),
        instructorId: courseValues.instructorId ? courseValues.instructorId : null,
      });
      templateForm.resetFields();
      courseForm.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="Create course"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <Form
        form={templateForm}
        name="course_template_form"
        layout="vertical"
        initialValues={{ courseTemplateId: initialCourseTemplateId }}
      >
        <TemplateSelect />
      </Form>
      <CourseForm
        form={courseForm}
        initialValues={
          initialCourseValues && {
            ...initialCourseValues,
            year: initialCourseValues.year ? dayjs(initialCourseValues.year) : undefined,
          }
        }
        disabled={isNaN(Number(courseTemplateId))}
      />
    </Modal>
  );
}

function TemplateSelect() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { data: me } = useGetMeQuery();
  const {
    data: templates,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetCourseTemplatesQuery();

  if (isError) {
    messageApi.open({
      key: "COURSE_TEMPLATE_QUERY",
      type: "error",
      content: "Unable to retrieve list of subjects",
    });
  }

  return (
    <>
      {contextHolder}
      <Form.Item
        name="courseTemplateId"
        label="Subject"
        rules={[{ required: true, message: "Please select subject!" }]}
      >
        <Select
          allowClear
          showSearch
          loading={isLoading || isFetching}
          placeholder="Please select subject"
          notFoundContent={
            <Empty
              imageStyle={{ height: 60 }}
              description={
                <div style={{ maxWidth: "300px", margin: "auto" }}>
                  No available subjects. Please, <b>create</b> subject before completing this form.
                </div>
              }
              style={{ margin: "20px" }}
            >
              {(["ROLE_ADMIN"] as UserRole[]).some((r) => me?.user.roles.includes(r)) && (
                <Button type="primary" onClick={() => navigate(getAllCourseTemplatePath())}>
                  Manage Subjects
                </Button>
              )}
            </Empty>
          }
        >
          {isSuccess &&
            templates.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
}
