import { Outlet, useOutletContext } from "react-router-dom";

export type CourseTemplateContextType = {
  canEdit: boolean;
  canCreate: boolean;
  canDelete: boolean;
};

export default function CourseTemplateContext(context: CourseTemplateContextType) {
  return <Outlet context={context} />;
}

export function useCourseTemplateContext() {
  return useOutletContext<CourseTemplateContextType>();
}
