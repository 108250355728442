import React, { useRef, useState } from "react";
import { Button, Form, FormInstance, Input, message } from "antd";
import ReactQuill from "react-quill";
import { SaveFilled } from "@ant-design/icons";
import Editor2 from "./Editor2";

export interface InputFormData {
    title: string;
    content: string;
}

export interface OutputFormData {
    title: string;
    content: string;
}

export function IntroForm2({
    form,
    initialValues,
    disabled = false,
    onCancel,
    onSubmit,
    onDone,
    isLoading = false,
}: {
    form: FormInstance<OutputFormData>;
    initialValues?: Partial<InputFormData>;
    disabled?: boolean;
    onCancel: () => void;
    onSubmit: (values: InputFormData) => void;
    onDone: (pageId: number) => void;
    isLoading: boolean;
}) {

    const [content, setContent] = useState(initialValues ? initialValues.content ? initialValues.content : "" : "");

    const quillRef = useRef<ReactQuill>(null);

    const handleSubmit = async () => {
        try {
            const introValues = await form.validateFields();
            onSubmit({
                title: introValues.title,
                content: introValues.content || content,
            });
            form.resetFields();
        } catch (info) {
            console.log("Validate Failed:", info);
        }
    };


    return (
        <Form
            form={form}
            name="intro_form"
            layout="vertical"
            initialValues={initialValues}
            disabled={disabled}
            onFinishFailed={() => message.error}
            onFinish={() => onDone}
        >
            <TitleInput />
            <ContentInput
                content={content}
                setContent={setContent}
                quillRef={quillRef}
            />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<SaveFilled rev={undefined} />}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="default"
                        onClick={onCancel}
                        danger
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
}

function TitleInput() {
    return (
        <Form.Item
            name="title"
            label="Introduction Title"
            rules={[{ required: true, message: "Please input title!" }]}
        >
            <Input />
        </Form.Item>
    );
}

interface ContentInputProps {
    content: string;
    setContent: React.Dispatch<React.SetStateAction<string>>;
    quillRef: any;
}

function ContentInput({ content, setContent, quillRef }: ContentInputProps) {
    return (
        <Form.Item
            name="content"
            label="Content"
            rules={[{ required: true, message: "Please input content!" }]}
        >
            <Editor2 initialValue={content} ref={quillRef} onChange={setContent} reference={quillRef} />
        </Form.Item>
    );
}
