import { BackgroundPage, LoadingRing, LoginHeading, LoginWrapperDiv, LogoImg } from "./style";
import { useLoginUserMutation } from "../../features/api/auth-api";
import { useGetMeQuery } from "../../features/api/user-api";
import { Navigate, useNavigate } from "react-router-dom";
import { App, Button, Form, Input, Typography } from "antd";
import logo from "../../components/companyLogo.png";
import { CloseCircleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import React, { useState } from "react";

interface Values {
  email: string;
  password: string;
}

export function Login() {
  const navigate = useNavigate();
  const { message } = App.useApp();

  const [form] = Form.useForm<Values>();
  const { data: user, isLoading, isError: notLogin, isSuccess } = useGetMeQuery();
  const [login, { isLoading: isLoggingIn, error }] = useLoginUserMutation();

  const [feedback, setFeedback] = useState<LoginFeedbackData | null>(null);

  if (isSuccess) {
    return <Navigate to={"/dashboard"} />;
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        login({
          email: values.email,
          password: values.password,
        })
          .unwrap()
          .then(() => {
            message.success("Logged in!");
          })
          .catch((error) => {
            form.resetFields(["password"]);
            setFeedback({
              receivedAt: Date.now(),
              status: error.status,
              message: error.data ? error.data.message : error.message,
            });
          });
      })
      .catch(() => {
        /* validation failed */
      });
  };

  if (notLogin) {
    return (
      <BackgroundPage>
        <LogoImg src={logo} alt="logo" />
        <LoginWrapperDiv>
          <LoginHeading>Log In</LoginHeading>
          <div style={{ width: "400px" }}>
            <Form form={form} name="login_form" layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Please enter your email address!" }]}
              >
                <Input prefix={<UserOutlined rev={undefined} />} type="email" placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter your password!" }]}
              >
                <Input
                  prefix={<LockOutlined rev={undefined} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="login-form-button"
                  loading={isLoggingIn}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div>{feedback && <LoginFeedback data={feedback} />}</div>
          <Button type="link" onClick={() => navigate("/password-forgot")}>
            Forgot password
          </Button>
        </LoginWrapperDiv>
      </BackgroundPage>
    );
  }

  if (isLoading) {
    return (
      <BackgroundPage>
        <LoadingRing />
      </BackgroundPage>
    );
  }

  return (
    <BackgroundPage>
      <LoadingRing />
    </BackgroundPage>
  );
}

function LoginFeedback({ data }: { data: LoginFeedbackData }) {
  return (
    <div style={{ maxWidth: "300px" }}>
      <Typography.Text type="danger" strong>
        <CloseCircleOutlined rev={undefined} /> Unable to login
      </Typography.Text>
      {data.message ? (
        <Typography.Paragraph type="secondary">{data.message}</Typography.Paragraph>
      ) : (
        <Typography.Paragraph type="secondary">Unknown error</Typography.Paragraph>
      )}
    </div>
  );
}

interface LoginFeedbackData {
  receivedAt: number;
  status?: number;
  message?: string;
}
