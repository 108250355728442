import styled from "styled-components";

export const TopicSessionContainer = styled.div`
  position: relative;
  margin: 10px 50px 50px 50px;
  min-width: 420px;
  /* top: 603px; */

  background: rgba(194, 192, 192, 0.24);
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-around;
`;
