import { EditableMathField } from "react-mathquill";
import styled, { css, keyframes } from "styled-components";
import { MessageDisplay, StyleSet } from "../../features/topic/types";

// const borderRadius = "6px";
// const height = "40px";
const styles = {
  primary: {
    container: {
      backgroundColor: "transparent",
    },
    number: {
      backgroundColor: "#2e6ea5",
      color: "#ffffff",
    },
  },
  secondary: {
    container: {
      backgroundColor: "#e2e2e2",
    },
    number: {
      backgroundColor: "#c4c4c4",
      color: "#000000c1",
    },
  },
  success: {
    container: {
      backgroundColor: "#e2e2e2",
    },
    number: {
      backgroundColor: "#24DE58",
      color: "#ffffff",
    },
  },
  danger: {
    container: {
      backgroundColor: "#e2e2e2",
    },
    number: {
      backgroundColor: "#FF5353",
      color: "#ffffff",
    },
  },
};

type StyleProp = {
  styling?: StyleSet;
};

export const StepContainer = styled.div<StyleProp>`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  /* border-radius: 6px;
  background-color: ${({ styling = "secondary" }) => styles[styling].container.backgroundColor}; */
`;

export const StepNumber = styled.div<StyleProp>`
  z-index: 2;
  display: flex;
  flex-direction: row;
  ${({ styling = "secondary" }) => css`
    background-color: ${styles[styling].number.backgroundColor};
    color: ${styles[styling].number.color};
  `}
  padding: 0 12px;
  border-radius: 30px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
`;

export const StepBody = styled.div`
  display: flex;
  flex-direction: row;
  color: #000000;
  min-height: 40px;
  margin: 8px 0 0 20px;
  padding: 0;
`;

export const StepExpression = styled.div`
  border: 2px solid transparent;
  border-radius: 6px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 36px;
`;

type InputProps = {
  isIncorrect?: boolean;
};
export const StepInputField = styled(EditableMathField)<InputProps>`
  z-index: 5;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
  padding: 4px 16px;
  min-width: 150px;
  &:focus {
    outline: none;
    border-color: ${({ isIncorrect }) => (isIncorrect ? "#FF5353" : "#2e6ea5")};
  }
`;

export const ProblenStepInputField = styled(EditableMathField)<InputProps>`
  border: 2px solid #c4c4c4;
  border-radius: 6px;
  padding: 4px 16px;
  min-width: 50px;
  &:focus {
    outline: none;
    border-color: ${({ isIncorrect }) => (isIncorrect ? "#FF5353" : "#2e6ea5")};
  }
`;

export const StyledSubmitButton = styled.button<InputProps>`
  width: 40px;
  height: 40px;
  margin-left: 6px;
  border-radius: 40px;
  border: none;
  background-color: transparent;
  padding: 0;
  & > svg {
    height: 24px;
    fill: ${({ isIncorrect }) => (isIncorrect ? "#c4c4c4" : "#2e6ea5")};
  }
  &:hover {
    background-color: #efebeb;
    & > svg {
      fill: ${({ isIncorrect }) => (isIncorrect ? "#FF5353" : "#2e6ea5")};
    }
  }
  &:active {
    background-color: #e4e4e4;
    & > svg {
      fill: ${({ isIncorrect }) => (isIncorrect ? "#FF5353" : "#2e6ea5")};
    }
  }
`;

export const StyledCheckMark = styled.div`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  & > svg {
    height: 16px;
    fill: #24de58;
  }
`;

export const StepFooter = styled.div``;

export const StepFooterControl = styled.div`
  display: flex;
  align-items: start;
  margin: 4px 0 0 20px;
`;

const messageColorMap: Record<
  Exclude<MessageDisplay, "NONE">,
  { color: string; backgroundColor: string }
> = {
  HINT: {
    color: "#000000",
    backgroundColor: "#c4c4c4",
  },
  MISTAKE: {
    color: "#000000",
    backgroundColor: "#ffc3c3",
  },
};

export type ControlButtonProp = {
  isSelected: boolean;
};

const StepFooterControlButton = css<ControlButtonProp>`
  font-size: 14px;
  padding: 0 8px;
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: transparent;
  text-decoration: underline;
  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        border-radius: 10px 10px 0 0;
        text-decoration: none;
        padding-bottom: 3px;
      `;
    } else {
      return css`
        &:hover {
          text-decoration: none;
          background-color: #efebeb;
        }
      `;
    }
  }}
  &:active {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
  }
`;

export const StepHintButton = styled.button`
  margin-right: 6px;
  color: #000000c1;
  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background-color: ${messageColorMap.HINT.backgroundColor};
      `;
    }
  }}
  ${StepFooterControlButton}
`;

export const StepFeedbackButton = styled.button`
  color: #000000c1;
  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background-color: ${messageColorMap.MISTAKE.backgroundColor};
      `;
    }
  }}
  ${StepFooterControlButton}
`;

export type StepMessageProps = {
  messageDisplay: MessageDisplay;
};

export const StepMessageBanner = styled.div<StepMessageProps>`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  min-width: 200px;
  max-width: 340px;

  ${({ messageDisplay }) => {
    if (messageDisplay === "HINT") {
      return css`
        border-radius: 0 10px 10px 10px;
        color: ${messageColorMap[messageDisplay].color};
        background-color: ${messageColorMap[messageDisplay].backgroundColor};
      `;
    } else if (messageDisplay === "MISTAKE") {
      return css`
        border-radius: 10px;
        color: ${messageColorMap[messageDisplay].color};
        background-color: ${messageColorMap[messageDisplay].backgroundColor};
      `;
    }
  }}
  /* border: 3px solid #c4c4c4; */
  padding: 20px 16px;
  & > button.close {
    margin: 0px 0 -17px auto;
    background-color: transparent;
    border: none;
    align-self: flex-start;
    z-index: 1;
    &:hover {
      text-decoration: underline;
    }
  }
  & > p {
    margin: 10px;
  }
`;

const loadingAniamtion = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoadingRing = styled.span`
  margin: auto;
  width: 22px;
  height: 22px;
  border: 2px solid #363636;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${loadingAniamtion} 1s linear infinite;
`;

export const InputStepCreateCss = css`
  width: 300px;
  display: inline-block;
`;