import React, { useRef, useState } from "react";
import { Button, Form, FormInstance } from "antd";
import ReactQuill from "react-quill";
import { SaveFilled } from "@ant-design/icons";
import Editor2 from "./Editor2";
import {
  StepBody,
  StepContainer,
  StepExpression,
  StepNumber,
} from "../../../../components/SolutionStep/style";
import { MathfieldElement } from "mathlive";
import MathField2 from "../../../../components/Mathlive/MathField";

export interface InputFormData {
  solution: string;
  hint: string;
}

export interface OutputFormData {
  solution: string;
  hint: string;
}

export function ProblemStepForm2({
  form,
  initialValues,
  index,
  onSubmit,
  onCancel,
  disabled = false,
}: {
  form: FormInstance<OutputFormData>;
  initialValues?: Partial<InputFormData>;
  index: number;
  onSubmit: (values: OutputFormData) => void;
  onCancel: () => void;
  disabled?: boolean;
}) {
  const [solution, setSolution] = useState(
    initialValues ? (initialValues.solution ? initialValues.solution : "") : "",
  );
  const [hint, setHint] = useState(
    initialValues ? (initialValues.hint ? initialValues.hint : "") : "",
  );
  const quillRef = useRef<ReactQuill>(null);
  const mathFieldRef = useRef<MathfieldElement>(null);

  const handleSubmit = async () => {
    try {
      console.log("solution: ", solution);
      console.log("hint: ", hint);
      const stepValues = await form.validateFields();
      onSubmit({
        solution: stepValues.solution.trim() || solution,
        hint: stepValues.hint.trim(),
      });
      form.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Form
      form={form}
      name="problem_step_form"
      layout="vertical"
      initialValues={initialValues}
      disabled={disabled}
    >
      {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
        <StepContainer styling={"primary"}>
          <StepNumber>Step {index + 1}</StepNumber>
          <StepBody>
            <StepExpression>
              <SolutionStepInput
                index={index}
                initialValue={initialValues?.solution || ""}
                onChange={setSolution}
              />
            </StepExpression>
          </StepBody>
        </StepContainer>

        <HintInput hint={hint} setHint={setHint} quillRef={quillRef} />
      {/* </div> */}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            icon={<SaveFilled rev={undefined} />}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

interface SolutionStepEditProps {
  index: number;
  initialValue: string;
  onChange: (solution: string) => void;
}

function SolutionStepInput({ index, initialValue, onChange }: SolutionStepEditProps) {
  return (
    <Form.Item
      name="solution"
      label="Solution"
      rules={[{ required: true, message: "Please input solution!" }]}
    >
      <MathField2
        defaultValue={initialValue}
        onChange={onChange}
        style={{ minWidth: "200px", maxWidth: "500px" }}
      />
    </Form.Item>
  );
}

interface HintInputProps {
  hint: string;
  setHint: React.Dispatch<React.SetStateAction<string>>;
  quillRef: any;
}

function HintInput({ hint, setHint, quillRef }: HintInputProps) {
  return (
    <Form.Item name="hint" label="Hint" rules={[{ required: false }]}>
      <Editor2 initialValue={hint} ref={quillRef} onChange={setHint} reference={quillRef} />
    </Form.Item>
  );
}
