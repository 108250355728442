import { useNavigate } from "react-router-dom";
import { useLogoutUserMutation } from "../../../features/api/auth-api";
import {
  SidebarBackdropR,
  SidebarEntry,
  SidebarHeader,
  SidebarList,
  SidebarLists,
} from "../LeftSideNav/style";

export default function RightSidePanel({ onEntryClick }: { onEntryClick: () => void }) {
  const [logout] = useLogoutUserMutation();
  const navigate = useNavigate();

  return (
    <SidebarBackdropR>
      <SidebarList>
        <SidebarHeader>Account Navigation</SidebarHeader>

        <SidebarLists>
          <SidebarEntry to={"/profile"} onClick={onEntryClick}>
            <div>Profile</div>
          </SidebarEntry>
        </SidebarLists>
        <SidebarLists>
          <SidebarEntry to={"/password-change"} onClick={onEntryClick}>
            <div>Change Password</div>
          </SidebarEntry>
        </SidebarLists>
        <SidebarLists>
          <div
            style={{
              fontSize: " 15px",
              color: "#fff",
              lineHeight: "40px",
              textIndent: "25px",
              textDecoration: "none",
            }}
            onClick={() => {
              logout()
                .unwrap()
                .then(() => {
                  /* empty */
                })
                .catch(() => {
                  /* empty */
                })
                .finally(() => {
                  onEntryClick();
                  navigate("/");
                });
            }}
          >
            Logout
          </div>
        </SidebarLists>
      </SidebarList>
    </SidebarBackdropR>
  );
}
