import styled from "styled-components";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";

export interface CoursePanelProps {
  panelColor: string;
}

export const CoursePanel = styled.div<CoursePanelProps>`
  box-sizing: border-box;
  width: 322px;
  height: 194px;
  background-color: ${({ panelColor }) => panelColor};
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0px 0px;
  transition: background-color 0.3s ease-in-out;
  //
`;
export const CoursePanelHeader = styled.div`
  color: white;
  font-size: x-large;
  font-family: "Roboto", sans-serif;
  height: 105px;
  text-align: center;
  position: relative;
  & p {
    margin: auto;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const CoursePanelFootr = styled.div`
  height: 87px;
  left: 0px;
  bottom: -86px;

  background: #ecf0f1;
  display: flex;
  flex-direction: row;

  color: black;
`;
export const CourseDetailed = styled.div`
  width: 50%;
  justify-content: center;

  padding: 10px 0 0 10px;
`;
export const CourseDetailPt2 = styled.div`
  float: right;
  width: 50%;
  padding: 10px 0 0 20px;
`;
export const StudentIcon = styled(GrGroup)`
  font-size: x-large;
  padding-left: 30px;
`;
export const StudentAssignmentIcon = styled(IoDocumentTextOutline)`
  font-size: x-large;
  padding-left: 30px;
`;
