import { Form, message, Modal, Select } from "antd";
import React from "react";
import { TopicForm, OutputFormData, InputFormData } from "./TopicForm";

interface TemplateData {
  courseTemplateId: number;
}

export type CreateFromData = TemplateData & InputFormData;

export interface ModalTopicCreateProps {
  open: boolean;
  initialValues?: Partial<CreateFromData>;
  onSubmit: (values: CreateFromData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalTopicCreate({
  open,
  initialValues,
  onSubmit,
  onCancel,
  isLoading,
}: ModalTopicCreateProps) {
  const [topicForm] = Form.useForm<OutputFormData>();

  const { courseTemplateId: initialTopicTemplateId, ...initialTopicValues } = initialValues || {};

  const handleSubmit = async () => {
    try {
      const topicValues = await topicForm.validateFields();

      onSubmit({
        title: topicValues.title?.trim() || "",
        courseTemplateId: topicValues.courseTemplateId ? topicValues.courseTemplateId : 0,
        published: topicValues.published?.valueOf() || false,
      });
      topicForm.resetFields();
    } catch (info) {
      console.log("Validation Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="Create Topic"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <TopicForm
        form={topicForm}
        initialValues={
          initialTopicValues && {
            ...initialTopicValues,
          }
        }
      />
    </Modal>
  );
}
