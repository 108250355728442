import styled from "styled-components";
import { Link } from "react-router-dom";
import { Devices as device } from "../../components/utils/Devices";

export const PanelBody = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding: 15px;
  font-size: 16px;
  background-color: rgb(236, 240, 241);
  color: rgb(52, 73, 94);
`;

export const PanelBodyContentSpace = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
`;
export const PanelBodyContent = styled.div`
  font-size: 18px;
`;

export const PanelFooter = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: all 0.25s ease 0s;
  :hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
`;

export const PanelFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 10px 15px 10px 15px;
  transition: border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  :hover {
    color: rgb(52, 73, 94);
    background-color: rgb(241, 196, 15);
  }
`;

export const PanelFooterLabel = styled.span`
  float: left;
  font-size: 16px;
`;

export const PanelFooterIcon = styled.span`
  float: right;
`;

export const CoursePanelDiv = styled.div`
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 7%;
`;

export const CoursePanelBorder = styled.div`
  border: thin solid rgb(52, 73, 94);
  border-radius: 4px;
`;

export const PanelHeading = styled.div`
  color: rgb(255, 255, 255);
  background-color: rgb(52, 73, 94);
  padding: 3% 4%;
`;

export const CourseTitleStyle = styled.div`
  font-size: 18px;
  color: rgb(89, 255, 13);
  margin-bottom: 2%;
`;

export const CourseAffiliationStyle = styled.small`
  font-size: 16px;
  color: rgb(255, 213, 27);
`;

export const CoursePanelsLayout = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2%;
  flex-flow: wrap;
  align-items: center;
  @media (min-width: 300px) {
    justify-content: center;
  }
`;

export const CoursePanelWrapper = styled.div`
  @media (min-width: 300px) {
    width: 100%;
  }
  @media ${device.mobileS} {
    width: 50%;
  }
  @media ${device.desktopL} {
    width: 33.3%;
  }
`;

export const PostAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: 300px) {
    flex-flow: column;
  }
  @media ${device.desktopL} {
    flex-flow: row;
  }
`;

export const PostPanelDiv = styled.div`
  margin-bottom: 3%;
  margin-top: 3%;
  color: rgb(52, 73, 94);
  @media (min-width: 300px) {
    width: 100%;
    padding: 0 5%;
  }
  @media ${device.desktopL} {
    width: 50%;
    padding: 0 2%;
  }
`;

export const PostPanelBorder = styled.div`
  border: #337ab7 thin solid;
  border-radius: 4px;
`;

export const PostPanelHeader = styled.div`
  color: #000000;
  background-color: #337ab7;
  padding: 10px 15px;
  font-size: 16px;
`;

/**
 * *****************Student Dashboard********************
 */

export const AssignmentsContainer = styled.div``;

export const AssignmentPanel = styled.div`
  box-sizing: border-box;
  border: 1px solid #c0bbbb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 505px;
  height: 237px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const AssignmentPanelMark = styled.div`
  height: 9px;
  left: 0px;
  top: 1px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #34495e;
`;

export const AssigmentTitle = styled.h2`
  left: 29px;
  /* top: 38px; */
  position: relative;
`;

export const DonutBox = styled.div`
  right: 0;
  display: flex;
`;

interface ProgressDoughnutProps {
  progress: number;
}
export const ProgressDonut = styled.div<ProgressDoughnutProps>`
  position: relative;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  left: 80px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #55a5e9; */
  transition: transform 0.35s;

  background: conic-gradient(
    #ededed ${(props) => props.progress * -3.6 + 360}deg,
    #55a5e9 0deg
  );
  &::before {
    content: "";
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background-color: white;
  }
`;

export const LostPointsMarkup = styled.div<ProgressDoughnutProps>`
  background: conic-gradient(
    #ff0000 ${(props) => props.progress * 3.6}deg,
    #ededed11 0deg
  );
  position: relative;
  height: 180px;
  width: 180px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.35s;

  &::before {
    content: "";
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;
export const ProgressValue = styled.div`
  position: absolute;
  font-size: 50px;
  font-weight: 600;
  color: #2e6ea5;
  &.percentage-text {
    font-size: 20px;
    margin: 0;
  }
`;

// #55a5e9 ${(props) => props.progress * 3.6}deg,


/*
 * Student Assignments
 */
export const ALContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const ALLabel = styled.label`
  margin-left: 20px;
  display: flex;
  flex-flow: row wrap;
`;

export const ALSelect = styled.select`
  width: 200px;
  padding: 8px;
  border-radius: 4px;
  border: none;
  margin-left: 5px;
`;

export const ALButton = styled.button`
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: #0077cc;
  color: #fff;
  margin-left: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
`;

export const ContentContainer = styled.div`
  flex: auto;
`;