import { useLocation, useNavigate } from "react-router-dom";

export interface History {
  navigate: ReturnType<typeof useNavigate> | null;
  location: ReturnType<typeof useLocation> | null;
}

export const history: History = {
  navigate: null,
  location: null,
};
