import {
  useAddCourseStudentsMutation,
  useGetStudentCandidatesQuery,
} from "../../../features/api/professor/course";
import { message, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { UserPublicWithProfile } from "../../../features/api/types";
import React, { useState } from "react";
import { getColumn, UserColumns } from "../../../components/Table/columns";

export interface AddStudentExistingProps {
  courseId: number;
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
}

export default function AddStudentExisting({
  courseId,
  isOpen,
  onCancel,
  onOk,
}: AddStudentExistingProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const { data: students, isLoading, isFetching, isError } = useGetStudentCandidatesQuery(courseId);
  const [enrollStudents, { isLoading: isEnrolling }] = useAddCourseStudentsMutation();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = () => {
    enrollStudents({ courseId, students: selectedRowKeys.map((k) => Number(k)) })
      .unwrap()
      .then(({ success, failed }) => {
        if (success.length > 0) {
          messageApi.success(`Added ${success.length} students`);
        }
        if (failed.length > 0) {
          messageApi.warning(`Failed to add ${failed.length} students`);
        }
        onOk();
      })
      .catch(() => {
        messageApi.error("Failed to add students");
      });
  };

  const columns: ColumnsType<RowDataType> = [
    getColumn.institutionalId(),
    getColumn.email(),
    getColumn.firstName(),
    getColumn.lastName(),
  ];

  const rows: RowDataType[] =
    students?.map((s) => ({
      key: s.user.id,
      email: s.user.email,
      institutionalId: s.profile?.institutional_id || null,
      firstName: s.profile?.firstname || null,
      lastName: s.profile?.lastname || null,
    })) || [];

  if (isError) {
    messageApi.open({ key: "QUERY_ERROR", type: "error", content: "Error retrieving data" });
  }

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title="Add student"
        okText={
          "Add to course" + (selectedRowKeys.length > 0 ? ` (${selectedRowKeys.length})` : "")
        }
        cancelText={"Cancel" + " "}
        onCancel={handleCancel}
        onOk={handleSubmit}
        confirmLoading={isEnrolling}
        okButtonProps={{
          disabled: !hasSelected,
        }}
      >
        <Table
          size="small"
          columns={columns}
          dataSource={rows}
          rowSelection={rowSelection}
          loading={isLoading || isFetching}
        />
      </Modal>
    </>
  );
}

type RowDataType = { key: React.Key } & Pick<
  UserColumns,
  "institutionalId" | "email" | "firstName" | "lastName"
>;
