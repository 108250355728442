import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import {
  useCreateCourseMutation,
  useGetCoursesQuery,
} from "../../../features/api/professor/course";
import { getDashboardPath } from "../nav";
import { CourseLayoutContainer, CourseLayoutControl, CourseListContainer } from "./style";
import InstructorCourseTile from "../../../components/Course/Instructor/InstructorCourseTile";
import { Button, Empty, message, theme } from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";

import { ModalCourseCreate } from "./CourseCreate";
import { useCourseContext } from "./CourseContext";

export default function CourseList() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { token } = theme.useToken();
  const { canCreate } = useCourseContext();
  const { data: courses, isSuccess, isLoading, isError, error } = useGetCoursesQuery();
  const [createCourse, { isLoading: isCreating }] = useCreateCourseMutation();
  const [isCreate, setIsCreate] = useState(false);

  if (isSuccess) {
    return (
      <>
        {contextHolder}
        <PageHeader header="Instructor Dashboard" subheader="" />
        <br />
        <CourseLayoutContainer>
          <CourseLayoutControl>
            <Button
              type="text"
              size="large"
              icon={<LeftOutlined rev={undefined} />}
              style={{ marginRight: "auto" }}
              onClick={() => {
                navigate(getDashboardPath());
              }}
            >
              Dashboard
            </Button>
            {canCreate && (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined rev={undefined} />}
                onClick={() => setIsCreate(true)}
              >
                Create
              </Button>
            )}
            <ModalCourseCreate
              open={isCreate}
              initialValues={{ color: token.colorPrimary }}
              onSubmit={(values) => {
                console.log(values);
                const { courseTemplateId, ...otherValues } = values;
                createCourse({
                  ...otherValues,
                  course_template_id: courseTemplateId,
                  gamification: false,
                  social_interaction: false,
                  collaborative_learning: false,
                  aliasing: false,
                })
                  .unwrap()
                  .then(() => {
                    setIsCreate(false);
                    messageApi.success("Course created");
                  })
                  .catch(() => {
                    messageApi.error("Failed to create course");
                  });
              }}
              onCancel={() => setIsCreate(false)}
              isLoading={isCreating}
            />
          </CourseLayoutControl>
          {courses.length > 0 ? (
            <CourseListContainer>
              {courses.map((course) => (
                <InstructorCourseTile key={course.id} course={course} />
              ))}
            </CourseListContainer>
          ) : (
            <Empty
              style={{ margin: "40px 0" }}
              description={<span>No available courses. Please, create new course.</span>}
            />
          )}
        </CourseLayoutContainer>
        {/* <NavLink to={getCreateCoursePath()}>Create</NavLink> */}
      </>
    );
  }

  if (isLoading) {
    return (
      <div>
        <h1>Courses</h1>
        <p>Loading...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <h1>Courses</h1>
        <p>Error: {JSON.stringify(error)}</p>
      </div>
    );
  }
  return (
    <div>
      <h1>Courses</h1>
      <p>Unexpected error</p>
    </div>
  );
}
