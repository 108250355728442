import PageHeader from "../../components/PageHeader";
import { useChangePasswordMutation } from "../../features/api/auth-api";
import { useGetMeQuery } from "../../features/api/user-api";
import { Button, Form, Input, message } from "antd";

interface FormData {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export default function ChangePass() {
  const { data: user, isError, isSuccess } = useGetMeQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<FormData>();

  const [changePassword, { isLoading: isChanging }] = useChangePasswordMutation();

  if (isSuccess) {
    const id = user.user.id;

    const displaySuccess = () => {
      form.resetFields();
      messageApi.open({
        type: "success",
        content: "Password changed",
      });
    };

    const displayError = (error: { status: number; data: never }) => {
      let message: string;
      if (error.status === 409) {
        message = "Failed to change password. Check you current password.";
      } else {
        message = "Failed to change password";
      }
      messageApi.open({
        type: "error",
        content: message,
        duration: 5,
      });
    };

    const handleSubmit = (values: FormData) => {
      changePassword({
        userId: id,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
        .unwrap()
        .then(displaySuccess)
        .catch(displayError);
    };

    return (
      <>
        {contextHolder}
        <PageHeader header="Change Password" subheader="" />
        <br />
        <div>
          <Form
            {...formItemLayout}
            form={form}
            name="change_password_form"
            style={{ maxWidth: 600, margin: "40px auto", padding: "0 14px" }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="currentPassword"
              label="Current password"
              rules={[{ required: true, message: "Please input your current password!" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New password"
              rules={[{ required: true, message: "Please input your new password!" }]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="newPasswordConfirmation"
              label="Confirm new password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords that you entered do not match!"),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" loading={isChanging}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
  return null;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
