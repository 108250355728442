import { VictoryLabel, VictoryTooltip, VictoryPie } from "victory";

function CustomLabel() {
  return (
    <g>
      <VictoryLabel />
      <VictoryTooltip
        x={200}
        y={250}
        orientation="top"
        pointerLength={10}
        cornerRadius={50}
        flyoutWidth={100}
        flyoutHeight={100}
        flyoutStyle={{ fill: "black" }}
      />
    </g>
  );
}

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

interface SummaryPieProps {
  pieData: { x: string | number; y: number }[];
  earnedAverage: number;
  maxPoints: number;
  width: number;
  height: number;
  colors: string[];
  fontSizeA: string;
  fontSizeT: string;
  centerX: number;
  centerY: number;
  innerRadius: number;
}

interface DisplayValueProps {
  earnedAverage: number;
  maxPoints: number;
  fontSizeA: string;
}

function DisplayValue({ earnedAverage, maxPoints, fontSizeA }: DisplayValueProps) {
  if (earnedAverage < maxPoints) {
    return (
      <>
        <span style={{ display: "block", fontSize: fontSizeA, fontWeight: "600" }}>
          {earnedAverage.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
        <span style={{ display: "block", marginTop: "-10px" }}>
          /{maxPoints.toLocaleString("en-US")}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span style={{ fontSize: fontSizeA }}>{maxPoints.toLocaleString("en-US")}</span>
        <br />
        points
      </>
    );
  }
}

export function SummaryPie({
  pieData,
  earnedAverage,
  maxPoints,
  width,
  height,
  colors,
  fontSizeA,
  fontSizeT,
  centerX,
  centerY,
  innerRadius,
}: SummaryPieProps) {
  return (
    <>
      <div style={{ position: "relative", width: width }}>
        <div
          style={{
            width: "70%",
            position: "absolute",
            left: centerX,
            top: centerY,
            textAlign: "center",
            justifyContent: "center",
            fontSize: fontSizeT,
            fontWeight: "400",
            color: colors[0],
          }}
        >
          <DisplayValue earnedAverage={earnedAverage} maxPoints={maxPoints} fontSizeA={fontSizeA} />
        </div>
        <VictoryPie
          width={width}
          height={height}
          colorScale={colors}
          style={{ labels: { fill: "white" } }}
          innerRadius={innerRadius}
          radius={width / 2}
          labelRadius={0}
          labels={({ datum }) => `# ${datum.y}`}
          labelComponent={<CustomLabel />}
          data={pieData}
        />
      </div>
    </>
  );
}
