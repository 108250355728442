import { Route, Routes, useNavigate } from "react-router-dom";
import IntroCreate, { IntroCreate2 } from "./IntroCreate";
import IntroPage from "./IntroPage";
import { useTopicContext } from "../Context";

export default function IntroManager({ topicId }: { topicId: number }) {
  const navigate = useNavigate();
  const { canEdit } = useTopicContext();

  return (
    <Routes>
      <Route
        path=":pageId/*"
        element={<IntroPage afterDelete={() => navigate("..")} />}
      />
      {canEdit && (
        <Route
          path="new"
          element={
            <IntroCreate2
              topicId={topicId}
              onDone={(pageId: number) => navigate(`${pageId}`)}
              onCancel={() => navigate("..")}
            />
          }
        />
      )}
    </Routes>
  );
}
