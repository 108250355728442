import Step from "./Step";

type Props = {
  number: number;
  expression: string;
};

export default function SolutionStep(props: Props) {
  return (
    // <Step {...props} />
    <></>
  );
}
