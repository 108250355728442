import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../../features/api/auth-api";
import { BackgroundPage, ControlRow, LoginHeading, LoginWrapperDiv, LogoImg } from "./style";
import { Button, Form, Input, Typography } from "antd";
import logo from "../../components/companyLogo.png";
import { CheckCircleOutlined, CloseCircleOutlined, LeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

interface Values {
  email: string;
}

const SUBMIT_TIMEOUT = 60 * 1000;

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [form] = Form.useForm<Values>();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const [requestResult, setRequestResult] = useState<ResultData | null>(null);

  const [lastSubmit, setLastSubmit] = useState<number | null>(null);
  const calculateTimeout = (last = lastSubmit) => {
    const now = Date.now();
    if (last) {
      return SUBMIT_TIMEOUT - (now - last);
    }
    return 0;
  };
  const [submitTimeout, setSubmitTimeout] = useState(calculateTimeout());
  const tickTimeout = () => setSubmitTimeout(calculateTimeout());

  useEffect(() => {
    const timer = setInterval(() => {
      tickTimeout();
    }, 1000);

    return () => clearInterval(timer);
  });

  const handleSubmit = (values: Values) => {
    forgotPassword({
      email: values.email,
      basePath: `${window.location.origin}/password-reset`,
    })
      .unwrap()
      .then(() => {
        const now = Date.now();
        setSubmitTimeout(calculateTimeout(now));
        setLastSubmit(now);
        setRequestResult({
          isSuccess: true,
          receivedAt: now,
        });
      })
      .catch((error) => {
        setRequestResult({
          isSuccess: false,
          receivedAt: Date.now(),
          status: error.status,
          message: error.data.message,
        });
      });
  };

  return (
    <BackgroundPage>
      <LogoImg src={logo} alt="logo" />
      <LoginWrapperDiv>
        <LoginHeading>Forgot Password</LoginHeading>

        <Form
          form={form}
          name="forgot_password_form"
          layout="vertical"
          style={{ width: "100%" }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please enter your email address!" }]}
          >
            <Input type="email" placeholder="Email" onChange={() => setRequestResult(null)} />
          </Form.Item>
          <Form.Item>
            <ControlRow>
              <Button
                type="text"
                icon={<LeftOutlined rev={undefined} />}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "auto" }}
                loading={isLoading}
                disabled={submitTimeout > 0}
              >
                Submit {submitTimeout > 0 && `(${Math.ceil(submitTimeout / 1000)})`}
              </Button>
            </ControlRow>
          </Form.Item>
        </Form>
        <div>{requestResult && <ResultDisplay data={requestResult} />}</div>
      </LoginWrapperDiv>
    </BackgroundPage>
  );
}

function ResultDisplay({ data }: { data: ResultData }) {
  if (data.isSuccess) {
    return <SuccessResult />;
  } else {
    return <ErrorResult data={data} />;
  }
}

function SuccessResult() {
  return (
    <div style={{ maxWidth: "250px" }}>
      <Typography.Text type="success" strong>
        <CheckCircleOutlined rev={undefined} /> Check your inbox
      </Typography.Text>
      <Typography.Paragraph type="secondary">
        We sent you an email with further instructions. Please, allow up to <b>5 minutes</b> for it
        to appear in your inbox.
      </Typography.Paragraph>
    </div>
  );
}

function ErrorResult({ data }: { data: ErrorResultData }) {
  return (
    <div style={{ maxWidth: "250px" }}>
      <Typography.Text type="danger" strong>
        <CloseCircleOutlined rev={undefined} /> Unable to satisfy request
      </Typography.Text>
      {data.message && <Typography.Paragraph type="secondary">{data.message}</Typography.Paragraph>}
    </div>
  );
}

type ResultData = SuccessResultData | ErrorResultData;

interface SuccessResultData {
  isSuccess: true;
  receivedAt: number;
}

interface ErrorResultData {
  isSuccess: false;
  receivedAt: number;
  status?: number;
  message?: string;
}
