import {
  useGetCourseTemplateQuery,
  useUpdateCourseTemplateMutation,
} from "../../../features/api/professor/course";
import { App, Form, Modal, Result, Spin } from "antd";
import CourseTemplateFormNew, { CourseTemplateFormData } from "./CourseTemplateFormNew";
import { LoadingOutlined } from "@ant-design/icons";
import UserForm from "../User/UserForm";

export interface CourseTemplateEditProps {
  id: number | null;
  onSubmit: () => void;
  onCancel: () => void;
}

export default function CourseTemplateEditNew({
  id: _id,
  onSubmit,
  onCancel,
}: CourseTemplateEditProps) {
  const isOpen = _id != null;
  const id = _id || 0;

  const { message } = App.useApp();
  const [form] = Form.useForm<CourseTemplateFormData>();

  const {
    data: subject,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetCourseTemplateQuery(id);
  const [updateCourseTemplate, { isLoading: isUpdating }] = useUpdateCourseTemplateMutation();

  if (isSuccess) {
    form.setFieldsValue({
      name: subject.name,
      description: subject.description,
      affiliation: subject.affiliation,
    });
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateCourseTemplate({
          id,
          name: values.name,
          description: values.description || "",
          affiliation: values.affiliation || "",
        })
          .unwrap()
          .then(() => {
            message.success("Changes saved");
            onSubmit();
            form.resetFields();
          })
          .catch(() => {
            message.error("Failed to save changes");
          });
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={isOpen}
      title="Edit subject"
      okText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={isUpdating}
      okButtonProps={{ disabled: !isSuccess }}
    >
      {isError ? (
        <Result
          status="error"
          title="Failed to fetch data for this subject"
          subTitle="Subject does not exist or no connection to the system"
        />
      ) : (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin rev={undefined} />}
          spinning={isLoading}
        >
          <CourseTemplateFormNew form={form} disabled={!isSuccess} />
        </Spin>
      )}
    </Modal>
  );
}
