import React from "react";
import { Form, FormInstance, Input, message, Select, Switch } from "antd";


export interface InputFormData {
    firstname: string;
    lastname: string;
    user_bio: string;
}

export interface OutputFormData {
    firstname: string;
    lastname: string;
    user_bio: string;
}

export function TopicForm({
    form,
    initialValues,
}: {
    form: FormInstance<OutputFormData>;
    initialValues?: Partial<InputFormData>;
}) {
    return (
        <Form
            form={form}
            name="profile_form"
            layout="vertical"
            initialValues={initialValues}
        >
            <FirstnameInput />
            <LastnameInput />
            {/* <EmailInput /> */}
            <BioInput />
        </Form>
    );
}

function FirstnameInput() {
    return (
        <Form.Item
            name="firstname"
            label="First Name"
            rules={[{ required: true, message: "Please enter first name!" }]}
        >
            <Input />
        </Form.Item>
    );
}

function LastnameInput() {
    return (
        <Form.Item
            name="lastname"
            label="Lastname"
            rules={[{ required: true, message: "Please enter lastname!" }]}
        >
            <Input />
        </Form.Item>
    );
}

function EmailInput() {
    return (
        <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please enter email!" }]}
        >
            <Input />
        </Form.Item>
    );
}

function BioInput() {
    return (
        <Form.Item
            name="user_bio"
            label="Bio"
        >
            <Input />
        </Form.Item>
    );
}
