import { useState } from "react";
import { StaticMathField } from "react-mathquill";
import PageHeader from "../../../components/PageHeader";
import { useTryExpressionQuery } from "../../../features/api/professor/math";
import { EditMathInput, ExpressionContainer, SheetContainer } from "./style";

export default function ExpressionTest() {
  const [exprA, setExprA] = useState("\\frac{1}{\\sqrt{2}}\\cdot 2");
  const [exprB, setExprB] = useState("\\frac{1}{\\sqrt{2}}\\cdot 2");

  const { data, isLoading, isSuccess } = useTryExpressionQuery({
    expressionA: exprA,
    expressionB: exprB,
  });

  return (
    <>
      <PageHeader header="Expresion Test " subheader="" />
      <ExpressionContainer>
        <br />
        Source:
        <br />
        <EditMathInput
          latex={exprA}
          onChange={(mathField) => {
            setExprA(mathField.latex());
          }}
        />
        <br />
        <br />
        Answer:
        <br />
        <EditMathInput
          latex={exprB}
          onChange={(mathField) => {
            setExprB(mathField.latex());
          }}
        />
        {isSuccess ? (
          <h3>
            <Result result={data.result} />
          </h3>
        ) : isLoading ? (
          <h3>Loading...</h3>
        ) : (
          <h3>Error</h3>
        )}
        <br />
        <CheatSheet />
      </ExpressionContainer>
    </>
  );
}

function Result({ result }: { result: boolean }) {
  if (result) {
    return <span style={{ color: "green" }}>This answer will be accepted</span>;
  } else {
    return <span style={{ color: "red" }}>This answer will NOT be accepted</span>;
  }
}

function CheatSheet() {
  return (
    <SheetContainer>
      <h2>TeX Cheat Sheet</h2>
      <p className="text">
        <code style={{ backgroundColor: "lightgrey" }}>^</code> exponent, e.g.:
        <StaticMathField>{"x^2"}</StaticMathField>
      </p>

      <p className="text">
        <code style={{ backgroundColor: "lightgrey" }}>\pm</code> plus minus sign, e.g.:{" "}
        <StaticMathField>{"x\\pm2"}</StaticMathField>
      </p>
      <p className="text">
        <code style={{ backgroundColor: "lightgrey" }}>\sqrt</code> square root, e.g.:{" "}
        <StaticMathField>{"\\sqrt{2}"}</StaticMathField>
      </p>
    </SheetContainer>
  );
}
