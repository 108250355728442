import { StyledSubHeader } from "../../../components/PageHeader/style";
import { useGetCoursesQuery } from "../../../features/api/professor/course";
import { getAllCoursePath } from "../nav";
import { CoursePanelsLayout } from "../style";
import InstructorCourseTile from "../../../components/Course/Instructor/InstructorCourseTile";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function Courses() {
  const navigate = useNavigate();
  const { data: courses, isSuccess, isLoading, isError, error } = useGetCoursesQuery();

  if (isLoading) {
    return (
      <div>
        <h1>Courses</h1>
        <p>Loading...</p>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <StyledSubHeader>Courses</StyledSubHeader>
        {courses.length > 0 ? (
          <>
            <CoursePanelsLayout>
              {courses.slice(0, 3).map((course) => (
                <InstructorCourseTile key={course.id} course={course} />
              ))}
            </CoursePanelsLayout>
            <Button
              type="text"
              size="large"
              style={{ margin: "20px 8% 0 auto" }}
              onClick={() => navigate(getAllCoursePath())}
            >
              See all...
            </Button>
          </>
        ) : (
          <Empty style={{ margin: "40px 0" }}>
            <Button type="primary" ghost onClick={() => navigate(getAllCoursePath())}>
              Manage Courses
            </Button>
          </Empty>
        )}
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <h1>Courses</h1>
        <p>Error: {JSON.stringify(error)}</p>
      </div>
    );
  }

  return (
    <div>
      <h1>Courses</h1>
      <p>Unexpected error</p>
    </div>
  );
}
