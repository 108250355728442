import { Route, Routes } from "react-router-dom";
import TopicLayout from "./TopicLayout";
import TopicPage from "./Topic";
import TopicContext, { TopicContextType } from "./Context";

export default function TopicManager({
  canCreate = false,
  canEdit = false,
  canDelete = false,
}: Partial<TopicContextType>) {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <TopicContext
            canCreate={canCreate}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        }
      >
        <Route index element={<TopicLayout />} />
        <Route path="/:topicId/*" element={<TopicPage />} />
      </Route>
    </Routes>
  );
}
