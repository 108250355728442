import UserForm, { UserFromData, UserInputVisibility } from "./UserForm";
import { Form, message, Modal } from "antd";
import { useSignUpUserMutation } from "../../../features/api/auth-api";

export interface UserCreateProps {
  open: boolean;
  initialValues?: Partial<UserFromData>;
  visibility?: Partial<UserInputVisibility>;
  onSubmit: () => void;
  onCancel: () => void;
}

export function ModalUserCreate({
  open,
  initialValues,
  visibility,
  onSubmit,
  onCancel,
}: UserCreateProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<UserFromData>();
  const [createUser, { isLoading: isCreating }] = useSignUpUserMutation();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        createUser({
          email: values.email,
          username: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          institutional_id: values.institutionalId,
          roles: ["ROLE_USER", values.roles],
          affiliation: values.affiliation?.trim() || "",
          user_bio: values.biography?.trim() || "",
          password: values.password,
        })
          .unwrap()
          .then(() => {
            messageApi.success("User created");
            onSubmit();
            form.resetFields();
          })
          .catch(() => {
            messageApi.error("Failed to create user");
          });
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Create user"
        okText="Create"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={handleSubmit}
        confirmLoading={isCreating}
      >
        <UserForm form={form} initialValues={initialValues} visibility={visibility} />
      </Modal>
    </>
  );
}
