import React from "react";
import { Button, Empty, Form, FormInstance, Input, message, Select, Switch } from "antd";
import { useGetCourseTemplatesQuery } from "../../../features/api/professor/course";
import { useNavigate } from "react-router-dom";
import { getAllCourseTemplatePath } from "../nav";
import { UserRole } from "../../../features/api/types";
import { useGetMeQuery } from "../../../features/api/user-api";

export interface InputFormData {
  title: string;
  courseTemplateId: number | null;
  published: boolean;
}

export interface OutputFormData {
  title?: string;
  courseTemplateId?: number | null;
  published?: boolean;
}

export function TopicForm({
  form,
  initialValues,
}: {
  form: FormInstance<OutputFormData>;
  initialValues?: Partial<InputFormData>;
}) {
  const selectedId = initialValues
    ? initialValues.courseTemplateId
      ? initialValues.courseTemplateId
      : -1
    : -1;
  return (
    <Form form={form} name="topic_form" layout="vertical" initialValues={initialValues}>
      <TitleInput />
      <CourseTemplateSelect />
      <PublishedToggle />
    </Form>
  );
}

function CourseTemplateSelect() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { data: me } = useGetMeQuery();
  const {
    data: courseTemplates,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetCourseTemplatesQuery();

  if (isError) {
    messageApi.open({
      key: "COURSE_TEMPLATE_QUERY",
      type: "error",
      content: "Unable to retrieve list of course templates",
    });
  }

  return (
    <>
      {contextHolder}
      <Form.Item
        name="courseTemplateId"
        label="Subject"
        rules={[{ required: true, message: "Please select subject!" }]}
      >
        <Select
          allowClear
          showSearch
          loading={isLoading || isFetching}
          placeholder="Please select subject"
          notFoundContent={
            <Empty
              imageStyle={{ height: 60 }}
              description={
                <div style={{ maxWidth: "300px", margin: "auto" }}>
                  No available subjects. Please, <b>create</b> subject before completing this form.
                </div>
              }
              style={{ margin: "20px" }}
            >
              {(["ROLE_ADMIN"] as UserRole[]).some((r) => me?.user.roles.includes(r)) && (
                <Button type="primary" onClick={() => navigate(getAllCourseTemplatePath())}>
                  Manage Subjects
                </Button>
              )}
            </Empty>
          }
        >
          {isSuccess &&
            courseTemplates.map((ct) => (
              <Select.Option key={ct.id} value={ct.id}>
                {ct.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
}

function TitleInput() {
  return (
    <Form.Item
      name="title"
      label="Title"
      rules={[{ required: true, message: "Please input title!" }]}
    >
      <Input />
    </Form.Item>
  );
}

function PublishedToggle() {
  return (
    <Form.Item name="published" label="Published">
      <Switch />
    </Form.Item>
  );
}
