import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: 60px auto 60px auto;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-top: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
`;
