import styled from "styled-components";
import { ProblemStatus } from "../../../features/api/types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NavContainer = styled.nav`
  width: 300px;
  height: auto;
  border-right: 2px solid #d6d6d6;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
`;

export const ContentContainer = styled.div`
  flex: auto;
`;

const colorMap: Record<ProblemStatus, { color: string }> = {
  correct: {
    color: "#24DE58",
  },
  incorrect: {
    color: "#FF5353",
  },
  unanswered: {
    color: "#00000094",
  },
};

type ProblemNavProps = {
  problemStatus: ProblemStatus;
};

export const ProblemNavContent = styled.span<ProblemNavProps>`
  color: ${({ problemStatus }) => colorMap[problemStatus].color};
  & > span.progress {
    margin-left: 30px;
    font-weight: 300;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
  border-bottom: 2px solid #d6d6d6;
`;

export const Title = styled.h1`
  flex: 1;
  // order: 2;
  margin: 20px 0;
  // font-size: 22px;
  class: "large-text";
  text-align: center;
`;

export const ScoreContainer = styled.div`
  // order: 1;
  display: flex;
  align-items: center;
  margin: 0 20px 0 30px;
  @media (max-width: 600px) {
    order: 3;
  }
`;

export const DateContainer = styled.div`
  // order: 4;
  display: flex;
  align-items: center;
  margin: 0 30px 0 20px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`;

export const Divider = styled.hr`
  width: 90%;
  border: 1px solid #d6d6d6;
  margin: auto;
`;
