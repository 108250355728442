import { Form, Modal } from "antd";
import { ProblemStepForm2, OutputFormData } from "../../components/ProblemStepForm2";
import { EditProblemStepData } from "./ModalStepEdit";

interface ModalProblemStepCreateProps {
  open: boolean;
  problemId: number;
  index: number;
  onSubmit: (values: EditProblemStepData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalProblemStepCreate({
  open,
  problemId,
  index,
  onCancel,
  onSubmit,
  isLoading,
}: ModalProblemStepCreateProps) {
  const [form] = Form.useForm<OutputFormData>();

  const handleSubmit = async () => {
    try {
      const stepValues = await form.validateFields();
      onSubmit({
        problemId: problemId,
        solution: stepValues.solution.trim(),
        hint: stepValues.hint.trim(),
      });
      form.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="New Problem Step"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      // onOk={handleSubmit}
      footer={null}
      confirmLoading={isLoading}
    >
      <ProblemStepForm2
        form={form}
        index={index}
        initialValues={{
            solution: "",
            hint: "",
        }}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  );
}
