import PageHeader from "../../../components/PageHeader";
import Courses from "./Courses";

export default function ProfDashboard() {
  return (
    <>
      <PageHeader header="Instructor Dashboard" subheader="" />
      <br />
      {/* ------------- Display Course ------------- */}
      <Courses />
      <br />

      <br />

      {/* ------------- Display Assignment ------------- */}

      {/* <Assignments /> */}
      <br />
    </>
  );
}
