import { useState } from "react";
// import { Button } from "../../../../components/Button";
import { DeleteButton, NextButton } from "../../../../components/Button/Button";
import QuestionHeader from "../../../../components/QuestionHeader";
import SectionHeader from "../../../../components/SectionHeader";
import {
  useDeleteProblemMutation,
  useGetProblemQuery,
} from "../../../../features/api/professor/problem";
import { Table } from "../../../Student/Assignment/Example/style";
import { ButtonsContainer, PlusIcon, QuestionEditIcon, QuestionTrashcanIcon } from "../../style";
import StepCreate, { StepCreate2, StepCreate3 } from "./Step/StepCreate";
import StepEdit, { StepEdit2, StepEdit3 } from "./Step/StepEdit";
import StepView, { StepView2 } from "./Step/StepView";
import { useTopicContext } from "../Context";
import { QuestionHeader2 } from "../../../../components/QuestionHeader/QuestionHeader";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, EditFilled, PlusOutlined } from "@ant-design/icons";

export default function ProblemView({
  problemId,
  afterDelete,
  onEdit,
}: {
  problemId: number;
  afterDelete: () => void;
  onEdit: () => void;
}) {
  const { canEdit } = useTopicContext();

  const { data: problem, isSuccess, isLoading, isError, error } = useGetProblemQuery(problemId);

  const [deleteProblem, { isLoading: isDeleting }] = useDeleteProblemMutation();

  // TODO: move to redux state
  const [currentEdit, _setCurrentEdit] = useState<number | null>(null);
  const [currentCreate, _setCurrentCreate] = useState<number | null>(null);

  // mutual exclusion for state changes
  // only one edit/create dialog can be open at a time
  const isOpenState = currentEdit === null && currentCreate === null;
  const setCurrentEdit = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentEdit(newState);
  };
  const setCurrentCreate = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentCreate(newState);
  };

  if (isSuccess) {
    const handleDelete = () => {
      if (
        window.confirm(
          "Are you sure you want to DELETE this problem? This action cannot be undone!",
        )
      ) {
        deleteProblem(problem.id).then(afterDelete);
      }
    };
    return (
      <div>
        <SectionHeader>Problem</SectionHeader>
        <div style={{ display: "block" }}>
          <QuestionHeader>{problem.question}</QuestionHeader>
          <ButtonsContainer>
            {canEdit && (
              <NextButton onClick={onEdit}>
                Edit
                <QuestionEditIcon />
              </NextButton>
            )}
            {canEdit && (
              <DeleteButton onClick={handleDelete}>
                Delete
                <QuestionTrashcanIcon />
              </DeleteButton>
            )}
          </ButtonsContainer>
          <hr />
          <Table>
            {problem.steps.map((step, index) => {
              if (canEdit && currentEdit === step.id) {
                return (
                  <StepEdit
                    key={index}
                    index={index}
                    data={step}
                    onDone={() => setCurrentEdit(null)}
                    onCancel={() => setCurrentEdit(null)}
                  />
                );
              } else {
                return (
                  <StepView
                    key={index}
                    data={step}
                    index={index}
                    onEdit={() => setCurrentEdit(step.id)}
                    disableEdit={!isOpenState}
                    disableDelete={!isOpenState}
                  />
                );
              }
            })}
            {canEdit && currentCreate === 1 && (
              <StepCreate
                problemId={problem.id}
                index={problem.steps.length}
                onDone={() => setCurrentCreate(null)}
                onCancel={() => setCurrentCreate(null)}
              />
            )}
          </Table>
          {canEdit && (
            <Button onClick={() => setCurrentCreate(1)} disabled={!isOpenState}>
              Add Step <PlusIcon />
            </Button>
          )}

          {/* <ButtonsContainer>
          <NextButton onClick={onEdit}>
            <EditIcon />
          </NextButton>
          <PreviusButton onClick={onDelete}>
            <TrashcanIcon />
          </PreviusButton>
        </ButtonsContainer> */}
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return <h1>Unexpected Error</h1>;
}

export function ProblemView2({
  problemId,
  afterDelete,
  onEdit,
}: {
  problemId: number;
  afterDelete: () => void;
  onEdit: () => void;
}) {
  const { canEdit, canDelete } = useTopicContext();

  const { data: problem, isSuccess, isLoading, isError, error } = useGetProblemQuery(problemId);

  const [deleteProblem, { isLoading: isDeleting }] = useDeleteProblemMutation();

  // TODO: move to redux state
  const [currentEdit, _setCurrentEdit] = useState<number | null>(null);
  const [currentCreate, _setCurrentCreate] = useState<number | null>(null);

  // mutual exclusion for state changes
  // only one edit/create dialog can be open at a time
  const isOpenState = currentEdit === null && currentCreate === null;
  const setCurrentEdit = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentEdit(newState);
  };
  const setCurrentCreate = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentCreate(newState);
  };

  if (isSuccess) {
    const handleDelete = () => {
      deleteProblem(problem.id).then(afterDelete);
    };
    return (
      <>
        <SectionHeader>Problem</SectionHeader>
        <QuestionHeader>{problem.question}</QuestionHeader>
        
        <ButtonsContainer>
          {canEdit && (
            <Button type="primary" icon={<EditFilled rev={undefined} />} onClick={onEdit}>
              Edit
            </Button>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete Problem"
              description="Are you sure to delete this problem?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button
                type="default"
                style={{ marginLeft: "auto" }}
                icon={<DeleteOutlined rev={undefined} />}
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </ButtonsContainer>
        <hr />
        <div>
          {problem.steps.map((step, index) => {
            if (canEdit && currentEdit === step.id) {
              return (
                <StepEdit2
                  key={index}
                  index={index}
                  data={step}
                  onDone={() => setCurrentEdit(null)}
                  onCancel={() => setCurrentEdit(null)}
                />
              );
            } else {
              return (
                <StepView2
                  key={index}
                  data={step}
                  index={index}
                  onEdit={() => setCurrentEdit(step.id)}
                  disableEdit={!isOpenState}
                  disableDelete={!isOpenState}
                />
              );
            }
          })}
          {canEdit && currentCreate === 1 && (
            <StepCreate2
              problemId={problem.id}
              index={problem.steps.length}
              onDone={() => setCurrentCreate(null)}
              onCancel={() => setCurrentCreate(null)}
            />
          )}
        </div>
        {canEdit && (
          <Button
            type="dashed"
            style={{ marginLeft: "auto" }}
            icon={<PlusOutlined rev={undefined} />}
            onClick={() => setCurrentCreate(1)}
            disabled={!isOpenState}
          >
            Add Step
          </Button>
        )}
      </>
    );
  }

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return <h1>Unexpected Error</h1>;
}

export function ProblemView3({
  problemId,
  afterDelete,
  onEdit,
}: {
  problemId: number;
  afterDelete: () => void;
  onEdit: () => void;
}) {
  const { canEdit, canDelete } = useTopicContext();

  const { data: problem, isSuccess, isLoading, isError, error } = useGetProblemQuery(problemId);

  const [deleteProblem, { isLoading: isDeleting }] = useDeleteProblemMutation();

  // TODO: move to redux state
  const [currentEdit, _setCurrentEdit] = useState<number | null>(null);
  const [currentCreate, _setCurrentCreate] = useState<number | null>(null);

  // mutual exclusion for state changes
  // only one edit/create dialog can be open at a time
  const isOpenState = currentEdit === null && currentCreate === null;
  const setCurrentEdit = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentEdit(newState);
  };
  const setCurrentCreate = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentCreate(newState);
  };

  if (isSuccess) {
    const handleDelete = () => {
      deleteProblem(problem.id).then(afterDelete);
    };
    return (
      <>
        <SectionHeader>Problem</SectionHeader>
        <QuestionHeader2>{problem.question}</QuestionHeader2>
        <ButtonsContainer>
          {canEdit && (
            <Button type="primary" icon={<EditFilled rev={undefined} />} onClick={onEdit}>
              Edit
            </Button>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete Problem"
              description="Are you sure to delete this problem?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button
                type="default"
                style={{ marginLeft: "auto" }}
                icon={<DeleteOutlined rev={undefined} />}
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </ButtonsContainer>
        <hr />
        <div>
          {problem.steps.map((step, index) => {
            if (canEdit && currentEdit === step.id) {
              return (
                <StepEdit3
                  key={index}
                  index={index}
                  data={step}
                  onDone={() => setCurrentEdit(null)}
                  onCancel={() => setCurrentEdit(null)}
                />
              );
            } else {
              return (
                <StepView2
                  key={index}
                  data={step}
                  index={index}
                  onEdit={() => setCurrentEdit(step.id)}
                  disableEdit={!isOpenState}
                  disableDelete={!isOpenState}
                />
              );
            }
          })}
          {canEdit && currentCreate === 1 && (
            <StepCreate3
              problemId={problem.id}
              index={problem.steps.length}
              onDone={() => setCurrentCreate(null)}
              onCancel={() => setCurrentCreate(null)}
            />
          )}
        </div>
        {canEdit && (
          <Button
            type="dashed"
            style={{ marginLeft: "auto" }}
            icon={<PlusOutlined rev={undefined} />}
            onClick={() => setCurrentCreate(1)}
            disabled={!isOpenState}
          >
            Add Step
          </Button>
        )}
      </>
    );
  }

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return <h1>Unexpected Error</h1>;
}
