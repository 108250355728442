import { Form, FormInstance, Input } from "antd";
import React from "react";

export interface CourseTemplateFormProps {
  form: FormInstance<CourseTemplateFormData>;
  initialValues?: Partial<CourseTemplateFormData>;
  disabled?: boolean;
}

export interface CourseTemplateFormData {
  name: string;
  affiliation?: string;
  description?: string;
}

export default function CourseTemplateFormNew({
  form,
  initialValues,
  disabled,
}: CourseTemplateFormProps) {
  return (
    <Form
      form={form}
      name="course_template_form"
      layout="vertical"
      initialValues={initialValues}
      disabled={disabled}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please enter name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="affiliation" label="Affiliation">
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={4} placeholder="About this subject (optional)" />
      </Form.Item>
    </Form>
  );
}
