import { history } from "../../app/history";
import { resetAuth, setAuth } from "../auth/auth-slice";
import { resetAllSlices } from "../util";
import { apiSlice } from "./api-slice";
import { CREDENTIALS } from "./api-url";
import {
  ChangePasswordParams,
  ChangePasswordResponse,
  ForgetPasswordParams,
  ForgotPasswordResponse,
  LoginParams,
  LoginResponse,
  ResetPasswordParams,
  ResetPasswordResponse,
  SignUpParams,
  SignUpResponse,
  Tag,
} from "./types";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signUpUser: builder.mutation<SignUpResponse, SignUpParams>({
      query({ roles, ...data }) {
        return {
          url: "signup",
          method: "POST",
          body: { ...data, roles: JSON.stringify(roles) },
        };
      },
      invalidatesTags: [{ type: Tag.USER_DATA, id: "LIST" }],
    }),
    loginUser: builder.mutation<LoginResponse, LoginParams>({
      query(data) {
        return {
          url: "login",
          method: "POST",
          body: data,
          credentials: CREDENTIALS ? "include" : undefined,
        };
      },
      // save auth token
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          const { token, expiresIn } = response.data.data.auth;
          dispatch(setAuth({ token, expiresIn }));

          // reload all queries related to User
          // reset slice and api states/cache
          dispatch(apiSlice.util.resetApiState());
          dispatch(resetAllSlices());
        } catch (error) {
          /* TODO: handle error */
        }
      },
    }),
    logoutUser: builder.mutation<object, void>({
      query() {
        return {
          url: "logout",
          method: "POST",
          credentials: CREDENTIALS ? "include" : undefined,
        };
      },
      // clear auth token
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(resetAuth());

          // reset slice and api states/cache
          dispatch(apiSlice.util.resetApiState());
          dispatch(resetAllSlices());

          await queryFulfilled;
        } catch (error) {
          // TODO: handle error
        } finally {
          // TODO: remember current page, so we can come back to it after login

          // go to login page
          if (history.navigate) history.navigate("/login");

          console.log("Logging out: COMPLETE");
        }
      },
    }),
    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordParams>({
      query(body) {
        return {
          url: "change-password",
          method: "POST",
          body,
        };
      },
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, ForgetPasswordParams>({
      query(body) {
        return {
          url: "forgot-password",
          method: "POST",
          body,
        };
      },
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordParams>({
      query(body) {
        return {
          url: "password-reset",
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useSignUpUserMutation,
  useLogoutUserMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApiSlice;
