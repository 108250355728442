import { combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/api-slice";
import authSlice from "../features/auth/auth-slice";
import topicSlice from "../features/topic/topic-slice";
import navSlice from "../features/nav/nav-slice";

const rootReducer = combineReducers({
  topic: topicSlice,
  auth: authSlice,
  nav: navSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
