import { Form } from "antd";
import { useCreateIntroPageMutation } from "../../../../features/api/professor/page";
import IntroForm from "../components/IntroForm";
import { InputFormData, IntroForm2, OutputFormData } from "../components/IntroForm2";
import { Container } from "./style";

export default function IntroCreate({
  topicId,
  onDone,
  onCancel,
}: {
  topicId: number;
  onDone: (pageId: number) => void;
  onCancel: () => void;
}) {
  const [createIntro, { isLoading: isCreating }] = useCreateIntroPageMutation();

  return (
    <IntroForm
      initialData={{ title: "", content: "" }}
      onCancel={onCancel}
      onSubmit={(data) => {
        createIntro({ ...data, topic_id: Number(topicId) })
          .unwrap()
          .then((page) => onDone(page.id))
          .catch(onCancel);
      }}
      isLoading={isCreating}
    />
  );
}

export function IntroCreate2({
  topicId,
  onDone,
  onCancel,
}: {
  topicId: number;
  onDone: (pageId: number) => void;
  onCancel: () => void;
}) {
  const [createIntro, { isLoading: isCreating }] = useCreateIntroPageMutation();
  const [introForm] = Form.useForm<OutputFormData>();

  return (
    <Container>
    <IntroForm2
      form={introForm}
      initialValues={{ title: "", content: "" }}
      disabled={false}
      onCancel={onCancel}
      onSubmit={(data: InputFormData) => {
        createIntro({ ...data, topic_id: Number(topicId) })
          .unwrap()
          .then((page) => onDone(page.id))
          .catch(onCancel);
      }}
      onDone={onDone}
      isLoading={isCreating}
    />
    </Container>
  );
}