import { TokenData } from "../auth/auth-slice";

export enum Tag {
  MY_DATA = "MyData",
  USER_DATA = "UserData",
  TOPIC = "Topic",
  INTRO_PAGE = "IntroPage",
  EXAMPLE_PROBLEM = "ExampleProblem",
  EXAMPLE_STEP = "ExampleStep",
  PROBLEM = "Problem",
  PROBLEM_STEP = "ProblemStep",
  COURSE_TEMPLATE = "CourseTemplate",
  COURSE = "Course",
  COURSE_STUDENTS = "CourseStudents",
  COURSE_GRADES = "CourseGrades",
  ASSIGNMENT = "Assignment",
  STUDENT_ASSIGNMENT = "StudentAssignment",
  STUDENT_ASSIGNMENT_SUMMARY = "StudentAssignmentSummary",
}

export type TopicSummary = {
  _id: string;
  title: string;
  course: string;
  score?: number;
  maxScore: number;
  dueDate: string;
};

export type TopicSummaryResponse = {
  topics: TopicSummary[];
};

export type TopicInfo = {
  title: string;
  section: string;
};

export type TopicDescription = {
  pages: IntroPage[];
  examples: ExampleProblem[];
};

export type IntroPage = {
  title: string;
  content: string;
};

export type ExampleProblem = {
  id: string;
  title: string;
  question: string;
  steps: ExampleStep[];
};

export type ExampleStep = {
  expression: string;
  explanation: string;
};

export type Problem = {
  id: string;
  question: string;
  totalSteps: number;
  steps: Step[];
  status: ProblemStatus;
};

export type Step = {
  id: number;
  status: StepStatus;
  attempts: Attempt[];
  hint: string;
  points: number;
};

export type Attempt = {
  expression: string;
  explanation: string;
  isCorrect: boolean;
  submittedAt: Date;
};

export type ProblemStatus = "correct" | "incorrect" | "unanswered";

export type StepStatus = "correct" | "incorrect" | "unanswered";

export type SignUpResponse = {
  data: string;
  message: string;
};

export type SignUpParams = {
  firstname: string;
  lastname: string;
  affiliation: string;
  institutional_id?: number;
  user_bio?: string;
  username: string;
  email: string;
  password: string;
  roles?: UserRole[];
};

export type LoginResponse = {
  data: {
    auth: TokenData;
    user: UserPrivate;
  };
  message: string;
};

export type ChangePasswordParams = {
  userId: number;
  currentPassword: string;
  newPassword: string;
};

export type ChangePasswordResponse = {
  data: {
    userId: number;
  };
  message: string;
};

export type ForgotPasswordResponse = {
  data: string;
  message: string;
};

export type ForgetPasswordParams = {
  email: string;
  basePath: string;
};

export type ResetPasswordResponse = {
  data: number[];
  message: string;
};

export type ResetPasswordParams = {
  token: string;
  userId: number;
  password: string;
};

export type LoginParams = {
  email: string;
  password: string;
};

export interface ApiUserInterface {
  id: number;
  profile_id?: number;
  username: string;
  email: string;
  password: string;
  roles: UserRole[];
  is_active: number;
  password_reset_token?: string;
}

export interface ApiUserProfileInterface {
  id: number;
  firstname: string;
  lastname: string;
  affiliation: string;
  institutional_id?: number;
  user_bio?: string;
  profile_picture_url?: string;
  created_at: Date;
  updated_at: Date;
}

export interface UserWithProfile {
  user: ApiUserInterface;
  profile: ApiUserProfileInterface;
}

export type UserPrivate = Pick<
  ApiUserInterface,
  "id" | "profile_id" | "username" | "email" | "roles" | "is_active" | "password_reset_token"
>;

export interface UserPrivateWithProfile {
  user: UserPrivate;
  profile?: ApiUserProfileInterface;
}

export interface UserPrivateWithProfileRaw {
  user: Omit<UserPrivate, "roles"> & { roles: string };
  profile?: ApiUserProfileInterface;
}

export type UserPublic = Pick<
  ApiUserInterface,
  "id" | "profile_id" | "username" | "email" | "is_active" | "roles"
>;

export interface UserPublicWithProfile {
  user: UserPublic;
  profile?: ApiUserProfileInterface;
}

export interface UserPublicWithProfileRaw {
  user: Omit<UserPublic, "roles"> & { roles: string };
  profile?: ApiUserProfileInterface;
}

export interface UserPublicWithProfileRaw2 extends UserPublic {
  profile?: ApiUserProfileInterface;
}

export const roles = [
  "ROLE_USER",
  "ROLE_STUDENT",
  "ROLE_PROFESSOR",
  "ROLE_SUPER_PROFESSOR",
  "ROLE_ADMIN",
] as const;
export type UserRole = (typeof roles)[number];
