import { createSlice } from "@reduxjs/toolkit";

export interface INav {
  leftDropdown: boolean;
  rightDropdown: boolean;
}

const initialState: INav = {
  leftDropdown: false,
  rightDropdown: false,
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    toggleLeftSidebar: (state) => {
      state.rightDropdown = false;
      state.leftDropdown = !state.leftDropdown;
    },
    toggleRightSidebar: (state) => {
      state.leftDropdown = false;
      state.rightDropdown = !state.rightDropdown;
    },
    hideLeftSidebar: (state) => {
      state.leftDropdown = false;
    },
    hideRightSidebar: (state) => {
      state.rightDropdown = false;
    },
  },
});

export default navSlice.reducer;

export const { toggleLeftSidebar, toggleRightSidebar, hideLeftSidebar, hideRightSidebar } =
  navSlice.actions;
