import {
  roles,
  UserPrivateWithProfile,
  UserPublicWithProfile,
  UserRole,
} from "../../../features/api/types";

/**
 * Provides general permissions (create user) for specified user
 * @author Vyacheslav Lazurenko (PID: 6116403)
 * @param user person that uses the webpage
 */
export function getGeneralPermission(user?: UserPrivateWithProfile): GeneralPermission {
  const userRoles = user?.user.roles || [];
  if (userRoles.includes("ROLE_ADMIN")) {
    return {
      canCreate: {
        student: true,
        professor: true,
        superProfessor: true,
        admin: true,
      },
    };
  } else if (userRoles.includes("ROLE_SUPER_PROFESSOR")) {
    return {
      canCreate: {
        student: true,
        professor: true,
        superProfessor: false,
        admin: false,
      },
    };
  } else if (userRoles.includes("ROLE_PROFESSOR")) {
    return {
      canCreate: {
        student: true,
        professor: false,
        superProfessor: false,
        admin: false,
      },
    };
  } else {
    return {
      canCreate: false,
    };
  }
}

/**
 * Provides permissions for update, delete operations for specified target
 * @author Vyacheslav Lazurenko (PID: 6116403)
 * @param caller person that uses the webpage
 * @param target resource - user in question
 */
export function getTargetPermission({
  caller,
  target,
}: {
  caller?: UserPrivateWithProfile;
  target: UserPublicWithProfile;
}): TargetPermission {
  const userRoles = caller?.user.roles || [];
  const targetRoles = target.user.roles;
  // for every target role caller must have a role that allows creation of that target role
  const isAuthorized = targetRoles.every((role) =>
    userRoles.some((callerRole) => editAuthorized[callerRole].includes(role)),
  );
  return {
    canEdit: isAuthorized,
    canDelete: isAuthorized,
  };
}

export const editAuthorized: Record<UserRole, UserRole[]> = {
  ROLE_ADMIN: [...roles], // all roles
  ROLE_SUPER_PROFESSOR: ["ROLE_USER", "ROLE_STUDENT", "ROLE_PROFESSOR"],
  ROLE_PROFESSOR: ["ROLE_USER", "ROLE_STUDENT"],
  ROLE_STUDENT: [],
  ROLE_USER: [],
};

interface GeneralPermission {
  canCreate:
    | {
        student: boolean;
        professor: boolean;
        superProfessor: boolean;
        admin: boolean;
      }
    | false;
}

interface TargetPermission {
  canEdit: boolean;
  canDelete: boolean;
}
