import React from "react";
import {
  Button,
  ColorPicker,
  DatePicker,
  Empty,
  Form,
  FormInstance,
  Input,
  message,
  Select,
  Space,
} from "antd";
import { Dayjs } from "dayjs";
import { Color } from "antd/lib/color-picker";
import { useGetMeQuery, useGetUsersQuery } from "../../../features/api/user-api";
import { getAllCourseTemplatePath } from "../nav";
import RoleTag from "../../../components/Role/RoleTag";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../features/api/types";

export interface InputFormData {
  semester: string;
  section: string;
  year: Dayjs;
  color: string;
  instructorId: number | null;
}

export interface OutputFormData {
  semester: string;
  section: string;
  year: Dayjs;
  color: Color | string;
  instructorId?: number;
}

export function CourseForm({
  form,
  initialValues,
  disabled = false,
}: {
  form: FormInstance<OutputFormData>;
  initialValues?: Partial<InputFormData>;
  disabled?: boolean;
}) {
  return (
    <Form
      form={form}
      name="course_form"
      layout="vertical"
      initialValues={initialValues}
      disabled={disabled}
    >
      <SectionInput />
      <SemesterInput />
      <YearInput />
      <ColorInput disabled={disabled} />
      <InstructorSelect />
    </Form>
  );
}

function YearInput() {
  return (
    <Form.Item
      name="year"
      label="Year"
      rules={[{ required: true, message: "Please select year!" }]}
    >
      <DatePicker picker={"year"} />
    </Form.Item>
  );
}

function ColorInput({ disabled = false }: { disabled?: boolean }) {
  return (
    <Form.Item name="color" label="Color">
      <ColorPicker
        presets={[
          {
            label: "Recommended",
            colors: [
              "#F5222D",
              "#FA8C16",
              "#FADB14",
              "#8BBB11",
              "#52C41A",
              "#13A8A8",
              "#1677FF",
              "#2F54EB",
              "#722ED1",
              "#EB2F96",
            ],
          },
        ]}
        disabled={disabled}
      />
    </Form.Item>
  );
}

function InstructorSelect() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { data: me } = useGetMeQuery();
  const {
    data: instructorCandidates,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetUsersQuery({ role: "ROLE_PROFESSOR" });

  if (isError) {
    messageApi.open({
      key: "COURSE_INSTRUCTOR_QUERY",
      type: "error",
      content: "Unable to retrieve list of instructors",
    });
  }

  return (
    <>
      {contextHolder}
      <Form.Item
        name="instructorId"
        label="Instructor"
        required={false}
        tooltip="Instructor will be able to add/remove students, create assignments and view grades. You will be able to do the same."
      >
        <Select
          allowClear
          showSearch
          loading={isLoading || isFetching}
          placeholder="Please select instructor"
          notFoundContent={
            <Empty
              imageStyle={{ height: 60 }}
              description={
                <div style={{ maxWidth: "300px", margin: "auto" }}>
                  No available instructors. Please, <b>create</b> user with{" "}
                  <RoleTag role={"ROLE_PROFESSOR"} /> role before completing this form.
                </div>
              }
            >
              {(["ROLE_ADMIN", "ROLE_SUPER_PROFESSOR"] as UserRole[]).some((r) =>
                me?.user.roles.includes(r),
              ) && (
                <Button type="primary" onClick={() => navigate(getAllCourseTemplatePath())}>
                  Manage Users
                </Button>
              )}
            </Empty>
          }
        >
          {isSuccess &&
            instructorCandidates.map((i) => (
              <Select.Option key={i.user.id} value={i.user.id}>
                <Space>
                  <span>{i.profile && `${i.profile.firstname} ${i.profile.lastname}`}</span>
                  <span>({i.user.email})</span>
                </Space>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
}

function SemesterInput() {
  return (
    <Form.Item
      name="semester"
      label="Semester"
      rules={[{ required: true, message: "Please select semester!" }]}
    >
      <Select>
        <Select.Option value={"spring"}>Spring</Select.Option>
        <Select.Option value={"summer"}>Summer</Select.Option>
        <Select.Option value={"fall"}>Fall</Select.Option>
      </Select>
    </Form.Item>
  );
}

function SectionInput() {
  return (
    <Form.Item
      name="section"
      label="Section"
      rules={[{ required: true, message: "Please input section!" }]}
    >
      <Input />
    </Form.Item>
  );
}
