import styled from "styled-components";

export const StyledTableContainer = styled.div`
  & table {
    border: 1px solid lightgrey;
    border-collapse: collapse;
  }

  & table tbody {
    border-bottom: 1px solid lightgrey;
  }

  & table tbody td:not(:first-child):not(:last-child) {
    padding-right: 22px;
  }

  & table th {
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    padding: 2px 4px;
  }

  & table tfoot {
    color: lightgrey;
  }

  & table tfoot th {
    font-weight: normal;
  }
`;

export const EditContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  align-items: center;
  text-align: center;

  transition: 4ms;
  z-index: 20;

  width: 637px;
  height: 738px;
`;

export const EditContainerSmall = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  transition: 4ms;
  z-index: 20;

  width: 400px;
  height: 350px;
  &.addStudent {
    width: 413px;
    height: 297px;
  }
`;

export const EditLabel = styled.label`
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin: auto;
  color: rgba(0, 0, 0, 0.7);
  & div.ql-snow.ql-container {
    height: 320px;
  }
`;

export const EditInput = styled.input`
  box-sizing: border-box;
  text-align: center;
  height: 40px;
  /* left: 498px;
  top: 369px; */
  width: 345px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
`;

export const EditInputSmall = styled.input`
  text-align: center;
  box-sizing: border-box;
  height: 36px;
  width: 200px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
`;

export const SelectInput = styled.select`
  text-align: center;
  box-sizing: border-box;
  height: 36px;
  width: 200px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
`;

export const OptionSelect = styled.option`
  text-align: center;
  box-sizing: border-box;
  height: 36px;
  width: 200px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  width: 500px;
  justify-content: center;
`;

export const DateContainer = styled.div`
  display: flex;
  width: 500px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: auto;
`;

// Professor Assignment Page

export const BodyContent = styled.div`
  margin: auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 20px;
`;

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const BottomSection = styled.div`
  width: 70%;
`;

export const LeftPanel = styled.div`
  width: 70%;
`;

export const RightPanel = styled.div`
  width: 30%;
`;

export const DatesPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`;

export const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const DateSideBox = styled.div`
  box-sizing: border-box;
  border: 1px solid #c0bbbb;
  background-color: #e3e1e1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 250px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DateSideBoxTitle = styled.div`
  padding: 10px 0 20px 0;
  font-weight: bold;
  font-size: 50px;
`;

export const DateSideBoxContent = styled.div``;

export const SectionTitle = styled.div`
  font-weight: bold;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
`;

export const Subtitle = styled.div`
  font-weight: bold;
`;

export const DescriptionText = styled.div`
  width: 90%;
  padding: 10px 0 30px 0;
  word-wrap: break-word;
`;

export const DateFormat = styled.div``;

export const LeftSubArea = styled.div`
  padding: 5px;
`;

export const RightSubArea = styled.div`
  padding: 5px 0 0 50px;
`;

export const CourseContainer = styled.div`
  margin-top: 10px;
`;

export const CoursePanel = styled.div<CoursePanelMarkProps>`
  box-sizing: border-box;
  border: 1px solid #${(props) => props.courseColor || "2E6EA5"};
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 250px;
  height: 60px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

interface CoursePanelMarkProps {
  courseColor?: string;
}

export const CoursePanelMark = styled.div<CoursePanelMarkProps>`
  height: 100%;
  width: 10px;
  background: #${(props) => props.courseColor || "2E6EA5"};
  margin-right: 10px;
`;

export const CoursePanelContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CourseName = styled.div``;

export const CourseSemester = styled.div``;

export const CourseCount = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopicContainer = styled.div`
  margin-top: 10px;
`;

export const TopicPanel = styled.div`
  box-sizing: border-box;
  border: 1px solid #2e6ea5;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 250px;
  height: 60px;
  display: flex;
  flex-direction: row;
`;

export const TopicPanelContent = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
`;

export const TopicName = styled.div``;

export const TopicCount = styled.div`
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 12px;
  max-width: 900px;
  margin: 30px 0 50px 0;
`;

export const EditButton = styled.button`
  min-width: 80px;
  min-height: 40px;
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: #0077cc;
  color: #fff;
  margin-left: 10px;
  font-size: 18px;
`;

export const ResetButton = styled.button`
  min-width: 150px;
  min-height: 40px;
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: #c4c4c4;
  color: #000;
  margin-left: 10px;
  font-size: 18px;
`;

export const DeleteButton = styled.button`
  min-width: 80px;
  min-height: 40px;
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: #d25959;
  color: #fff;
  margin-left: 10px;
  font-size: 18px;
`;

export const CourseTopicPanel = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const PieContainer = styled.div`
  padding-top: 20px;
`;

export const StudentProgressContainer = styled.div`
  width: 20em;
`;

export const ItemDot = styled.span`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
`;
