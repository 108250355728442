import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

type Prop = {
  error: FetchBaseQueryError | SerializedError;
};
export default function ErrorMessages({ error }: Prop) {
  return (
    <>
      <p style={{ color: "red", textAlign: "center" }}>
        {JSON.stringify(error).includes("Wrong authentication token") ||
        JSON.stringify(error).includes("Authentication token missing")
          ? "SESSION EXPIRED"
          : JSON.stringify(error)}
      </p>
    </>
  );
}
