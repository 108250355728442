import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Button, PreviusButton } from "../../../../components/Button/Button";
import SolutionStepEdit from "../../../../components/SolutionStep/SolutionStepEdit";
import { StepExplanation } from "../../../Student/Assignment/Example/style";
import Editor from "./Editor";

interface FromData {
  solution: string;
  hint: string;
}

export default function ProblemStepFrom({
  initialData,
  index,
  onCancel,
  onSubmit,
  isLoading,
}: {
  initialData: FromData;
  index: number;
  onCancel: () => void;
  onSubmit: (data: FromData) => void;
  isLoading: boolean;
}) {
  const [solution, setSolution] = useState(initialData.solution);
  const hintEditorRef = useRef<ReactQuill>(null);

  return (
    <tr key={index}>
      <td>
        <SolutionStepEdit
          index={index}
          expression={solution}
          onChange={(field) => setSolution(field.latex())}
        />
      </td>
      <td>
        <StepExplanation>
          <Editor initialValue={initialData.hint} ref={hintEditorRef} />
        </StepExplanation>
      </td>
      <td>
        <div>
          <PreviusButton className="problemStepEdit" onClick={onCancel}>
            Cancel
          </PreviusButton>
          <Button
            className="problemStepEdit"
            onClick={(event) => {
              event.preventDefault();
              onSubmit({
                solution,
                hint: hintEditorRef.current?.value.toString() || initialData.hint,
              });
            }}
          >
            Save
          </Button>
        </div>
      </td>
    </tr>
  );
}
