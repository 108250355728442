import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Container } from "./style";
import { ExampleEdit2 } from "./ExampleEdit";
import { ExampleView } from "./ExampleView";
import { useTopicContext } from "../Context";

export default function ExamplePage({ afterDelete }: { afterDelete: () => void }) {
  const params = useParams();
  const navigate = useNavigate();
  const exampleId = Number(params.exampleId || "");
  const { canEdit } = useTopicContext();

  return (
    <>
      <Container>
        <Routes>
          <Route
            index
            element={
              <ExampleView
                exampleId={exampleId}
                afterDelete={afterDelete}
                onEdit={() => navigate("edit")}
              />
            }
          />
          {canEdit && (
            <Route
              path="/edit"
              element={
                <ExampleEdit2
                  exampleId={exampleId}
                  onDone={() => navigate("")}
                  onCancel={() => navigate("")}
                />
              }
            />
          )}
        </Routes>
      </Container>
    </>
  );
}
