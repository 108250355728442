import React, { useState } from "react";
import Switch from "react-switch";
import { NextButton, PreviusButton } from "../../../../components/Button/Button";
import {
  EditButtonsContainer,
  EditContainer,
  EditText,
  EditTitleInput,
  EditTitleLabel,
  TransparentBackground,
} from "./EditModeStyle";
import { useGetCourseTemplatesQuery } from "../../../../features/api/professor/course";
import { SelectInput } from "../../Assignment/style";

interface FormData {
  title: string;
  courseTemplateId?: number;
  published: boolean;
}

export default function TopicForm({
  initialData,
  onCancel,
  onSubmit,
  isProcessing,
}: {
  initialData: FormData;
  onCancel: () => void;
  onSubmit: (data: Required<FormData>) => void;
  isProcessing: boolean;
}) {
  const [title, setTitle] = useState(initialData.title);
  const [published, setPublished] = useState(initialData.published);
  const [courseTemplateId, setCourseTemplateId] = useState<number>(0);

  const {
    data: availableCourseTemplates,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetCourseTemplatesQuery();

  if (isSuccess) {
    return (
      <TransparentBackground>
        <EditContainer style={{ height: "296px", overflow: "hidden" }}>
          <form style={{ overflow: "hidden" }}>
            <EditTitleLabel>
              <EditText>Topic Title:</EditText>
              <br />
              <EditTitleInput
                type="text"
                onChange={(event) => setTitle(event.target.value)}
                value={title}
                disabled={isProcessing}
                style={{ width: "363px" }}
              />
            </EditTitleLabel>

            <SelectInput
              onChange={(event) => {
                setCourseTemplateId(Number(event.target.value));
              }}
            >
              <option value={0}></option>
              {availableCourseTemplates.map((course, index) => (
                <option key={index} value={course.id}>
                  {course.name}
                </option>
              ))}
            </SelectInput>

            <EditTitleLabel>
              <EditText>Published:</EditText>

              <br></br>
              <Switch
                onChange={() => setPublished(!published)}
                checked={published}
                className="react-switch"
                onColor="#94fc75"
                onHandleColor="#a3f33b"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={23}
                width={39}
              />
            </EditTitleLabel>
            <br />
            <EditButtonsContainer>
              <PreviusButton onClick={onCancel} disabled={isProcessing}>
                Cancel
              </PreviusButton>
              <NextButton
                onClick={(event) => {
                  event.preventDefault();
                  if (courseTemplateId) {
                    onSubmit({ title, published, courseTemplateId });
                  } else {
                    alert("Please, select a valid 'subject' field");
                  }
                }}
                disabled={isProcessing}
              >
                Save
              </NextButton>
            </EditButtonsContainer>
          </form>
        </EditContainer>
      </TransparentBackground>
    );
  }

  return <p>Unable to retrieve data.</p>;
}
