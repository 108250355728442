import { ColumnsType } from "antd/lib/table";
import { Table } from "antd";
import React from "react";
import { ProblemScoreReport } from "../../../../features/api/professor/grades";
import StepsTable from "./StepsTable";

export interface ProblemsTableProps {
  data: ProblemScoreReport[];
}

type ProblemScoreRow = ProblemScoreReport & {
  index: number;
};

export default function ProblemsTable({ data }: ProblemsTableProps) {
  const rows: ProblemScoreRow[] =
    data.map((problem, index) => ({
      ...problem,
      index,
    })) || [];

  return (
    <Table
      columns={problemColumns}
      dataSource={rows}
      rowKey={(record) => record.problemId}
      expandable={{ expandedRowRender: (record) => <StepsTable data={record.steps} /> }}
      pagination={false}
      size="middle"
      bordered
      tableLayout="fixed"
      style={{
        maxWidth: 800,
      }}
    />
  );
}

const problemColumns: ColumnsType<ProblemScoreRow> = [
  {
    title: "Problem",
    key: "problemTitle",
    render: (_, record) => `Problem ${record.index + 1}`,
    rowScope: "row",
  },
  {
    title: "Earned",
    key: "earnedPoints",
    render: (_, record) =>
      record?.earnedPoints.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
  },
  // {
  //   title: "Lost",
  //   key: "lostPoints",
  //   render: (_, record) =>
  //     record?.lostPoints.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
  // },
  {
    title: "Remaining",
    key: "unansweredPoints",
    render: (_, record) =>
      record
        ? (record.maxPoints - record.earnedPoints - record.lostPoints).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })
        : "-",
  },
  {
    title: "Total",
    key: "maxPoints",
    render: (_, record) =>
      record.maxPoints.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
  },
];
