import ReactQuill from "react-quill";
import { Link } from "react-router-dom";
import { getExamplePage } from "../../pages/Student/Assignment/NavButtons";
import { StepMessageBanner, StepMessageProps } from "./style";

type Props = {
  content: string;
  onClose: () => void;
};
{
  /** children */
}
export default function MessageBanner({
  content,
  onClose,
  messageDisplay,
}: Props & StepMessageProps) {
  return (
    <StepMessageBanner messageDisplay={messageDisplay}>
      <button className="close" onClick={onClose}>
        Close
      </button>

      <ReactQuill style={{ minHeight: "10px" }} value={content} theme={"bubble"} readOnly={true} />
      {/* <Link to={getExamplePage(topicId, 1)} style={{ textAlign: "right" }}>
        Example
      </Link> */}
    </StepMessageBanner>
  );
}
