import { IoDocumentTextSharp } from "react-icons/io5";
import styled from "styled-components";

export const TopicPanel = styled.div`
  min-width: 360px;
  /* width: 360px; */
  height: 150px;
  max-width: 500px;
  background: #ffffff;
  border: 1px solid #2e6ea5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  padding: 10px;
  margin: 20px 41px 20px 41px;

  display: flex;
  flex-direction: row;
`;

export const TopicPanelLeft = styled.div`
  width: 280px;
  color: #2e6ea5;
  font-size: 22px;
  font-family: "Roboto";
  font-weight: 700;

  .subDetail {
    padding-top: 11px;

    color: #000000;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const TopicPanelRight = styled.div`
  width: 79px;
  align-items: center;
  display: flex;
  color: black;
`;

export const AssignmentIcon = styled(IoDocumentTextSharp)`
  font-size: x-large;
`;
