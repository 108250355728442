import MathField from "../../../components/Mathlive/MathField";
import { useState } from "react";

const initialValue = "f(x) = \\sin(x+\\pi)";

export default function CortexMathInput() {
  const [defaultValue, setDefualtValue] = useState(initialValue);
  const [trackedValue, setTrackedValue] = useState(initialValue);

  return (
    <div>
      <h1>Cortex math field</h1>
      <label>Edit</label>
      <MathField
        defaultValue={defaultValue}
        onChange={(v) => {
          setTrackedValue(v);
        }}
        onEnter={() => {
          console.log("ENTER");
        }}
        options={{
          defaultMode: "math",
          scriptDepth: 2,
          readOnly: false,
        }}
        style={{ marginLeft: "20px" }}
      />
      <br />
      <label>Static</label>
      <MathField
        defaultValue={trackedValue}
        options={{ readOnly: true }}
        style={{ marginLeft: "20px" }}
      />
      <br />
      <button
        onClick={() => {
          setDefualtValue("x=\\frac{-b\\pm \\sqrt{b^2-4ac}}{2a}");
        }}
      >
        Reset
      </button>
    </div>
  );
}
