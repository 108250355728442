import { apiSlice } from "../api-slice";
import { Tag } from "../types";
import { AssignmentStatus } from "../student/assignment";

export const assignmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInstructorAssignments: builder.query<Assignment[], void>({
      query: () => ({ url: "instructor/assignment" }),
      transformResponse(res: { data: Assignment[] }, meta, arg) {
        return res.data;
      },
      providesTags: (result, error) => [
        { type: Tag.ASSIGNMENT, id: "ALL_LIST" },
        ...(result ? result.map((a) => ({ type: Tag.ASSIGNMENT, id: a.id })) : []),
      ],
    }),
    getInstructorAssignmentsByCourse: builder.query<Assignment[], number>({
      query: (courseId) => ({
        url: "instructor/assignment",
        params: { course_id: courseId },
      }),
      transformResponse(res: { data: Assignment[] }, meta, arg) {
        return res.data;
      },
      providesTags: (result, error, courseId) => [
        { type: Tag.ASSIGNMENT, id: `COURSE_${courseId}_LIST` },
        ...(result ? result.map((a) => ({ type: Tag.ASSIGNMENT, id: a.id })) : []),
      ],
    }),
    getInstructorAssignment: builder.query<Assignment, number>({
      query: (assignmentId) => ({
        url: `instructor/assignment/${assignmentId}`,
      }),
      transformResponse(res: { data: Assignment }, meta, arg) {
        return res.data;
      },
      providesTags: (result, error, assignmentId) => [{ type: Tag.ASSIGNMENT, id: assignmentId }],
    }),
    createInstructorAssignment: builder.mutation<AssignmentAttributes, CreateAssignment>({
      query(data) {
        return {
          url: "instructor/assignment",
          method: "POST",
          body: data,
        };
      },
      transformResponse(res: { data: AssignmentAttributes }, meta, arg) {
        return res.data;
      },
      invalidatesTags: (result, error, data) => [
        { type: Tag.ASSIGNMENT, id: "ALL_LIST" },
        { type: Tag.ASSIGNMENT, id: `COURSE_${data.course_id}_LIST` },
      ],
    }),
    updateInstructorAssignment: builder.mutation<AssignmentAttributes, UpdateAssignment>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/assignment/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(res: { data: AssignmentAttributes }, meta, arg) {
        return res.data;
      },
      invalidatesTags: (result, error, course) =>
        result ? [{ type: Tag.ASSIGNMENT, id: result.id }] : [],
    }),
    deleteInstructorAssignment: builder.mutation<{ message: string }, number>({
      query: (assignmentId) => ({
        url: `instructor/assignment/${assignmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, assignmentId) => [
        { type: Tag.ASSIGNMENT, id: assignmentId },
      ],
    }),
    deleteInstructorAssignmentSubmissions: builder.mutation<{ message: string }, number>({
      query: (assignmentId) => ({
        url: `instructor/assignment/${assignmentId}/submissions`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, assignmentId) => [
        { type: Tag.ASSIGNMENT, id: assignmentId },
      ],
    }),
  }),
});

export const {
  useGetInstructorAssignmentsQuery,
  useGetInstructorAssignmentsByCourseQuery,
  useGetInstructorAssignmentQuery,
  useCreateInstructorAssignmentMutation: useCreateAssignmentMutation,
  useUpdateInstructorAssignmentMutation: useUpdateAssignmentMutation,
  useDeleteInstructorAssignmentMutation: useDeleteAssignmentMutation,
  useDeleteInstructorAssignmentSubmissionsMutation: useDeleteAssignmentSubmissionsMutation,
} = assignmentApiSlice;

export interface AssignmentAttributes {
  id: number;
  topic_id: number;
  course_id?: number;
  assigned_by_user_id?: number;
  start_date: string;
  end_date: string;
  title: string;
  description: string;
  allowed_attempts: number;
  min_pass_score: string;
  weight: number;
}

export type Assignment = AssignmentAttributes & {
  status: AssignmentStatus;
};
export type CreateAssignment = Omit<AssignmentAttributes, "id" | "assigned_by_user_id">;
export type UpdateAssignment = { id: number } & Partial<
  Pick<AssignmentAttributes, "start_date" | "end_date" | "title" | "description" | "weight">
>;
