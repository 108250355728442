import React from "react";
import dayjs from "dayjs";
import AssignmentFrom, { InputFormData, OutputFormData } from "./AssignmentFrom";
import { Form, message, Modal } from "antd";
import { useCreateAssignmentMutation } from "../../../features/api/professor/assignment";

export interface AssignmentCreateProps {
  open: boolean;
  initialValues?: Partial<AssignmentFromData>;
  visibility?: React.ComponentProps<typeof AssignmentFrom>["visibility"];
  onSubmit: () => void;
  onCancel: () => void;
}

export type AssignmentFromData = {
  courseId: number;
  topicId: number;
  title: string;
  description: string;
  weight: number;
  startDate: string;
  endDate: string;
};

export function ModalAssignmentCreate({
  open,
  initialValues,
  visibility,
  onSubmit,
  onCancel,
}: AssignmentCreateProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<OutputFormData>();
  const [createAssignment, { isLoading: isCreating }] = useCreateAssignmentMutation();

  let formInitialValues: InputFormData | undefined;
  if (initialValues) {
    const { startDate, endDate, ...otherValues } = initialValues;
    formInitialValues = {
      ...otherValues,
      date: [startDate ? dayjs(startDate) : undefined, endDate ? dayjs(endDate) : undefined],
    };
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        createAssignment({
          course_id: values.courseId,
          topic_id: values.topicId,
          start_date: values.date[0].toISOString(),
          end_date: values.date[1].toISOString(),
          title: values.title.trim(),
          description: values.description?.trim() || "",
          allowed_attempts: 3,
          min_pass_score: "",
          weight: values.weight,
        })
          .unwrap()
          .then(() => {
            messageApi.success("Assignment created");
            onSubmit();
            form.resetFields();
          })
          .catch(() => {
            messageApi.error("Failed to create assignment");
          });
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Create assignment"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleSubmit}
        confirmLoading={isCreating}
      >
        <AssignmentFrom form={form} initialValues={formInitialValues} visibility={visibility} />
      </Modal>
    </>
  );
}
