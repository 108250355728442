import { Form, Modal } from "antd";
import { ExampleStepForm2, OutputFormData } from "../../components/ExampleStepForm2";
import { EditExampleStepData } from "./ModalStepEdit";
import { CreateExampleStep } from "../../../../../features/api/professor/types";

interface ModalExampleStepCreateProps {
  open: boolean;
  index: number;
  onSubmit: (values: OutputFormData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalExampleStepCreate({
  open,
  index,
  onCancel,
  onSubmit,
  isLoading,
}: ModalExampleStepCreateProps) {
  const [form] = Form.useForm<OutputFormData>();

  const handleSubmit = async () => {
    try {
      const formValues = await form.validateFields();
      console.log("expression: " + formValues.expression);
      onSubmit({
        expression: formValues.expression.trim(),
        explanation: formValues.explanation.trim(),
      });
      form.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="New Example Step"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      // onOk={handleSubmit}
      footer={null}
      confirmLoading={isLoading}
    >
      <ExampleStepForm2
        form={form}
        index={index}
        initialValues={{
          expression: "",
          explanation: "",
        }}
        disabled={false}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  );
}
