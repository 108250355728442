import styled from 'styled-components';

export const QuestionContainer = styled.div`
  width: 100%;
`;

export const QuestionBody = styled.div`
  // font-size: 16px;
  // font-weight: 400;
  color: #000;
`;