import { Item, Section, SideNav } from "../../../components/SideNav";
import { useGetTopicQuery } from "../../../features/api/professor/topic";
import { useTopicContext } from "./Context";
import { NavContainer } from "./style";

export default function TopicNav({ topicId }: { topicId: string }) {
  const { canEdit: canEdit } = useTopicContext();

  const { data: topic, isSuccess, isLoading, isError, error } = useGetTopicQuery(topicId);

  if (isError) {
    return (
      <NavContainer>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </NavContainer>
    );
  }

  if (isLoading) {
    return (
      <NavContainer>
        <p>Loading...</p>
      </NavContainer>
    );
  }

  if (isSuccess) {
    const intro = topic.pages;
    const examples = topic.examples;
    const problems = topic.problems;

    const sections: Section[] = [
      {
        title: "Introduction",
        items: [
          ...intro.map(
            (page, pageIdx): Item => ({
              content: page.title,
              href: `intro/${page.id}`,
            }),
          ),
          ...(canEdit
            ? [
                {
                  content: <span style={{ fontWeight: "normal" }}>+ new</span>,
                  href: "intro/new",
                },
              ]
            : []),
        ],
      },
      {
        title: "Examples",
        items: [
          ...examples.map(
            (example, exampleIdx): Item => ({
              content: example.title,
              href: `example/${example.id}`,
            }),
          ),
          ...(canEdit
            ? [
                {
                  content: <span style={{ fontWeight: "normal" }}>+ new</span>,
                  href: "example/new",
                },
              ]
            : []),
        ],
      },
      {
        title: "Problems",
        items: [
          ...problems.map((problem, problemIdx): Item => {
            return {
              content: `Problem #${problemIdx + 1}`,
              href: `problem/${problem.id}`,
            };
          }),
          ...(canEdit
            ? [
                {
                  content: <span style={{ fontWeight: "normal" }}>+ new</span>,
                  href: "problem/new",
                },
              ]
            : []),
        ],
      },
    ];

    return (
      <NavContainer>
        <SideNav sections={sections} />
      </NavContainer>
    );
  }

  return <p>Something went wrong</p>;
}
