import { Assignment } from "../../../features/api/professor/assignment";
import { NavLink } from "react-router-dom";
import { getAssignmentPath } from "../../../pages/Professor/nav";
import DateMessages from "../../utils/Dates/DateMenssages";
import React from "react";
import { useGetTopicQuery } from "../../../features/api/professor/topic";
import { HWDate, HWDeco, HWDescription, HWPanel, HWTitle } from "./style";

export default function InstructorAssignmentTile({ data }: { data: Assignment }) {
  return (
    <NavLink to={getAssignmentPath(data.id)} style={{ textDecoration: "none", color: "black" }}>
      <HWPanel>
        <HWDeco />
        <HWTitle>{data.title}</HWTitle>
        <HWDescription>
          <div>
            Topic:{" "}
            <b>
              <TopicTitle id={data.topic_id} />
            </b>
          </div>
          <div>
            Points: <b>{data.weight}</b>
          </div>
        </HWDescription>
        <HWDate>
          <DateMessages start_date={data.start_date} end_date={data.end_date} />
        </HWDate>
      </HWPanel>
    </NavLink>
  );
}

function TopicTitle({ id: topicId }: { id: number }) {
  const { data: topic, isSuccess, isLoading, isError, error } = useGetTopicQuery(topicId);
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isSuccess) {
    return <span>{topic.title}</span>;
  }
  return <span>N/A</span>;
}
