import { apiSlice } from "../api-slice";
import { Tag } from "../types";
import {
  CreateTopicAttributes,
  Topic,
  TopicAttributes,
  TopicSummary,
  UpdateTopicAttributes,
} from "./types";

export const topicApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopicsSummary: builder.query<TopicSummary[], void>({
      query: () => ({ url: "instructor/topic" }),
      transformResponse(baseQueryReturnValue: { data: TopicSummary[] }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      providesTags: (result, error) => [
        { type: Tag.TOPIC, id: "ALL_LIST" },
        ...(result ? result.map((a) => ({ type: Tag.TOPIC, id: a.id })) : []),
      ],
    }),
    getTopicsByCourse: builder.query<TopicSummary[], number>({
      query: (courseId) => ({
        url: "instructor/topic",
        params: { course_id: courseId },
      }),
      transformResponse(baseQueryReturnValue: { data: TopicSummary[] }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      providesTags: (result, error, courseId) => [
        { type: Tag.TOPIC, id: `COURSE_${courseId}_LIST` },
        ...(result ? result.map((a) => ({ type: Tag.TOPIC, id: a.id })) : []),
      ],
    }),
    getTopic: builder.query<Topic, string | number>({
      query: (topicId) => ({ url: `instructor/topic/${topicId}` }),
      providesTags: (result, error, topicId) => {
        const problems = result?.problems.map((p) => ({ type: Tag.PROBLEM, id: p.id })) || [];
        const examples =
          result?.examples.map((e) => ({
            type: Tag.EXAMPLE_PROBLEM,
            id: e.id,
          })) || [];
        const pages = result?.pages.map((p) => ({ type: Tag.INTRO_PAGE, id: p.id })) || [];

        return [
          { type: Tag.TOPIC, id: topicId },
          { type: Tag.PROBLEM },
          ...problems,
          { type: Tag.EXAMPLE_PROBLEM },
          ...examples,
          { type: Tag.INTRO_PAGE },
          ...pages,
        ];
      },
    }),
    updateTopic: builder.mutation<Topic, UpdateTopicAttributes>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/topic/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(baseQueryReturnValue: { data: Topic }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, topic) => [{ type: Tag.TOPIC, id: topic.id }],
    }),
    createTopic: builder.mutation<Topic, CreateTopicAttributes>({
      query(data) {
        return {
          url: "instructor/topic",
          method: "POST",
          body: data,
        };
      },
      transformResponse(baseQueryReturnValue: { data: Topic }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, data) => [
        { type: Tag.TOPIC, id: "ALL_LIST" },
        { type: Tag.TOPIC, id: `COURSE_${data.courseTemplateId}_LIST` },
      ],
    }),
    deleteTopic: builder.mutation<Topic, number | string>({
      query: (topicId) => ({
        url: `instructor/topic/${topicId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, topicId) => [{ type: Tag.TOPIC, id: topicId }],
    }),
  }),
});

export const {
  useGetTopicsSummaryQuery,
  useGetTopicQuery,
  useGetTopicsByCourseQuery,
  useUpdateTopicMutation,
  useCreateTopicMutation,
  useDeleteTopicMutation,
} = topicApiSlice;
