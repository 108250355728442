import { Form } from "antd";
import { useCreateExampleProblemStepMutation } from "../../../../../features/api/professor/example";
import ExampleStepForm from "../../components/ExampleStepForm";
import { ExampleStepForm2, OutputFormData } from "../../components/ExampleStepForm2";
import { ModalExampleStepCreate } from "./ModalStepCreate";

export default function StepCreate({
  exampleId,
  index,
  onDone,
  onCancel,
}: {
  exampleId: number;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [createStep, { isLoading: isCreating }] =
    useCreateExampleProblemStepMutation();

  return (
    <ExampleStepForm
      index={index}
      initialData={{
        expression: "",
        explanation: "",
      }}
      onCancel={onCancel}
      onSubmit={(data) => {
        createStep({ ...data, example_problem_id: exampleId }).then(onDone);
      }}
      isLoading={isCreating}
    />
  );
}

export function StepCreate2({
  exampleId,
  index,
  onDone,
  onCancel,
}: {
  exampleId: number;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [createStep, { isLoading: isCreating }] = useCreateExampleProblemStepMutation();

  return (
    
    <ModalExampleStepCreate
      open={true}
      index={index}
      onCancel={onCancel}
      onSubmit={(data) => {
        createStep({ ...data, example_problem_id: exampleId }).then(onDone);
      }}
      isLoading={isCreating}
    />
  );
}

export function StepCreate3({
  exampleId,
  index,
  onDone,
  onCancel,
}: {
  exampleId: number;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [createStep, { isLoading: isCreating }] = useCreateExampleProblemStepMutation();
  const [form] = Form.useForm<OutputFormData>();

  return (
    
    <ExampleStepForm2
      index={index}
      onCancel={onCancel}
      onSubmit={(data) => {
        createStep({ ...data, example_problem_id: exampleId }).then(onDone);
      } }
      form={form}
    />
  );
}