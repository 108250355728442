import { isAnyOf } from "@reduxjs/toolkit";
import { AppStartListening } from "../../app/middleware";
import { hasAuth } from "../auth/auth-selectors";
import { authApiSlice } from "./auth-api";
import { assignmentApiSlice } from "./professor/assignment";
import { courseApiSlice } from "./professor/course";
import { exampleApiSlice } from "./professor/example";
import { mathApiSlice } from "./professor/math";
import { pageApiSlice } from "./professor/page";
import { problemApiSlice } from "./professor/problem";
import { studentAssignmentApiSlice } from "./student/assignment";
import { studentCourseApiSlice } from "./student/course";
import { userApiSlice } from "./user-api";

const endpoints = [
  ...Object.values(authApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(userApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(assignmentApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(courseApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(exampleApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(mathApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(pageApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(problemApiSlice.endpoints).map((e) => e.matchRejected),
  ...Object.values(studentAssignmentApiSlice.endpoints).map(
    (e) => e.matchRejected
  ),
  ...Object.values(studentCourseApiSlice.endpoints).map((e) => e.matchRejected),
];

export const registerLogoutMiddleware = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(endpoints[0], ...endpoints.slice(1)),
    effect: (action, { dispatch, getState }) => {
      if (action.payload?.status === 401) {
        if (hasAuth(getState())) {
          dispatch(authApiSlice.endpoints.logoutUser.initiate());
        }
      }
    },
  });
};
