import styled from "styled-components";

export const Button = styled.button`
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  height: 40px;
  /* line-height: 40px; */
  font-size: 18px;
  border-radius: 8px;
  padding: 0 14px;
  background-color: #2e6ea5;
  color: #ffffff;
  border: none;
  &:hover,
  &:focus {
    background: #00549e;
  }
  &:disabled {
    background: #00549e65;
  }

  &:focus {
    outline: 2px solid #70a9db;
  }

  &:active {
    transform: scale(0.98);
  }
  &.addStudent {
    height: 28px;
    font-size: 16px;
    padding: 0 7px;
  }
  &.problemStepEdit {
    height: 28px;
    font-size: 16px;
    padding: 0 7px;
    margin: 10px;

    border-radius: 4px;
    background-color: #2ecc71;
  }
`;

export const NextButton = styled(Button)`
  margin-left: 30%;
`;

export const PreviusButton = styled(Button)`
  margin-left: 20%;
  background-color: #84868b;
  &:hover,
  &:focus {
    background-color: #595b5e;
  }
  &.addStudent {
    height: 28px;
    font-size: 16px;
    padding: 0 7px;
  }
  &.problemStepEdit {
    height: 28px;
    font-size: 16px;
    padding: 0 7px;
    margin: 10px;
    border-radius: 4px;
    background-color: #bdc3c7;
  }
`;

export const DeleteButton = styled(Button)`
  background-color: #84868b;
  &:hover,
  :focus {
    background-color: #c50909;
  }

  &:disabled {
    background-color: #c509098b;
    pointer-events: none;
  }
  .a {
    transform: none;
  }
`;
