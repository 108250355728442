import * as React from "react";
import { useEffect, useRef } from "react";
import { MathfieldElement, MathfieldOptions } from "mathlive";

export type MathFieldProps = {
  options?: Partial<MathfieldOptions>;
  /**
   * Avoid setting value through this property because
   * it will cause re-render and move caret to the end of the input.
   * Consider using 'defaultValue' property.
   * @deprecated
   */
  value?: string;
  defaultValue?: string;
  onChange?: (latex: string) => void;
  onEnter?: (latex: string) => void;
  className?: string;
  style?: React.CSSProperties;
};

export default function MathField({
  value = "",
  defaultValue = "",
  onChange,
  onEnter,
  className,
  options,
  style,
}: MathFieldProps) {
  const mathFieldRef = useRef<MathfieldElement>(null);

  useEffect(() => {
    const mf = mathFieldRef.current;
    if (mf) {
      mf.defaultMode = "math";
      mf.macros = {
        ...mf.macros, // Preserve default macros
        "\\operatorname{or}": "\\;\\operatorname{or}\\;",
      };
      mf.inlineShortcuts = {
        ...mf.inlineShortcuts, // Preserve default shortcuts
        or: "\\operatorname{or}",
      };
      mf.addEventListener("input", () => {
        if (onChange) onChange(mf.value);
        // console.log(mf.value);
      });
    }
  }, []);

  useEffect(() => {
    const mf = mathFieldRef.current;
    if (mf) {
      Object.assign(mathFieldRef.current, options);
    }
  }, [options]);

  // useEffect(() => {
  //   updateValue(value);
  // }, [value]);

  useEffect(() => {
    updateValue(defaultValue);
  }, [defaultValue]);

  const updateValue = (v: string) => {
    const mf = mathFieldRef.current;
    if (mf) {
      mf.value = v;
      if (onChange) onChange(mf.value);
    }
  };

  return (
    <math-field
      ref={mathFieldRef}
      className={className}
      style={{
        fontSize: "24px",
        padding: "8px",
        ...(options?.readOnly
          ? { border: "none" }
          : {
              borderRadius: "8px",
              border: "1px solid rgba(0, 0, 0, .3)",
              boxShadow: "0 0 8px rgba(0, 0, 0, .2)",
            }),
        ...style,
      }}
    ></math-field>
  );
}
