import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1000px;
`;

export const Controls = styled.div`
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 30px 60px;
`;

export const CourseTemplatesContainer = styled.div`
  flex: auto;
  min-width: 600px;
  max-width: 1100px;
  margin: 0 auto;
  align-self: center;
`;
