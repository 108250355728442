import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import {
  NextButton,
  PreviusButton,
} from "../../../../components/Button/Button";
import {
  EditButtonsContainer,
  EditContainer,
  EditContentInput,
  EditText,
  EditTitleInput,
  EditTitleLabel,
} from "./EditModeStyle";
export interface FormData {
  title: string;
  content: string;
}

export default function IntroForm({
  initialData,
  onCancel,
  onSubmit,
  isLoading,
}: {
  initialData: FormData;
  onCancel: () => void;
  onSubmit: (data: { title: string; content: string }) => void;
  isLoading: boolean;
}) {
  const [title, setTitle] = useState(initialData.title);
  const contentEditorRef = useRef<ReactQuill>(null);

  return (
    <form>
      <EditTitleLabel>
        <EditText>Introduction Title:</EditText>
        <EditTitleInput
          type="text"
          onChange={(event) => setTitle(event.target.value)}
          value={title}
          disabled={isLoading}
        />
      </EditTitleLabel>
      <br />
      <EditTitleLabel>
        <EditText>Content</EditText>
        <EditContentInput
          initialValue={initialData.content}
          ref={contentEditorRef}
        />
      </EditTitleLabel>
      <br />
      <EditButtonsContainer>
        <PreviusButton onClick={onCancel} disabled={isLoading}>
          Cancel
        </PreviusButton>
        <NextButton
          onClick={(event) => {
            event.preventDefault();
            onSubmit({
              title,
              content:
                contentEditorRef.current?.value.toString() ||
                initialData.content,
            });
          }}
          disabled={isLoading}
        >
          Save
        </NextButton>
      </EditButtonsContainer>
    </form>
  );
}
