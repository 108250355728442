import { Route, Routes } from "react-router-dom";
import { Profile } from "./Profile/Profile";
import ChangePass from "./Profile/ChangePass";

export default function CommonRouters() {
  return (
    <Routes>
      <Route path="profile" element={<Profile />} />
      <Route path="password-change" element={<ChangePass />} />
    </Routes>
  );
}
