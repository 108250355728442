import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import {
  AssignmentStatus,
  useGetStudentAssignmentsQuery,
} from "../../../features/api/student/assignment";
import StudentAssignmentSummary from "../../../components/Assignment/StudentAssignmentSummary";
import { BodyContent } from "../../../components/Assignment/style";
import { ALContainer, ALLabel, ALSelect } from "../style";
import { useState } from "react";
import { useGetCoursesQuery } from "../../../features/api/student/course";

export default function StudAssignments() {
  const {
    data: assignments,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useGetStudentAssignmentsQuery();

  const {
    data: courses,
    error: errorCourses,
    isLoading: isLoadingCourses,
    isError: isErrorCourses,
    isSuccess: isSuccessCourses,
  } = useGetCoursesQuery();

  const navigate = useNavigate();

  const { courseId } = useParams();

  const [selectedCourse, setSelectedCourse] = useState(parseInt(courseId ? courseId : "0"));
  const [selectedStatus, setSelectedStatus] = useState(0);

  const optionsStatus = [
    { value: 0, label: "Any" },
    { value: 1, label: AssignmentStatus.OPEN },
    { value: 2, label: AssignmentStatus.CLOSED },
    { value: 3, label: AssignmentStatus.PLANNED },
  ];

  const assignmentsFiltered = assignments?.filter((assignment) => {
    if (selectedCourse && selectedStatus) {
      return (
        assignment.courseId === selectedCourse &&
        assignment.status === optionsStatus[selectedStatus].label
      );
    } else if (selectedCourse) {
      return assignment.courseId === selectedCourse;
    } else if (selectedStatus) {
      return assignment.status === optionsStatus[selectedStatus].label;
    }
    return true;
  });

  if (isError || isErrorCourses) {
    navigate("/");
  }

  /* Add a loading animation */
  if (isLoading || isLoadingCourses) {
    return <p>Loading...</p>;
  }
  const handleChangeCourse = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(parseInt(event.target.value));
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(parseInt(event.target.value));
  };

  if (isSuccess && isSuccessCourses) {
    return (
      <>
        <PageHeader header="My Assignments" subheader="  " />

        <BodyContent>
          <ALContainer>
            <ALLabel htmlFor="select">Course:</ALLabel>
            <ALSelect id="select" value={selectedCourse} onChange={handleChangeCourse}>
              <option key={0} value={0}>
                All
              </option>
              {courses.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.template.name} {course.section.toUpperCase()}{" "}
                  {course.semester.toUpperCase()} {course.year}
                </option>
              ))}
            </ALSelect>
            <ALLabel htmlFor="select">Status:</ALLabel>
            <ALSelect id="select" value={selectedStatus} onChange={handleChangeStatus}>
              {optionsStatus.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </ALSelect>
          </ALContainer>
          <StudentAssignmentSummary
            assignments={assignmentsFiltered ? assignmentsFiltered : assignments}
          />
        </BodyContent>
      </>
    );
  }

  return (
    <>
      <h1>Error</h1>
      <p>Something went wrong</p>
    </>
  );
}

interface CourseId {
  courseId: number;
}
