import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { addStyles } from "react-mathquill";
import { history } from "./app/history";
import ForgotPassword from "./pages/Login/ForgotPassword";
import PrivateManager from "./pages/PrivateManager";
import PrivateRoute from "./pages/PrivateRoute";
import {CortexMathInput, SubComponent as SubComponentTableTest} from "./pages/Test";
import { useEffect } from "react";
import { Login } from "./pages/Login/Login";
import ResetPassword from "./pages/Login/ResetPassword";

addStyles();
function App() {
  // TODO: use this history object instead of useNavigate (createContext)
  history.navigate = useNavigate();
  history.location = useLocation();

  const titleMap = [
    { path: "/login", title: "Login" },
    { path: "/dashboard", title: "Dashboard" },
    { path: "/prof/course", title: "Course Manager" },
    { path: "/prof/topic", title: "Topic Manager" },
    { path: "/stud/assignments", title: "Assignments" },
    { path: "/profile", title: "Profile" },
    { path: "/password-change", title: "Password Change" },
  ];

  const curLoc = useLocation();
  useEffect(() => {
    const curTitle = titleMap.find((item) => curLoc.pathname.includes(item.path));
    if (curTitle && curTitle.title) {
      document.title = "MATH-CyLE - " + curTitle.title;
    } else {
      document.title = "MATH-CyLE";
    }
  }, [curLoc]);

  return (
    <Routes>
      {/* public */}
      <Route index element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="password-forgot" element={<ForgotPassword />} />
      <Route path="password-reset" element={<ResetPassword />} />
      <Route path="test" element={<CortexMathInput />} />
      {/* private (login required) */}
      <Route element={<PrivateRoute />}>
        <Route path="*" element={<PrivateManager />} />
      </Route>
    </Routes>
  );
}

export default App;
