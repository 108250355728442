import { isAnyOf } from "@reduxjs/toolkit";
import { AppStartListening } from "../../app/middleware";
import { RootState } from "../../app/reducers";
import { resetAuth, setAuth } from "./auth-slice";

export const registerAuthMiddleware = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(setAuth, resetAuth),
    effect: (action, listenerApi) => {
      const authState = (listenerApi.getState() as RootState).auth;
      localStorage.setItem("Authorization", JSON.stringify(authState));
    },
  });
};
