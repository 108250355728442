import { apiSlice } from "../api-slice";
import { Tag } from "../types";
import {
  CreateProblemAttributes,
  CreateStepAttributes,
  Problem,
  Step,
} from "./types";

export const problemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProblem: builder.query<Problem, string | number>({
      query: (problemId) => ({ url: `instructor/topic/problem/${problemId}` }),
      providesTags: (result, error, problemId) => {
        return [
          { type: Tag.PROBLEM, id: problemId },
          ...(result?.steps?.map((step) => ({
            type: Tag.PROBLEM_STEP,
            id: step.id,
          })) || []),
        ];
      },
    }),
    updateProblem: builder.mutation<Problem, Partial<Problem>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/topic/problem/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(baseQueryReturnValue: { data: Problem }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, problem) => [
        { type: Tag.PROBLEM, id: problem.id },
      ],
    }),
    createProblem: builder.mutation<Problem, CreateProblemAttributes>({
      query(data) {
        return {
          url: `instructor/topic/problem`,
          method: "POST",
          body: data,
        };
      },
      transformResponse(baseQueryReturnValue: { data: Problem }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: [Tag.PROBLEM],
    }),
    deleteProblem: builder.mutation<Problem, number | string>({
      query: (problemId) => ({
        url: `instructor/topic/problem/${problemId}`,
        method: "DELETE",
      }),
      invalidatesTags: [Tag.PROBLEM],
    }),
    createProblemStep: builder.mutation<Step, CreateStepAttributes>({
      query(data) {
        return {
          url: `instructor/topic/step`,
          method: "POST",
          body: data,
        };
      },
      transformResponse(baseQueryReturnValue: { data: Step }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, step) => [
        { type: Tag.PROBLEM, id: step.problem_id },
      ],
    }),
    updateProblemStep: builder.mutation<Step, Partial<Step>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/topic/step/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(baseQueryReturnValue: { data: Step }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, problemStep) => [
        { type: Tag.PROBLEM_STEP, id: problemStep.id },
      ],
    }),
    deleteProblemStep: builder.mutation<void, number | string>({
      query: (stepId) => ({
        url: `instructor/topic/step/${stepId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, stepId) => [
        {
          type: Tag.PROBLEM_STEP,
          id: stepId,
        },
      ],
    }),
  }),
});

export const {
  useGetProblemQuery,
  useUpdateProblemMutation,
  useCreateProblemMutation,
  useDeleteProblemMutation,
  useCreateProblemStepMutation,
  useUpdateProblemStepMutation,
  useDeleteProblemStepMutation,
} = problemApiSlice;
