/**
 * Column definitions for Ant Design tables
 * @author Vyacheslav Lazurenko (PID: 6116403)
 */
import { ColumnType } from "antd/lib/table";
import { roles, UserRole } from "../../features/api/types";
import { Badge, Button, Input, InputRef, Space, Tooltip } from "antd";
import { StatusContainer } from "../../pages/Professor/User/style";
import React, { useRef } from "react";
import RoleTag, { roleSettings } from "../Role/RoleTag";
import { SearchOutlined } from "@ant-design/icons";

export interface Columns {
  userId: number;
  email: string;
  institutionalId: number | null;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  roles: UserRole[];

  earnedPoints: number | null;
  lostPoints: number | null;
  unansweredPoints: number | null;
  maxPoints: number | null;

  courseTemplateName: string;
  description: string;
  affiliation: string;
}

type ColumnIndex = keyof Columns;

export type UserColumns = Pick<
  Columns,
  "userId" | "email" | "institutionalId" | "firstName" | "lastName" | "isActive" | "roles"
>;

export type GradeColumns = Pick<
  Columns,
  "earnedPoints" | "lostPoints" | "unansweredPoints" | "maxPoints"
>;

export const getEarnedPointsColumn = <
  T extends Pick<GradeColumns, "earnedPoints">,
>(): ColumnType<T> => ({
  title: "Earned",
  key: "earnedPoints",
  render: (_, record) =>
    record.earnedPoints?.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
  sorter: (a, b) => (a.earnedPoints || -1) - (b.earnedPoints || -1),
  sortDirections: ["descend", "ascend"],
});

const getLostPointsColumn = <T extends Pick<GradeColumns, "lostPoints">>(): ColumnType<T> => ({
  title: "Lost",
  key: "lostPoints",
  render: (_, record) =>
    record.lostPoints?.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
  sorter: (a, b) => (a.lostPoints || -1) - (b.lostPoints || -1),
  sortDirections: ["descend", "ascend"],
});

const getUnansweredPointsColumn = <
  T extends Pick<GradeColumns, "unansweredPoints">,
>(): ColumnType<T> => ({
  title: "Remaining",
  key: "unansweredPoints",
  render: (_, record) =>
    record.unansweredPoints?.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
  sorter: (a, b) => (a.unansweredPoints || -1) - (b.unansweredPoints || -1),
  sortDirections: ["descend", "ascend"],
});

const getTotalPointsColumn = <T extends Pick<GradeColumns, "maxPoints">>(): ColumnType<T> => ({
  title: "Total",
  key: "maxPoints",
  render: (_, record) =>
    record.maxPoints?.toLocaleString("en-US", { maximumFractionDigits: 2 }) || "-",
});

const getEmailColumn = <T extends Pick<UserColumns, "email">>(): ColumnType<T> => ({
  title: "Email",
  dataIndex: "email",
  key: "email",
  sorter: (a, b) => a.email.localeCompare(b.email),
  ...getColumnSearchProps("email"),
});

const getInstitutionalIdColumn = <
  T extends Pick<UserColumns, "institutionalId">,
>(): ColumnType<T> => ({
  title: "ID",
  dataIndex: "institutionalId",
  key: "institutionalId",
  sorter: (a, b) => (a.institutionalId || -1) - (b.institutionalId || -1),
  ...getColumnSearchProps("institutionalId"),
});

const getFirstNameColumn = <T extends Pick<UserColumns, "firstName">>(): ColumnType<T> => ({
  title: "First Name",
  dataIndex: "firstName",
  key: "firstName",
  sorter: (a, b) => a.firstName?.localeCompare(b.firstName || "") || -1,
  ...getColumnSearchProps("firstName"),
});

const getLastNameColumn = <T extends Pick<UserColumns, "lastName">>(): ColumnType<T> => ({
  title: "Last Name",
  dataIndex: "lastName",
  key: "lastName",
  sorter: (a, b) => a.lastName?.localeCompare(b.lastName || "") || -1,
  ...getColumnSearchProps("lastName"),
});

const getIsActiveColumn = <T extends Pick<UserColumns, "isActive">>(): ColumnType<T> => ({
  title: "Status",
  key: "isActive",
  render: (_, record) => <UserStatus isActive={record.isActive} />,
  filters: [
    { text: "Active", value: true },
    { text: "Deactivated", value: false },
  ],
  onFilter: (value, record) => record.isActive === value,
});

const getRolesColumn = <T extends Pick<UserColumns, "roles">>(): ColumnType<T> => ({
  title: "Role",
  key: "roles",
  render: (_, record) => (
    <>
      {record.roles.map((role) => (
        <RoleTag key={role} role={role} />
      ))}
    </>
  ),
  filters: [
    ...roles
      .filter((r) => r != "ROLE_USER")
      .map((r) => ({
        text: roleSettings[r]?.text,
        value: r,
      })),
  ],
  onFilter: (value, record) => record.roles.includes(value as UserRole),
});

export const getColumnSearchProps = <T extends Pick<Columns, G>, G extends ColumnIndex>(
  key: G,
): ColumnType<T> => {
  const searchInput = useRef<InputRef>(null);
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined rev={undefined} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} rev={undefined} />
    ),
    onFilter: (value, record) =>
      record[key]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) || false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  };
};

function UserStatus({ isActive }: { isActive: boolean }) {
  if (isActive) {
    return (
      <Tooltip title="Active">
        <StatusContainer>
          <Badge status="success" size="default" />
        </StatusContainer>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Deactivated">
        <StatusContainer>
          <Badge status="error" size="default" />
        </StatusContainer>
      </Tooltip>
    );
  }
}

export const getColumn = {
  lastName: getLastNameColumn,
  firstName: getFirstNameColumn,
  institutionalId: getInstitutionalIdColumn,
  email: getEmailColumn,
  earnedPoints: getEarnedPointsColumn,
  lostPoints: getLostPointsColumn,
  unansweredPoints: getUnansweredPointsColumn,
  totalPoints: getTotalPointsColumn,
  isActive: getIsActiveColumn,
  roles: getRolesColumn,
};
