import { Outlet, useOutletContext } from "react-router-dom";

export type TopicContextType = {
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
};

export default function TopicContext(context: TopicContextType) {
  return <Outlet context={context} />;
}

export function useTopicContext() {
  return useOutletContext<TopicContextType>();
}
