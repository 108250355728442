import { Form } from "antd";
import {
  useGetIntroPageQuery,
  useUpdateIntroPageMutation,
} from "../../../../features/api/professor/page";
import { EditContainer } from "../components/EditModeStyle";
import IntroForm from "../components/IntroForm";
import { OutputFormData, IntroForm2 } from "../components/IntroForm2";

export default function IntroEdit({
  pageId,
  onDone,
  onCancel,
}: {
  pageId: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const { data: page, isSuccess } = useGetIntroPageQuery(pageId);

  const [updateIntro, { isLoading: isUpdating }] = useUpdateIntroPageMutation();

  if (isSuccess) {
    return (
      <EditContainer>
        <IntroForm
          initialData={{ ...page }}
          onCancel={onCancel}
          onSubmit={(data) => {
            updateIntro({ id: page.id, ...data }).then(onDone);
          }}
          isLoading={isUpdating}
        />
      </EditContainer>
    );
  }
  return null;
}

export function IntroEdit2({
  pageId,
  onDone,
  onCancel,
}: {
  pageId: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  
  const {
    data: page,
    isSuccess
  } = useGetIntroPageQuery(pageId);

  const [updateIntro, { isLoading: isUpdating }] = useUpdateIntroPageMutation();

  const [introForm] = Form.useForm<OutputFormData>();

  if (isSuccess) {
    return (
      <div style={{ width: "90%", padding: "1em 2em 1em 2em" }}>
        <IntroForm2
          form={introForm}
          initialValues={{ ...page }}
          onCancel={onCancel}
          onSubmit={(data) => {
            updateIntro({ id: page.id, ...data }).then(onDone);
          } }
          onDone={onDone}
          isLoading={isUpdating}
                  />
      </div>
    );
  }
  return null;
}