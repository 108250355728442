import { Form, FormInstance, Input, InputNumber, Select } from "antd";
import { UserRole } from "../../../features/api/types";
import React from "react";
import { InputVisibilityType } from "../Assignment/AssignmentFrom";
import { editAuthorized } from "./permission";
import { useGetMeQuery } from "../../../features/api/user-api";
import { roleSettings } from "../../../components/Role/RoleTag";
import * as colors from "@ant-design/colors";

export interface UserFormProps {
  form: FormInstance<UserFromData>;
  initialValues?: Partial<UserFromData>;
  visibility?: Partial<UserInputVisibility>;
  disabled?: boolean;
}

export interface UserFromData {
  institutionalId: number;
  email: string;
  firstName: string;
  lastName: string;
  roles: UserRole;
  affiliation?: string;
  biography?: string;
  password: string;
}

export interface UserInputVisibility {
  password: InputVisibilityType;
  roles: InputVisibilityType;
}

export default function UserForm({
  form,
  initialValues,
  visibility: _visibility,
  disabled = false,
}: UserFormProps) {
  const visibility: UserInputVisibility = {
    password: "visible",
    roles: "visible",
    ..._visibility,
  };

  const meQuery = useGetMeQuery();

  return (
    <Form
      form={form}
      name="user_form"
      layout="vertical"
      initialValues={initialValues}
      disabled={disabled}
    >
      <Form.Item
        name="institutionalId"
        label="ID"
        rules={[{ required: true, message: "Please enter student ID!" }]}
      >
        <InputNumber min={1} />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true, message: "Please enter firstname!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[{ required: true, message: "Please enter lastname!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="roles"
        label="Role"
        rules={visibility.roles ? [{ required: true, message: "Please select content!" }] : []}
        style={{ display: visibility.roles === "hidden" ? "none" : undefined }}
      >
        <Select
          allowClear
          loading={meQuery.isLoading || meQuery.isFetching}
          disabled={visibility.roles === "disabled"}
        >
          {meQuery.isSuccess &&
            editAuthorized[meQuery.data.user.roles.slice(-1)[0]].map((role) => {
              const settings = roleSettings[role];
              if (settings != null) {
                const color = colors.presetPalettes[settings.color][6];
                return (
                  <Select.Option key={role} value={role} label={settings.text}>
                    <span style={{ color: color }}>{roleSettings[role]?.text}</span>
                  </Select.Option>
                );
              } else {
                return null;
              }
            })}
        </Select>
      </Form.Item>

      <Form.Item name="affiliation" label="Affiliation">
        <Input />
      </Form.Item>

      <Form.Item name="biography" label="Biography">
        <Input.TextArea rows={4} placeholder="About this student (optional)" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={
          visibility.password === "visible"
            ? [{ required: true, message: "Please enter password!" }]
            : []
        }
        style={{ display: visibility.password === "hidden" ? "none" : undefined }}
      >
        <Input.Password disabled={visibility.password === "disabled"} />
      </Form.Item>
    </Form>
  );
}
