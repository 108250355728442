import { UserRole } from "../../features/api/types";
import { Tag } from "antd";
import React, { ReactNode } from "react";

export interface RoleTagProps {
  role: UserRole;
}

export default function RoleTag({ role }: RoleTagProps) {
  const settings = roleSettings[role];
  if (settings != null) {
    return (
      <Tag key={role} color={settings.color} bordered={false}>
        {settings.text}
      </Tag>
    );
  } else {
    return null;
  }
}

export const roleSettings: Record<UserRole, RoleAttrs | undefined> = {
  ROLE_ADMIN: { color: "red", text: "Admin" },
  ROLE_SUPER_PROFESSOR: { color: "purple", text: "Super-Instructor" },
  ROLE_PROFESSOR: { color: "blue", text: "Instructor" },
  ROLE_STUDENT: { color: "gold", text: "Student" },
  ROLE_USER: undefined,
};

export interface RoleAttrs {
  color: string;
  text: ReactNode;
}
