import {
  useGetCourseStudentsQuery,
  useRemoveCourseStudentsMutation,
} from "../../../features/api/professor/course";
import { App, Button, Dropdown, MenuProps, Space, Table } from "antd";
import { DownOutlined, ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import AddStudentNew from "./AddStudentNew";
import AddStudentExisting from "./AddStudentExisting";
import AddStudentFile from "./AddStudentFile";
import { useCourseContext } from "./CourseContext";
import { ColumnsType } from "antd/lib/table";
import { getColumn, UserColumns } from "../../../components/Table/columns";
import UserEdit from "../User/UserEdit";
import ChangePassword from "../User/ChangePassword";

export default function CourseStudentsList({ courseId }: { courseId: number }) {
  const { canAddStudent, canRemoveStudent } = useCourseContext();
  const { message, modal } = App.useApp();

  const {
    data: students,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetCourseStudentsQuery(courseId);
  const [removeStudent, { isLoading: isDeleting }] = useRemoveCourseStudentsMutation();

  const [addStudentModal, setAddStudentModal] = useState<AddStudentMethod | null>(null);
  const [editStudent, setEditStudent] = useState<number | null>(null);
  const [changePassStudent, setChangePassStudent] = useState<number | null>(null);

  const handleEdit = (row: RowDataType) => {
    setEditStudent(row.id);
  };

  const handleChangePassword = (row: RowDataType) => {
    setChangePassStudent(row.id);
  };

  const handleRemoveStudent = (row: RowDataType) => {
    const studentId = row.id;
    const username = row.email;
    modal.confirm({
      title: "Are you sure to remove this student?",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: (
        <span>
          Student <b>{username}</b> will be removed from this class.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        message.open({
          key: `remove-student-result-${studentId}`,
          type: "loading",
          content: "Removing student...",
        });
        removeStudent({ courseId, students: [studentId] })
          .unwrap()
          .then(() =>
            message.open({
              key: `remove-student-result-${studentId}`,
              type: "success",
              content: `Student ${username} removed`,
            }),
          )
          .catch(() =>
            message.open({
              key: `remove-student-result-${studentId}`,
              type: "error",
              content: `Unable to remove student ${username}`,
            }),
          );
      },
    });
  };

  const closeAddStudentModal = () => setAddStudentModal(null);

  const handleAddStudentButton: MenuProps["onClick"] = ({ key }) => {
    setAddStudentModal(key as AddStudentMethod);
  };

  const columns: ColumnsType<RowDataType> = [
    getColumn.institutionalId(),
    getColumn.email(),
    getColumn.firstName(),
    { ...getColumn.lastName(), defaultSortOrder: "ascend" },
  ];
  if (canRemoveStudent) {
    columns.push({
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <StudentActionsCell
          row={record}
          onEdit={handleEdit}
          onChangePassword={handleChangePassword}
          onRemove={handleRemoveStudent}
        />
      ),
    });
  }

  const rows: RowDataType[] =
    students?.map((s) => ({
      key: s.user.id,
      id: s.user.id,
      email: s.user.email,
      institutionalId: s.profile?.institutional_id || null,
      firstName: s.profile?.firstname || null,
      lastName: s.profile?.lastname || null,
    })) || [];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {canAddStudent && (
        <div>
          <Dropdown
            menu={{ items: addStudentItems, onClick: handleAddStudentButton }}
            trigger={["click"]}
          >
            <Button type="primary">
              <Space>
                Add Student
                <DownOutlined rev={undefined} />
              </Space>
            </Button>
          </Dropdown>
          <AddStudentNew
            courseId={courseId}
            isOpen={addStudentModal === "new"}
            onCancel={closeAddStudentModal}
            onOk={closeAddStudentModal}
          />
          <AddStudentExisting
            courseId={courseId}
            isOpen={addStudentModal === "existing"}
            onCancel={closeAddStudentModal}
            onOk={closeAddStudentModal}
          />
          <AddStudentFile
            courseId={courseId}
            isOpen={addStudentModal === "file"}
            onCancel={closeAddStudentModal}
            onOk={closeAddStudentModal}
          />
          <UserEdit
            userId={editStudent}
            onSubmit={() => setEditStudent(null)}
            onCancel={() => setEditStudent(null)}
          />
          <ChangePassword
            userId={changePassStudent}
            onSubmit={() => setChangePassStudent(null)}
            onCancel={() => setChangePassStudent(null)}
          />
        </div>
      )}
      <Table columns={columns} dataSource={rows} loading={isLoading || isFetching} />
    </Space>
  );
}

interface StudentActionsCellProps {
  row: RowDataType;
  onEdit: (row: RowDataType) => void;
  onChangePassword: (row: RowDataType) => void;
  onRemove: (row: RowDataType) => void;
}

function StudentActionsCell({ row, onEdit, onChangePassword, onRemove }: StudentActionsCellProps) {
  const items: MenuProps["items"] = [
    { key: "edit" as StudentActions, label: "Edit" },
    { key: "changePassword" as StudentActions, label: "Change Password" },
    { key: "remove" as StudentActions, label: "Remove", danger: true },
  ];

  const handleItemClick: MenuProps["onClick"] = ({ key }) => {
    switch (key as StudentActions) {
      case "edit":
        onEdit(row);
        break;
      case "changePassword":
        onChangePassword(row);
        break;
      case "remove":
        onRemove(row);
        break;
    }
  };

  return (
    <Dropdown menu={{ items, onClick: handleItemClick }} trigger={["click"]}>
      <Button type="default" icon={<MoreOutlined rev={undefined} />} />
    </Dropdown>
  );
}

type RowDataType = { key: React.Key; id: number } & Pick<
  UserColumns,
  "institutionalId" | "email" | "firstName" | "lastName"
>;

type AddStudentMethod = "new" | "existing" | "file";

const addStudentItems: MenuProps["items"] = [
  {
    label: "New",
    key: "new" as AddStudentMethod,
  },
  { label: "Existing", key: "existing" as AddStudentMethod },
  { label: "From file", key: "file" as AddStudentMethod },
];

type StudentActions = "edit" | "changePassword" | "remove";
