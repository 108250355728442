import {
  StepContainer,
  StepNumber,
  StepInputField,
  StepBody,
  StepFooter,
  StepFeedbackButton,
  StepHintButton,
  StepFooterControl,
} from "./style";
import SubmitButton from "./SubmitButton";
import MessageBanner from "./MessageBanner";
import { MessageDisplay } from "../../features/topic/types";
import { EditableMathField } from "react-mathquill";
import MathField from "../Mathlive/MathField";

type Props = {
  id: number;
  index: number;
  expression: string;
  isIncorrect: boolean;
  isUpdating: boolean;
  hintMessage: string;
  mistakeMessage?: string;
  messageDisplay: MessageDisplay;
  setMessageDisplay: (display: MessageDisplay) => void;
  changeInput: (input: string) => void;
  onSubmit: (stepId: number, expression: string) => void;
};

export default function StepInput({
  id,
  index,
  expression,
  changeInput,
  onSubmit,
  isIncorrect,
  isUpdating,
  hintMessage,
  mistakeMessage,
  messageDisplay,
  setMessageDisplay,
}: Props) {
  return (
    <StepContainer styling={isIncorrect ? "danger" : "primary"}>
      <StepNumber styling={isIncorrect ? "danger" : "primary"}>Step {index + 1}</StepNumber>
      <StepBody>
        <form
          style={{ display: "inline-flex" }}
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(id, expression);
          }}
        >
          <MathField
            defaultValue={expression}
            onChange={(value) => {
              if (!isUpdating) changeInput(value);
            }}
            style={{ minWidth: "250px", maxWidth: "550px" }}
          />
          <SubmitButton isIncorrect={isIncorrect} isLoading={isUpdating} />
        </form>
      </StepBody>
      <StepFooter>
        <StepFooterControl>
          <StepHintButton
            isSelected={messageDisplay === "HINT"}
            onClick={() => {
              if (messageDisplay === "HINT") setMessageDisplay("NONE");
              else setMessageDisplay("HINT");
            }}
          >
            Hint
          </StepHintButton>
          {isIncorrect && (
            <StepFeedbackButton
              isSelected={messageDisplay === "MISTAKE"}
              onClick={() => {
                if (messageDisplay === "MISTAKE") setMessageDisplay("NONE");
                else setMessageDisplay("MISTAKE");
              }}
            >
              Feedback
            </StepFeedbackButton>
          )}
        </StepFooterControl>
        {messageDisplay === "HINT" && (
          <MessageBanner
            messageDisplay="HINT"
            content={hintMessage}
            onClose={() => {
              setMessageDisplay("NONE");
            }}
          ></MessageBanner>
        )}
        {messageDisplay === "MISTAKE" && (
          <MessageBanner
            messageDisplay="MISTAKE"
            content={mistakeMessage || "This answer is incorrect"}
            onClose={() => {
              setMessageDisplay("NONE");
            }}
          ></MessageBanner>
        )}
      </StepFooter>
    </StepContainer>
  );
}
