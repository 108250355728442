import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/api-slice";
// import { logoutMiddleware } from "../features/api/logout-middleware";
import { listenerMiddleware } from "./middleware";
import rootReducer from "./reducers";

const localAuth = localStorage.getItem("Authorization");

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    auth: localAuth ? JSON.parse(localAuth) : {},
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({})
      .prepend(listenerMiddleware.middleware)
      // .prepend(logoutMiddleware.middleware)
      .concat([apiSlice.middleware]),
});

export type AppDispatch = typeof store.dispatch;
