import {
  Item,
  ItemLink,
  Items,
  Section,
  Sections,
  SectionTitle,
} from "./style";

export type Props = {
  sections: SectionType[];
};

export type SectionType = {
  title: string;
  items: ItemType[];
};

export type ItemType = {
  content: React.ReactNode;
  href: string;
};

export default function SideNav({ sections }: Props) {
  return (
    <>
      <Sections>
        {sections.map((section, sectionNo) => (
          <Section key={sectionNo}>
            <SectionTitle>{section.title}</SectionTitle>
            <Items>
              {section.items.map((item, itemNo) => (
                <Item key={itemNo}>
                  <ItemLink to={item.href}>{item.content}</ItemLink>
                </Item>
              ))}
            </Items>
          </Section>
        ))}
      </Sections>
    </>
  );
}
