import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 40px auto 0 auto;
`;

export const Table = styled.table`
  overflow: scroll;
  width: 800px;
  margin-bottom: 30px;
  border-spacing: 0;

  & > tbody > tr {
    & > td {
      padding-bottom: 20px; //make space between rows
    }
    & > td:first-child {
      //first column: step expression
      white-space: nowrap;
      vertical-align: top;
    }
    & > td:last-child {
      //second column: step explanation
    }
  }
`;

export const StepExplanation = styled.p`
  margin: 0;
  & div.ql-snow.ql-container {
    height: 220px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
`;
