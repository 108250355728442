import { IoTimeOutline } from "react-icons/io5";
import styled from "styled-components";

export const TimeContainer = styled.div`
  //margin-top: 20px;
  text-align: center;
  font-size: medium;
`;

export const ClockIcon = styled(IoTimeOutline)`
  margin-top: 15px;
  font-size: xx-large;
`;
