import { Link } from "react-router-dom";
import { StyledNavbar } from "./style";
import logo from "../companyLogo.png";
import { LeftNavEntries } from "./LeftSideNav/LeftSidePanel";
import LeftSideNav from "./LeftSideNav/LeftSideNav";
import RightSideNav from "./RightSideNav/RightSideNav";

export default function Navbar({ leftNavEntries }: { leftNavEntries?: LeftNavEntries[] }) {
  return (
    <>
      <StyledNavbar>
        <LeftSideNav entries={leftNavEntries ?? []} />
        <Link to={"/dashboard"}>
          <img src={logo} alt="logo" />
        </Link>
        <RightSideNav />
      </StyledNavbar>
    </>
  );
}
