import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Container } from "./style";
import ProblemEdit, { ProblemEdit2 } from "./ProblemEdit";
import ProblemView, { ProblemView2, ProblemView3 } from "./ProblemView";
import { useTopicContext } from "../Context";

export default function Problem({ afterDelete }: { afterDelete: () => void }) {
  const navigate = useNavigate();
  const params = useParams();
  const problemId = Number(params.problemId || "");
  const { canEdit } = useTopicContext();

  return (
    <>
      <Container>
        <Routes>
          <Route
            index
            element={
              <ProblemView
                problemId={problemId}
                afterDelete={afterDelete}
                onEdit={() => navigate("edit")}
              />
            }
          />
          {canEdit && (
            <Route
              path="/edit"
              element={
                <ProblemEdit
                  problemId={problemId}
                  onDone={() => navigate("")}
                  onCancel={() => navigate("")}
                />
              }
            />
          )}
        </Routes>
      </Container>
    </>
  );
}

export function ProblemPage2({ afterDelete }: { afterDelete: () => void }) {
  const navigate = useNavigate();
  const params = useParams();
  const problemId = Number(params.problemId || "");
  const { canEdit } = useTopicContext();

  return (
    <>
      <Container>
        <Routes>
          <Route
            index
            element={
              <ProblemView2
                problemId={problemId}
                afterDelete={afterDelete}
                onEdit={() => navigate("edit")}
              />
            }
          />
          {canEdit && (
            <Route
              path="/edit"
              element={
                <ProblemEdit2
                  problemId={problemId}
                  onDone={() => navigate("")}
                  onCancel={() => navigate("")}
                />
              }
            />
          )}
        </Routes>
      </Container>
    </>
  );
}

export function ProblemPage3({ afterDelete }: { afterDelete: () => void }) {
  const navigate = useNavigate();
  const params = useParams();
  const problemId = Number(params.problemId || "");
  const { canEdit } = useTopicContext();

  return (
    <>
      <Container>
        <Routes>
          <Route
            index
            element={
              <ProblemView3
                problemId={problemId}
                afterDelete={afterDelete}
                onEdit={() => navigate("edit")}
              />
            }
          />
          {canEdit && (
            <Route
              path="/edit"
              element={
                <ProblemEdit2
                  problemId={problemId}
                  onDone={() => navigate("")}
                  onCancel={() => navigate("")}
                />
              }
            />
          )}
        </Routes>
      </Container>
    </>
  );
}