import { apiSlice } from "../api-slice";
import { Tag, UserPublicWithProfile, UserPublicWithProfileRaw } from "../types";
import {
  AddStudentResult,
  Course,
  CourseAttributes,
  CourseTemplate,
  CourseTemplateAttributes,
  CreateCourseAttributes,
  CreateCourseTemplateAttributes,
  RemoveStudentResult,
  UpdateCourseAttributes,
  UpdateCourseTemplateAttributes,
} from "./types";
import { transformUserPublic } from "../util";

export const courseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourseTemplates: builder.query<CourseTemplate[], void>({
      query: () => ({ url: "instructor/course-template" }),
      transformResponse(res: { data: CourseTemplate[] }) {
        return res.data;
      },
      providesTags: (result) => [
        { type: Tag.COURSE_TEMPLATE, id: "LIST_ALL" },
        ...(result ? result.map((t) => ({ type: Tag.COURSE_TEMPLATE, id: t.id })) : []),
      ],
    }),
    getCourseTemplate: builder.query<CourseTemplate, number | string>({
      query: (templateId) => ({
        url: `instructor/course-template/${templateId}`,
      }),
      transformResponse(res: { data: CourseTemplate }) {
        return res.data;
      },
      providesTags: (result, error, templateId) => {
        const tags = [{ type: Tag.COURSE_TEMPLATE, id: templateId }];
        result?.courses?.forEach((course) => {
          tags.push({
            type: Tag.COURSE,
            id: course.id,
          });
        });
        return tags;
      },
    }),
    getCourseTemplateByCourseId: builder.query<CourseTemplateAttributes, number>({
      query: (courseId) => ({
        url: `instructor/course/${courseId}`,
      }),
      transformResponse(res: { data: CourseTemplateAttributes }) {
        return res.data;
      },
      providesTags: (result) => (result ? [{ type: Tag.COURSE_TEMPLATE, id: result.id }] : []),
    }),
    getCourses: builder.query<Course[], void>({
      query: () => ({ url: "instructor/course" }),
      transformResponse(res: { data: Course[] }, arg) {
        return res.data;
      },
      providesTags: (result) => [
        { type: Tag.COURSE, id: "LIST_ALL" },
        ...(result ? result.map((c) => ({ type: Tag.COURSE, id: c.id })) : []),
      ],
    }),
    getRecentCourses: builder.query<Course[], void>({
      query: () => ({ url: "instructor/course/recent" }),
      transformResponse(res: { data: Course[] }) {
        return res.data;
      },
      providesTags: (result) => [
        { type: Tag.COURSE, id: "LIST_RECENT" },
        ...(result ? result.map((c) => ({ type: Tag.COURSE, id: c.id })) : []),
      ],
    }),
    getCourse: builder.query<Course, number | string>({
      query: (courseId) => ({
        url: `instructor/course/${courseId}`,
      }),
      transformResponse(res: { data: Course }) {
        return res.data;
      },
      providesTags: (result) =>
        result
          ? [
              { type: Tag.COURSE, id: result.id },
              { type: Tag.COURSE_TEMPLATE, id: result.template.id },
            ]
          : [],
    }),
    getCourseStudents: builder.query<UserPublicWithProfile[], number>({
      query: (courseId) => ({
        url: `instructor/course/${courseId}/student`,
      }),
      transformResponse: (res: { data: UserPublicWithProfileRaw[] }) =>
        res.data.map(transformUserPublic),
      providesTags: (result, error, courseId) => [{ type: Tag.COURSE_STUDENTS, id: courseId }],
    }),
    getCoursesByTemplateId: builder.query<CourseAttributes[], number | string>({
      query: (templateId) => ({
        url: `instructor/course-template/${templateId}/courses`,
      }),
      transformResponse(res: { data: CourseAttributes[] }) {
        return res.data;
      },
      providesTags: (result, error, templateId) => {
        const tags = [{ type: Tag.COURSE_TEMPLATE, id: templateId }];
        result?.forEach((course) => {
          tags.push({ type: Tag.COURSE, id: course.id });
        });
        return tags;
      },
    }),
    createCourse: builder.mutation<CourseAttributes, CreateCourseAttributes>({
      query(data) {
        return {
          url: "instructor/course",
          method: "POST",
          body: data,
        };
      },
      transformResponse(res: { data: CourseAttributes }) {
        return res.data;
      },
      invalidatesTags: [
        { type: Tag.COURSE, id: "LIST_ALL" },
        { type: Tag.COURSE, id: "LIST_RECENT" },
      ],
    }),
    updateCourse: builder.mutation<CourseAttributes, UpdateCourseAttributes>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/course/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(res: { data: CourseAttributes }) {
        return res.data;
      },
      invalidatesTags: (result) => (result ? [{ type: Tag.COURSE, id: result.id }] : []),
    }),
    deleteCourse: builder.mutation<{ message: string }, number>({
      query: (courseId) => ({
        url: `instructor/course/${courseId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, courseId) => [{ type: Tag.COURSE, id: courseId }],
    }),
    createCourseTemplate: builder.mutation<
      CourseTemplateAttributes,
      CreateCourseTemplateAttributes
    >({
      query(data) {
        return {
          url: "instructor/course-template",
          method: "POST",
          body: data,
        };
      },
      transformResponse(res: { data: CourseTemplateAttributes }) {
        return res.data;
      },
      invalidatesTags: [{ type: Tag.COURSE_TEMPLATE, id: "LIST_ALL" }],
    }),
    updateCourseTemplate: builder.mutation<
      CourseTemplateAttributes,
      UpdateCourseTemplateAttributes
    >({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/course-template/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(res: { data: CourseTemplateAttributes }) {
        return res.data;
      },
      invalidatesTags: (result) => (result ? [{ type: Tag.COURSE_TEMPLATE, id: result.id }] : []),
    }),
    deleteCourseTemplate: builder.mutation<{ message: string }, number>({
      query: (id) => ({
        url: `instructor/course-template/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, courseId) => [{ type: Tag.COURSE_TEMPLATE, id: courseId }],
    }),
    addCourseStudents: builder.mutation<AddStudentResult, { courseId: number; students: number[] }>(
      {
        query(data) {
          return {
            url: `instructor/course/${data.courseId}/student`,
            method: "POST",
            body: { student_ids: data.students },
          };
        },
        transformResponse(res: { data: AddStudentResult }) {
          return res.data;
        },
        invalidatesTags: (result, error, data) => [
          { type: Tag.COURSE_STUDENTS, id: data.courseId },
        ],
      },
    ),
    addCourseStudentsFromFile: builder.mutation<AddStudentResult, { courseId: number; data: File }>(
      {
        query({ courseId, data }) {
          const form = new FormData();
          form.append("title", "Student Roster CSV File");
          form.append("file", data);

          return {
            url: `instructor/course/${courseId}/student/csv`,
            method: "POST",
            body: form,
          };
        },
        transformResponse(res: { data: AddStudentResult }) {
          return res.data;
        },
        invalidatesTags: (result, error, data) => [
          { type: Tag.COURSE_STUDENTS, id: data.courseId },
        ],
      },
    ),
    removeCourseStudents: builder.mutation<
      RemoveStudentResult,
      { courseId: number; students: number[] }
    >({
      query(data) {
        return {
          url: `instructor/course/${data.courseId}/student`,
          method: "DELETE",
          body: { student_ids: data.students },
        };
      },
      transformResponse(res: { data: RemoveStudentResult }) {
        return res.data;
      },
      invalidatesTags: (result, error, data) => [{ type: Tag.COURSE_STUDENTS, id: data.courseId }],
    }),
    getStudentCandidates: builder.query<UserPublicWithProfile[], number>({
      query: (courseId) => ({
        url: `instructor/course/${courseId}/student/candidates`,
      }),
      transformResponse: (res: { data: UserPublicWithProfileRaw[] }) =>
        res.data.map(transformUserPublic),
      providesTags: (result, error, courseId) => [{ type: Tag.COURSE_STUDENTS, id: courseId }],
    }),
  }),
});

export const {
  useGetCourseTemplatesQuery,
  useGetCourseTemplateQuery,
  useGetCourseTemplateByCourseIdQuery,
  useGetCourseQuery,
  useGetCoursesQuery,
  useGetRecentCoursesQuery,
  useDeleteCourseMutation,
  useGetCoursesByTemplateIdQuery,
  useUpdateCourseMutation,
  useUpdateCourseTemplateMutation,
  useCreateCourseMutation,
  useCreateCourseTemplateMutation,
  useDeleteCourseTemplateMutation,
  useGetCourseStudentsQuery,
  useAddCourseStudentsMutation,
  useAddCourseStudentsFromFileMutation,
  useGetStudentCandidatesQuery,
  useRemoveCourseStudentsMutation,
} = courseApiSlice;
