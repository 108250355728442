import { Form } from "antd";
import {
  useGetExampleProblemQuery,
  useUpdateExampleProblemMutation,
} from "../../../../features/api/professor/example";
import { EditContainer } from "../components/EditModeStyle";
import ExampleForm from "../components/ExampleFrom";
import { ExampleForm2, OutputFormData } from "../components/ExampleForm";
import { Container } from "./style";


export default function ExampleEdit({
  exampleId,
  onDone,
  onCancel,
}: {
  exampleId: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const { data: example, isSuccess } = useGetExampleProblemQuery(exampleId);

  const [updateExample, { isLoading: isUpdating }] =
    useUpdateExampleProblemMutation();

  if (isSuccess) {
    return (
      <EditContainer>
        <ExampleForm
          initialData={{
            title: example.title || `Example ${example.id}`,
            question: example.question,
          }}
          onCancel={onCancel}
          onSubmit={(data) => {
            updateExample({ id: example.id, ...data }).then(onDone);
          }}
          isLoading={isUpdating}
        />
      </EditContainer>
    );
  }
  return null;
}

export function ExampleEdit2({
  exampleId,
  onDone,
  onCancel,
}: {
  exampleId: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const { data: example, isSuccess } = useGetExampleProblemQuery(exampleId);
  const [updateExample, { isLoading: isUpdating }] = useUpdateExampleProblemMutation();
  const [exampleForm] = Form.useForm<OutputFormData>();

  if (isSuccess) {
    return (
      <Container>
        <ExampleForm2
          form={exampleForm}
          initialValues={{
            title: example.title || `Example ${example.id}`,
            question: example.question,
          }}
          onCancel={onCancel}
          onSubmit={(data) => {
            updateExample({ id: example.id, ...data }).then(onDone);
          }}
          onDone={onDone}
          isLoading={isUpdating}
        />
      </Container>
    );
  }
  return null;
}