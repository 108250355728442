import {
  Button,
  DatePicker,
  Empty,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import { useGetCoursesQuery } from "../../../features/api/professor/course";
import { useGetTopicsByCourseQuery } from "../../../features/api/professor/topic";
import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { getAllCoursePath, getAllTopicsPath } from "../nav";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../features/api/types";
import { useGetMeQuery } from "../../../features/api/user-api";

export interface AssignmentFormProps {
  form: FormInstance<OutputFormData>;
  initialValues?: Partial<InputFormData>;
  visibility?: Partial<InputVisibility>;
  disabled?: boolean;
}

export interface InputVisibility {
  course: InputVisibilityType;
  topic: InputVisibilityType;
}

export type InputVisibilityType = "visible" | "disabled" | "hidden";

export type InputFormData = {
  courseId?: number;
  topicId?: number;
  title?: string;
  date?: [Dayjs?, Dayjs?];
  description?: string;
  weight?: number;
};

export interface OutputFormData {
  courseId: number;
  topicId: number;
  title: string;
  date: [Dayjs, Dayjs];
  description?: string;
  weight: number;
}

export default function AssignmentFrom({
  form,
  initialValues,
  visibility: _visibility,
  disabled = false,
}: AssignmentFormProps) {
  const visibility: InputVisibility = {
    course: "visible",
    topic: "visible",
    ..._visibility,
  };

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const courseId = Form.useWatch("courseId", form);

  const { data: me } = useGetMeQuery();
  const coursesQuery = useGetCoursesQuery();
  const topicsQuery = useGetTopicsByCourseQuery(courseId);

  const isCourseSet = !isNaN(Number(courseId));

  const handleCourseChange = () => {
    form.setFieldsValue({ topicId: undefined });
  };

  if (coursesQuery.isError) {
    messageApi.open({
      key: "ASSIGNMENT_COURSE_QUERY",
      type: "error",
      content: "Unable to retrieve list of available courses",
    });
  }
  if (topicsQuery.isError) {
    messageApi.open({
      key: "ASSIGNMENT_TOPIC_QUERY",
      type: "error",
      content: "Unable to retrieve list of available topics",
    });
  }

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        requiredMark="optional"
        disabled={disabled}
      >
        <Form.Item
          name="courseId"
          label="Course"
          rules={[{ required: true, message: "Please select course!" }]}
          style={{ display: visibility.course === "hidden" ? "none" : undefined }}
        >
          <Select
            allowClear
            showSearch
            placeholder="Please select course"
            loading={coursesQuery.isLoading || coursesQuery.isFetching}
            onChange={handleCourseChange}
            disabled={visibility.course === "disabled"}
            notFoundContent={
              <Empty
                imageStyle={{ height: 60 }}
                description={
                  <div style={{ maxWidth: "300px", margin: "auto" }}>
                    No available courses. Please, <b>create</b> course before completing this form.
                  </div>
                }
                style={{ margin: "20px" }}
              >
                {(["ROLE_ADMIN", "ROLE_SUPER_PROFESSOR"] as UserRole[]).some((r) =>
                  me?.user.roles.includes(r),
                ) && (
                  <Button type="primary" onClick={() => navigate(getAllCoursePath())}>
                    Manage Courses
                  </Button>
                )}
              </Empty>
            }
          >
            {coursesQuery.isSuccess &&
              coursesQuery.data.map((course) => (
                <Select.Option key={course.id} value={course.id}>{`${course.template.name} ${
                  course.section
                } ${course.semester.toUpperCase()} ${course.year}`}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        {/* <Divider style={{ display: visibility.course === "hidden" ? "none" : undefined }} /> */}
        <Form.Item
          name="topicId"
          label="Content"
          rules={[{ required: true, message: "Please select content!" }]}
          style={{ display: visibility.topic === "hidden" ? "none" : undefined }}
        >
          <Select
            allowClear
            showSearch
            loading={topicsQuery.isLoading || topicsQuery.isFetching}
            placeholder="Please select content"
            disabled={!isCourseSet || visibility.topic === "disabled"}
            notFoundContent={
              <Empty
                imageStyle={{ height: 60 }}
                description={
                  <div style={{ maxWidth: "300px", margin: "auto" }}>
                    No available content. Please, <b>create</b> topic before completing this form.
                  </div>
                }
                style={{ margin: "20px" }}
              >
                {(["ROLE_ADMIN", "ROLE_SUPER_PROFESSOR"] as UserRole[]).some((r) =>
                  me?.user.roles.includes(r),
                ) && (
                  <Button type="primary" onClick={() => navigate(getAllTopicsPath())}>
                    Manage Topics
                  </Button>
                )}
              </Empty>
            }
          >
            {topicsQuery.isSuccess &&
              topicsQuery.data
                .filter((t) => t.published)
                .map((topic) => (
                  <Select.Option key={topic.id} value={topic.id}>
                    {topic.title}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please select title!" }]}
        >
          <Input disabled={!isCourseSet} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea
            rows={4}
            placeholder="A brief description of this assignment (optional)"
            disabled={!isCourseSet}
          />
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          rules={[
            { required: true, message: "Please enter start date and due date!" },
            () => ({
              validator(_, value) {
                if (
                  Array.isArray(value) &&
                  value[0] instanceof dayjs &&
                  value[1] instanceof dayjs
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please select start and due dates!"));
              },
            }),
          ]}
        >
          <DatePicker.RangePicker
            format={"MM/DD/YYYY h:mm a"}
            showTime={{
              format: "h:mm a",
              use12Hours: true,
              defaultValue: [dayjs("00:00 am", "h:mm a"), dayjs("11:59 pm", "h:mm a")],
            }}
            disabled={!isCourseSet}
          />
        </Form.Item>
        <Form.Item
          name="weight"
          label="Points"
          rules={[{ required: true, message: "Please input points!" }]}
          tooltip="Total points for this assignment. Students who complete the assignment 100% will be awarded this amount of points."
        >
          <InputNumber min={0} disabled={!isCourseSet} />
        </Form.Item>
      </Form>
    </>
  );
}
