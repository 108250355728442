import { Form, Input, InputNumber, message, Modal } from "antd";
import { useSignUpUserMutation } from "../../../features/api/auth-api";
import { useAddCourseStudentsMutation } from "../../../features/api/professor/course";

export interface StudentFormData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface AddStudentNewProps {
  courseId: number;
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
}

export default function AddStudentNew({ courseId, isOpen, onCancel, onOk }: AddStudentNewProps) {
  const [form] = Form.useForm<StudentFormData>();
  const [messageApi, contextHolder] = message.useMessage();

  const [registerStudent, registerRequest] = useSignUpUserMutation();
  const [enrollStudents, enrollRequest] = useAddCourseStudentsMutation();
  const requests = {
    isLoading: registerRequest.isLoading || enrollRequest.isLoading,
    isSuccess: registerRequest.isSuccess && enrollRequest.isSuccess,
    isError: registerRequest.isError || enrollRequest.isError,
  };

  const addStudent = async (args: StudentFormData) => {
    try {
      const registerData = await registerStudent({
        institutional_id: args.id,
        firstname: args.firstName,
        lastname: args.lastName,
        email: args.email,
        password: args.password,
        affiliation: "", // TODO: take affiliation of current user (requires backend changes)
        username: args.email,
        roles: ["ROLE_USER", "ROLE_STUDENT"],
      }).unwrap();

      const studentId = Number(registerData.data);

      if (isNaN(studentId)) {
        throw new Error("invalid student id");
      }

      const enrollData = await enrollStudents({
        courseId: courseId,
        students: [studentId],
      }).unwrap();

      if (enrollData.success.length === 1) {
        messageApi.success(`Added ${args.firstName} ${args.lastName} to course`);
      }
    } catch (error) {
      messageApi.error("Failed to add student to course", 5);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title="Add student"
        okText="Submit"
        cancelText="Cancel"
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              addStudent(values).then(() => {
                onOk();
                form.resetFields();
              });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        confirmLoading={requests.isLoading}
      >
        <Form form={form} name="add_new_student" layout="vertical">
          <Form.Item
            name="id"
            label="ID"
            rules={[{ required: true, message: "Please enter student ID!" }]}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "Please enter firstname!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Please enter lastname!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter password!" }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
