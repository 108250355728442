import styled from "styled-components";

export const HWPanel = styled.div`
  box-sizing: border-box;
  width: 455px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #c0bbbb;
  border-top: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: clip;
`;

export const HWPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const HWDeco = styled.div`
  /* width: 502 px; */
  height: 9px;
  //left: 0px;
  //top: 1px;
  //border-radius: 10px 10px 0 0;
  background: #34495e;
`;

export const HWTitle = styled.h3`
  font-size: large;
  font-weight: bold;
  margin: 18px 0 18px 20px;
`;

export const HWDescription = styled.div`
  //margin: 10px 0 10px 35px;
  margin-left: 40px;
  font-size: medium;
`;

export const HWDate = styled.div`
  text-align: end;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  margin: auto 24px 18px 0;
`;
