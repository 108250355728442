import { Form } from "antd";
import { Header } from "../../../../components/PageHeader";
import { useCreateExampleProblemMutation } from "../../../../features/api/professor/example";
import ExampleForm from "../components/ExampleFrom";
import { ExampleForm2, InputFormData, OutputFormData } from "../components/ExampleForm";
import { Container } from "./style";

export default function ExampleCreate({
  topicId,
  onDone,
  onCancel,
}: {
  topicId: number;
  onDone: (exampleId: number) => void;
  onCancel: () => void;
}) {
  const [createExample, { isLoading: isCreating }] =
    useCreateExampleProblemMutation();

  return (
    <>
      <Header>Create a New Example Page</Header>
      <ExampleForm
        initialData={{ title: "", question: "" }}
        onCancel={onCancel}
        onSubmit={(data) => {
          createExample({ ...data, topic_id: Number(topicId) })
            .unwrap()
            .then((example) => onDone(example.id))
            .catch(onCancel);
        }}
        isLoading={isCreating}
      />
    </>
  );
}

export function ExampleCreate2({
  topicId,
  onDone,
  onCancel,
}: {
  topicId: number;
  onDone: (exampleId: number) => void;
  onCancel: () => void;
}) {
  const [createExample, { isLoading: isCreating }] = useCreateExampleProblemMutation();
  const [exampleForm] = Form.useForm<OutputFormData>();

  return (
    <>
      <Container>
        <Header>Create a New Example Page</Header>
        <ExampleForm2
          form={exampleForm}
          initialValues={{ title: "", question: "" }}
          onCancel={onCancel}
          onSubmit={(data: InputFormData) => {
            createExample({ ...data, topic_id: Number(topicId) })
              .unwrap()
              .then((example) => onDone(example.id))
              .catch(onCancel);
          }}
          onDone={onDone}
          isLoading={isCreating}
        />
      </Container>
    </>
  );
}