import {
  UserPrivateWithProfile,
  UserPrivateWithProfileRaw,
  UserPublicWithProfile,
  UserPublicWithProfileRaw,
  UserRole,
} from "./types";

export function transformUserPublic(data: UserPublicWithProfileRaw): UserPublicWithProfile {
  let userInfo = {} as UserPublicWithProfile;
  
  try{
    userInfo = {
      user: {
        ...data.user,
        roles: JSON.parse(data.user.roles) as UserRole[],
      },
      profile: data.profile,
    };
  }catch(err){
    console.log(err)
  }

  return userInfo;
}

export function transformUserPrivate(data: UserPrivateWithProfileRaw): UserPrivateWithProfile {
  let userInfo = {} as UserPrivateWithProfile;

  try {
    userInfo = {
      user: {
        ...data.user,
        roles: JSON.parse(data.user.roles) as UserRole[],
      },
      profile: data.profile,
    };
  } catch (err) {
    console.log(err)
  }

  return userInfo;
}
