import { NavLink } from "react-router-dom";
import { getTopicPath } from "../../pages/Professor/nav";
import { AssignmentIcon, TopicPanel, TopicPanelLeft, TopicPanelRight } from "./style";
import { TopicSummary } from "../../features/api/professor/types";

export default function InstructorTopicTile({ topic }: { topic: TopicSummary }) {
  return (
    <NavLink to={getTopicPath(topic.id)} style={{ textDecoration: "none" }}>
      <TopicPanel key={topic.id}>
        <TopicPanelLeft>
          {topic.title}
          <p className="subDetail">
            {topic.published ? "Published" : "Unpublished"}
            <br />
            Created at:{" "}
            {new Date(topic.createdAt).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </p>
        </TopicPanelLeft>
        <TopicPanelRight>
          <AssignmentIcon /> {topic.activeAssignmentCount}
        </TopicPanelRight>
      </TopicPanel>
    </NavLink>
  );
}
