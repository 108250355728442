import React, { useRef, useState } from "react";
import { Button, Form, FormInstance, Input, message } from "antd";
import ReactQuill from "react-quill";
import { SaveFilled } from "@ant-design/icons";
import Editor2 from "./Editor2";

export interface InputFormData {
    question: string;
}

export interface OutputFormData {
    question: string;
}

export function ProblemForm2({
    form,
    initialValues,
    disabled = false,
    onCancel,
    onSubmit,
    onDone,
    isLoading = false,
}: {
    form: FormInstance<OutputFormData>;
    initialValues?: Partial<InputFormData>;
    disabled?: boolean;
    onCancel: () => void;
    onSubmit: (values: InputFormData) => void;
    onDone: (problemId: number) => void;
    isLoading: boolean;
}) {

    const [question, setQuestion] = useState(initialValues ? initialValues.question ? initialValues.question : "" : "");

    const quillRef = useRef<ReactQuill>(null);

    const handleSubmit = async () => {
        try {
            const introValues = await form.validateFields();
            onSubmit({
                question: introValues.question || question,
            });
            form.resetFields();
        } catch (info) {
            console.log("Validate Failed:", info);
        }
    };


    return (
        <Form
            form={form}
            name="problem_form"
            layout="vertical"
            initialValues={initialValues}
            disabled={disabled}
            onFinishFailed={() => message.error}
            onFinish={() => onDone}
        >
            <QuestionInput
                question={question}
                setQuestion={setQuestion}
                quillRef={quillRef}
            />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<SaveFilled rev={undefined} />}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="default"
                        onClick={onCancel}
                        danger
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
}

interface QuestionInputProps {
    question: string;
    setQuestion: React.Dispatch<React.SetStateAction<string>>;
    quillRef: any;
}

function QuestionInput({ question, setQuestion, quillRef }: QuestionInputProps) {
    return (
        <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "Please input question!" }]}
        >
            <Editor2 initialValue={question} ref={quillRef} onChange={setQuestion} reference={quillRef} />
        </Form.Item>
    );
}
