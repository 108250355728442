import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import { NextButton, PreviusButton } from "../../../../components/Button/Button";
import SectionHeader from "../../../../components/SectionHeader";
import { useGetStudentAssignmentQuery } from "../../../../features/api/student/assignment";
import { getExamplePage, getIntroPage } from "../NavButtons";
import { ButtonsContainer, Container, TextBox } from "./style";

export default function Introduction({ assignmentId }: { assignmentId: number }) {
  const params = useParams();

  // page number starts from '1' and up to 'total number of pages'
  const pageNo = Number(params.pageNo);

  const {
    data: assignment,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentAssignmentQuery(assignmentId);

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Container>
        <SectionHeader>Loading...</SectionHeader>
        <TextBox>
          <p>Loading...</p>
        </TextBox>
      </Container>
    );
  }

  if (isError) {
    return (
      <Container>
        <SectionHeader>Error</SectionHeader>
        <TextBox>
          <p>{JSON.stringify(error)}</p>
        </TextBox>
      </Container>
    );
  }

  if (isSuccess) {
    const pages = assignment.pages;
    const pageIndex = pageNo - 1;
    if (pageIndex >= 0 && pageIndex < pages.length) {
      const introPage = pages[pageIndex];
      return (
        <Container style={{ display: "block" }}>
          <SectionHeader>{introPage.title}</SectionHeader>
          <TextBox>
            <ReactQuill value={introPage.content} theme={"bubble"} readOnly={true} />
          </TextBox>
          <ButtonsContainer>
            <PreviusButton
              onClick={() => {
                const prev = pageNo - 1;
                if (prev > 0) navigate(getIntroPage(assignmentId, prev));
              }}
            >
              Back
            </PreviusButton>
            <NextButton
              onClick={() => {
                if (pageNo < pages.length) {
                  navigate(getIntroPage(assignmentId, pageNo + 1));
                } else {
                  navigate(getExamplePage(assignmentId, 1));
                }
              }}
            >
              Next
            </NextButton>
          </ButtonsContainer>
        </Container>
      );
    }
  }

  return (
    <Container>
      <SectionHeader>Error</SectionHeader>
      <TextBox>
        <p>Unknwon error!</p>
      </TextBox>
    </Container>
  );
}
