import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import PageHeader from "../../../components/PageHeader";
import { useGetStudentAssignmentsQuery } from "../../../features/api/student/assignment";
import StudentAssignmentSummary from "../../../components/Assignment/StudentAssignmentSummary";
import StudentCourses from "../../../components/Course/StudentCourses";
import { BodyContent } from "../../../components/Assignment/style";
import { BodyContainer, Container, ContentContainer } from "../style";

export default function StudDashboard() {
    const {
        data: assignments,
        error,
        isLoading,
        isError,
        isSuccess,
    } = useGetStudentAssignmentsQuery();

    const navigate = useNavigate();

    if (isError) {
        navigate("/");
    }

    /* Add a loading animation */
    if (isLoading) {
        return (
            <Container>
              <PageHeader header={"Loading..."} subheader={"Loading..."} />
              <BodyContainer>
                <ContentContainer>Loading...</ContentContainer>
              </BodyContainer>
            </Container>
          );
    }

    if (isSuccess) {
        return (
            <>
                <PageHeader header="My Dashboard" subheader="  " />
                
                <BodyContent>
                
                    <StudentCourses  isDashboard={true} />
                
                </BodyContent>
            </>
        );
    }

    return (
        <>
            <h1>Error</h1>
            <p>Something went wrong</p>
        </>
    );
}
