export function getDashboardPath() {
  return "/dashboard";
}

export function getTopicPath(topicId: number | string) {
  return `/stud/topic/${topicId}`;
}

export function getTopicTestPath(topicId: number | string) {
  return `/stud/topic-new/${topicId}`;
}

export function getProblemPath(problemId: number | string) {
  return `/stud/topic/problem/${problemId}`;
}

export function getExampleProblemPath(exampleId: number | string) {
  return `/stud/topic/example/${exampleId}`;
}

export function getIntroPagePath(pageId: number | string) {
  return `/stud/topic/page/${pageId}`;
}

export function getCreateIntroPagePath(topicId: number | string) {
  return `/stud/topic/page/new?topic_id=${topicId}`;
}

export function getCreateExamplePath(topicId: number | string) {
  return `/stud/topic/example/new?topic_id=${topicId}`;
}

export function getCreateProblemPath(topicId: number | string) {
  return `/stud/topic/problem/new?topic_id=${topicId}`;
}

export function getAllTopicsPath(){
  return `/stud/topic/all`
}
export function getCoursePath(courseId: number | string) {
  return `/stud/course/${courseId}`;
}

export function getCreateCoursePath() {
  return `/stud/course/new`;
}

export function getEditCoursePath(courseId: number | string) {
  return `/stud/course/${courseId}/edit`;
}

export function getCourseTemplatePath(templateId: number | string) {
  return `/stud/course-template/${templateId}`;
}

export function getCreateCourseTemplatePath() {
  return `/stud/course-template/new`;
}

export function getEditCourseTemplatePath(templateId: number | string) {
  return `/stud/course-template/${templateId}/edit`;
}

export function getAllCoursePath(){
  return`/stud/courses`;
}

export function getAssignmentPathById(assignmentId: number | string) {
  return `/stud/topic/${assignmentId}`;
}

export function getAllAssignmentPath(){
  return `/stud/assignments`
}

export function getAssignmentsByCourse(courseId: number | string){
  return `/stud/assignments/course/${courseId}`
}