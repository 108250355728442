import { useUpdateUserMutation } from "../../../features/api/user-api";
import { App, Form, Input, Modal } from "antd";

interface FormData {
  newPassword: string;
  newPasswordConfirmation: string;
}

export type ChangePasswordProps = {
  userId: number | null;
  onSubmit: () => void;
  onCancel: () => void;
};

export default function ChangePassword({
  userId: _userId,
  onSubmit,
  onCancel,
}: ChangePasswordProps) {
  const isOpen = _userId != null;
  const id = _userId || 0;

  const { message } = App.useApp();
  const [form] = Form.useForm<FormData>();

  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const displaySuccess = () => {
    message.open({
      type: "success",
      content: "Password changed",
    });
    onSubmit();
    form.resetFields();
  };

  const displayError = (error: { status: number; data: never }) => {
    message.open({
      type: "error",
      content: "Failed to change password",
      duration: 5,
    });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateUser({
          id: id,
          password: values.newPassword,
        })
          .unwrap()
          .then(displaySuccess)
          .catch(displayError);
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={isOpen}
      title="Change user password"
      okText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={isUpdating}
    >
      <Form
        form={form}
        name="change_password_form"
        layout="vertical"
        style={{ maxWidth: 600, margin: "40px auto", padding: "0 14px" }}
      >
        <Form.Item
          name="newPassword"
          label="New password"
          rules={[{ required: true, message: "Please input new password!" }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPasswordConfirmation"
          label="Confirm new password"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!"),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}
