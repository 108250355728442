import { SummaryPie } from "../../../../components/Assignment/AssigmentSummaryPie";
import { ItemDot } from "../style";
import React from "react";
import {
  AssignmentScoreReport,
  StudentScoreReportAttrs,
} from "../../../../features/api/professor/grades";

interface AverageReportProps {
  data: AssignmentScoreReport<StudentScoreReportAttrs>;
  width: number;
  height: number;
  fontSizeA: string;
  fontSizeT: string;
  centerX: number;
  centerY: number;
  innerRadius: number;
}

export default function AverageReport({
  data,
  width,
  height,
  fontSizeA,
  fontSizeT,
  centerX,
  centerY,
  innerRadius,
}: AverageReportProps) {
  const numStudents = data.students.length;
  const earnedAverage = data.total.earnedPoints / numStudents;
  const lostAverage = data.total.lostPoints / numStudents;
  const maxPoints = data.total.maxPoints / numStudents;
  const unansweredAverage = maxPoints - earnedAverage - lostAverage;
  const colors = ["#55A5E9", "#C4C4C4", "#ED3B3B"];

  const pieData: { x: string | number; y: number }[] = [
    { x: "Earned Points", y: earnedAverage },
    { x: "Unanswered", y: unansweredAverage },
    { x: "Lost Points", y: lostAverage },
  ];

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}>
        <div>
          <SummaryPie
            pieData={pieData}
            earnedAverage={earnedAverage}
            maxPoints={maxPoints}
            width={width}
            height={height}
            colors={colors}
            fontSizeA={fontSizeA}
            fontSizeT={fontSizeT}
            centerX={centerX}
            centerY={centerY}
            innerRadius={innerRadius}
          />
        </div>
        <div style={{ padding: "3em 0 0 1em" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "left",
              paddingTop: "10px",
            }}
          >
            <ItemDot style={{ backgroundColor: colors[0] }} />
            <span style={{ paddingLeft: "10px" }}>
              Earned:{" "}
              {earnedAverage.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "left",
              paddingTop: "10px",
            }}
          >
            <ItemDot style={{ backgroundColor: colors[1] }} />
            <span style={{ paddingLeft: "10px" }}>
              Unanswered:{" "}
              {unansweredAverage.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "left",
              paddingTop: "10px",
            }}
          >
            <ItemDot style={{ backgroundColor: colors[2] }} />
            <span style={{ paddingLeft: "10px" }}>
              Lost:{" "}
              {lostAverage.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
