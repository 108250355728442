import { Route, Routes } from "react-router-dom";
import AssignmentLayout from "./AssignmentLayout";
import AssignmentPage from "./AssignmentPage";

export default function AssignmentManager() {
  return (
    <Routes>
      <Route index element={<AssignmentLayout />} />
      <Route path="/:assignmentId/*" element={<AssignmentPage />} />
    </Routes>
  );
}
