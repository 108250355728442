import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Sections = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Section = styled.li``;

export const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin: 4px 20px;
  padding: 6px 2px;
`;

export const Items = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  font-size: 20px;
  font-weight: 700;
`;

export const ItemLink = styled(NavLink)`
  display: block;
  margin: 4px 20px;
  padding: 6px 16px;
  border-radius: 4px;
  text-decoration: none;
  color: #00000094;
  font-weight: 500;
  &:hover {
    background-color: #00000013;
  }
  &.active {
    background-color: #00000013;
    color: #2e6ea5;
  }
`;
