import { Step } from "../../../../components/SolutionStep";
import { ExampleStep } from "../../../../features/api/types";
import { StepExplanation, Table } from "./style";
import ReactQuill from "react-quill";

export type Props = {
  steps: ExampleStep[];
};

export default function ExampleSteps(props: Props) {
  const { steps } = props;
  return (
    <Table>
      <tbody>
        {steps.map((step, index) => {
          return (
            <tr key={index}>
              <td>
                <Step id={index} expression={step.expression} />
              </td>
              <td>
                <StepExplanation>
                  <ReactQuill value={step.explanation} theme={"bubble"} readOnly={true} />
                </StepExplanation>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
