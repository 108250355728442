import {
  AssignmentScoreReport,
  StudentScoreReportAttrs,
} from "../../../../features/api/professor/grades";
import { ItemDot } from "../style";
import React from "react";

export default function StudentProgress({
  data,
}: {
  data: AssignmentScoreReport<StudentScoreReportAttrs>;
}) {
  const { started, notStarted, finished } = data.progress;
  const total = started + notStarted + finished;
  const colors = ["#4D4D4D", "#F5C956", "#6A9E57"];

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "10px",
            width: "100%",
            paddingTop: "20px",
          }}
        >
          <div
            style={{ width: `${(notStarted / total) * 100}%`, backgroundColor: colors[0] }}
          ></div>
          <div style={{ width: `${(started / total) * 100}%`, backgroundColor: colors[1] }}></div>
          <div style={{ width: `${(finished / total) * 100}%`, backgroundColor: colors[2] }}></div>
        </div>
        <div style={{ padding: "10px 0 10px 0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "left",
              paddingTop: "10px",
            }}
          >
            <ItemDot style={{ backgroundColor: colors[0] }} />
            <span style={{ paddingLeft: "10px" }}>
              Not started:{" "}
              {notStarted.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "left",
              paddingTop: "10px",
            }}
          >
            <ItemDot style={{ backgroundColor: colors[1] }} />
            <span style={{ paddingLeft: "10px" }}>
              Started:{" "}
              {started.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "left",
              paddingTop: "10px",
            }}
          >
            <ItemDot style={{ backgroundColor: colors[2] }} />
            <span style={{ paddingLeft: "10px" }}>
              Finished:{" "}
              {finished.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
