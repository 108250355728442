import React from "react";
import { IoTimeOutline } from "react-icons/io5";
import { ClockIcon, TimeContainer } from "./styles";
import { Tooltip } from "antd";

interface Props {
  start_date: string;
  end_date: string;
}

export default function DateMessages({ start_date, end_date }: Props) {
  const current = new Date();
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  /**
   * Before it starts
   */

  if (startDate > current) {
    const differenceInMilliseconds = startDate.getTime() - current.getTime();
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return (
      <div style={{ display: "flex" }}>
        {/* <ClockIcon /> */}
        <TimeContainer>
          Starts on{" "}
          <Tooltip title={startDate.toLocaleString()}>
            <strong>
              {differenceInDays < 7
                ? startDate.toLocaleString([], {
                    weekday: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : startDate.toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
            </strong>
          </Tooltip>
        </TimeContainer>
      </div>
    );
  }

  /**
   * After it starts
   */

  if (endDate > current) {
    const differenceInMilliseconds = endDate.getTime() - current.getTime();
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return (
      <div style={{ display: "flex" }}>
        {/* <ClockIcon /> */}
        <TimeContainer>
          Due on{" "}
          <Tooltip title={endDate.toLocaleString()}>
            <strong>
              {differenceInDays < 7
                ? endDate.toLocaleString([], {
                    weekday: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : endDate.toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
            </strong>
          </Tooltip>
        </TimeContainer>
      </div>
    );
  }

  /**
   * Overdue
   */
  return (
    <div style={{ display: "flex" }}>
      {/* <ClockIcon /> */}
      <TimeContainer>Closed</TimeContainer>
    </div>
  );
}
