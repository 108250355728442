import { useRef } from "react";
import ReactQuill from "react-quill";
import {
  NextButton,
  PreviusButton,
} from "../../../../components/Button/Button";
import {
  EditButtonsContainer,
  EditContainer,
  EditContentInput,
  EditText,
  EditTitleLabel,
} from "./EditModeStyle";

export interface FormData {
  question: string;
}

export default function ProblemForm({
  initialData,
  onCancel,
  onSubmit,
  isLoading,
}: {
  initialData: FormData;
  onCancel: () => void;
  onSubmit: (data: FormData) => void;
  isLoading: boolean;
}) {
  const questionEditorRef = useRef<ReactQuill>(null);

  return (
    <EditContainer style={{ height: "643px" }}>
      <form style={{ overflow: "hidden" }}>
        <EditTitleLabel>
          <EditText style={{ paddingTop: "6%" }}>Question:</EditText>
          <br />
          <EditContentInput
            initialValue={initialData.question}
            ref={questionEditorRef}
          />
        </EditTitleLabel>
        <br />
        <EditButtonsContainer>
          <PreviusButton
            onClick={(event) => {
              event.preventDefault();
              onCancel();
            }}
            disabled={isLoading}
          >
            Cancel
          </PreviusButton>
          <NextButton
            onClick={(event) => {
              event.preventDefault();
              onSubmit({
                question:
                  questionEditorRef.current?.value.toString() ||
                  initialData.question,
              });
            }}
            disabled={isLoading}
          >
            Save
          </NextButton>
        </EditButtonsContainer>
      </form>
    </EditContainer>
  );
}
