import { apiSlice } from "../api-slice";
import { StepStatus, Tag } from "../types";

export const gradeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGradesForAssignment: builder.query<
      AssignmentScoreReportDetailed,
      number
    >({
      query: (assignmentId) => ({
        url: `instructor/assignment/${assignmentId}/score-report`,
      }),
      transformResponse(
        res: { data: AssignmentScoreReportDetailed },
        meta,
        arg
      ) {
        return res.data;
      },
      providesTags: (result, error, assignmentId) => [
        { type: Tag.ASSIGNMENT, id: assignmentId },
      ],
    }),
    getGradesForCourse: builder.query<CourseScoreReport, number>({
      query: (courseId) => ({
        url: `instructor/course/${courseId}/score-report`,
      }),
      transformResponse(res: { data: CourseScoreReport }, meta, arg) {
        return res.data;
      },
      providesTags: (result, error, courseId) => [
        { type: Tag.COURSE_GRADES, id: courseId },
      ],
    }),
  }),
});

export const { useGetGradesForAssignmentQuery, useGetGradesForCourseQuery } =
  gradeSlice;

export interface CourseScoreReport {
  courseId: number;
  assignments: CourseAssignmentScoreReport[];
}

export type AssignmentScoreReportDetailed =
  AssignmentScoreReport<StudentScoreReport>;
export type CourseAssignmentScoreReport =
  AssignmentScoreReport<StudentScoreReportAttrs>;

export type AssignmentScoreReport<TStudent extends StudentScoreReportAttrs> = {
  assignmentId: number;
  courseId: number;
  total: {
    earnedPoints: number;
    lostPoints: number;
    maxPoints: number;
  };
  progress: {
    notStarted: number;
    started: number;
    finished: number;
  };
  students: TStudent[];
};

export interface StudentScoreReportAttrs {
  studentId: number;
  earnedPoints: number;
  lostPoints: number;
  maxPoints: number;
}

export interface StudentScoreReport extends StudentScoreReportAttrs {
  problems: ProblemScoreReport[];
}

export interface ProblemScoreReport {
  studentId: number;
  problemId: number;
  earnedPoints: number;
  lostPoints: number;
  maxPoints: number;
  steps: StepScoreReport[];
}

export interface StepScoreReport {
  stepId: number;
  numAttempts: number;
  status: StepStatus;
  points: number;
}
