import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import {
  useCreateTopicMutation,
  useGetTopicsSummaryQuery,
} from "../../../features/api/professor/topic";
import { getDashboardPath } from "../nav";
import { Button, Empty, message, theme } from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { ModalTopicCreate } from "./TopicCreate";
import { useTopicContext } from "./Context";
import { TopicLayoutContainer, TopicLayoutControl, TopicListContainer } from "./style";
import TopicTile from "../../../components/Topic/InstructorTopicTile";

export default function TopicLayout() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { token } = theme.useToken();
  const { canCreate } = useTopicContext();

  const { data: topics, isSuccess, isLoading, isError, error } = useGetTopicsSummaryQuery();
  const [createTopic, { isLoading: isCreating }] = useCreateTopicMutation();

  const [isCreate, setIsCreate] = useState(false);

  if (isSuccess) {
    return (
      <>
        {contextHolder}
        <PageHeader header="Instructor Topics" subheader="" />
        <br />
        <TopicLayoutContainer>
          <TopicLayoutControl>
            <Button
              type="text"
              size="large"
              icon={<LeftOutlined rev={undefined} />}
              style={{ marginRight: "auto" }}
              onClick={() => {
                navigate(getDashboardPath());
              }}
            >
              Dashboard
            </Button>
            {canCreate && (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined rev={undefined} />}
                onClick={() => setIsCreate(true)}
              >
                Create
              </Button>
            )}
            <ModalTopicCreate
              open={isCreate}
              onSubmit={(values) => {
                console.log(values);
                const { ...otherValues } = values;
                createTopic({
                  ...otherValues,
                  type: "",
                  authors: "",
                })
                  .unwrap()
                  .then(() => {
                    setIsCreate(false);
                    messageApi.success("Topic created");
                  })
                  .catch(() => {
                    messageApi.error("Failed to create topic");
                  });
              }}
              onCancel={() => setIsCreate(false)}
              isLoading={isCreating}
            />
          </TopicLayoutControl>

          <TopicListContainer>
            {topics.length > 0 ? (
              topics.map((topic) => <TopicTile key={topic.id} topic={topic} />)
            ) : (
              <Empty
                style={{ margin: "40px 0" }}
                description={<span>No available topics. Please, create new topic.</span>}
              />
            )}
          </TopicListContainer>
        </TopicLayoutContainer>
      </>
    );
  }

  if (isLoading) {
    return (
      <div>
        <h1>Topics</h1>
        <p>Loading...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <h1>Topics</h1>
        <p>Error: {JSON.stringify(error)}</p>
      </div>
    );
  }
  return (
    <div>
      <h1>Topics</h1>
      <p>Unexpected error</p>
    </div>
  );
}
