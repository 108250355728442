export function getIntroPage(topicId: number | string, pageId: number | string) {
  return `/stud/topic/${topicId}/intro/${pageId}`;
}

export function getExamplePage(topicId: number | string, exampleId: number | string) {
  return `/stud/topic/${topicId}/example/${exampleId}`;
}

export function getProblemPage(topicId: number | string, problemId: number | string) {
  return `/stud/topic/${topicId}/problem/${problemId}`;
}
