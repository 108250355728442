import { ColumnsType } from "antd/lib/table";
import { Table } from "antd";
import React from "react";
import { StepScoreReport } from "../../../../features/api/professor/grades";
import StepStatusTag from "./StepStatusTag";

export interface StepsTableProps {
  data: StepScoreReport[];
}

type StepScoreRow = StepScoreReport & {
  index: number;
};

export default function StepsTable({ data }: StepsTableProps) {
  const rows: StepScoreRow[] = data.map((step, index) => ({
    ...step,
    index,
  }));

  return (
    <Table
      columns={stepColumns}
      dataSource={rows}
      rowKey={(record) => record.stepId}
      pagination={false}
      size="small"
      bordered
      tableLayout="fixed"
      style={{
        maxWidth: 600,
      }}
    />
  );
}

const stepColumns: ColumnsType<StepScoreRow> = [
  {
    title: "Step",
    key: "stepTitle",
    render: (_, record) => `Step ${record.index + 1}`,
    rowScope: "row",
    // width: 50,
  },
  {
    title: "Attempts",
    key: "attempts",
    render: (_, record) => record.numAttempts.toLocaleString("en-US"),
    // width: 120,
  },
  {
    title: "Status",
    key: "status",
    render: (_, record) => <StepStatusTag {...record} />,
    // width: 120,
  },
  {
    title: "Points",
    key: "points",
    render: (_, record) => record.points.toLocaleString("en-US"),
    // width: 120,
  },
];
