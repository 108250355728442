import ReactQuill from "react-quill";
import SecondaryHeader from "./../SecondaryHeader";
import { QuestionContainer, QuestionBody } from "./style";
import "react-quill/dist/quill.bubble.css";

type Props = {
  children?: string;
};

export default function QuestionHeader({ children }: Props) {
  return (
    <QuestionContainer>
      <SecondaryHeader highlighted>Question</SecondaryHeader>
      <QuestionBody>
        <ReactQuill
          // style={{ fontSize: "22px"}}
          value={children}
          theme={"bubble"}
          readOnly={true}
        />
        {/* {children} */}
      </QuestionBody>
    </QuestionContainer>
  );
}

export function QuestionHeader2({ children }: Props) {
  return (
    <QuestionContainer>
      <SecondaryHeader highlighted>Question</SecondaryHeader>
      <QuestionBody>
        <ReactQuill
          // style={{ fontSize: "22px"}}
          value={children}
          theme={"bubble"}
          readOnly={true}
        />
      </QuestionBody>
    </QuestionContainer>
  );
}


export function ExplanationHeader({ children }: Props) {
  return (
        <ReactQuill
          style={{ fontSize: "initial" }}
          value={children}
          theme={"bubble"}
          readOnly={true}
        />
  );
}