import ReactQuill from "react-quill";
import { NextButton, PreviusButton } from "../../../../components/Button/Button";
import SectionHeader from "../../../../components/SectionHeader";
import { IntroPage } from "../../../../features/api/professor/types";
import { TextBox } from "../../../Student/Assignment/Introduction/style";
import { EditButtonsContainer } from "../components/EditModeStyle";
import { useTopicContext } from "../Context";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";

export default function IntroDisplay({
  data,
  onEdit,
  onDelete,
  isDeleting,
}: {
  data: IntroPage;
  onEdit: () => void;
  onDelete: () => void;
  isDeleting: boolean;
}) {
  const { canEdit, canDelete } = useTopicContext();
  return (
    <div>
      <SectionHeader>{data.title}</SectionHeader>
      <TextBox>
        <ReactQuill value={data.content} theme={"bubble"} readOnly={true} />
      </TextBox>
      <EditButtonsContainer>
        {canEdit && <NextButton onClick={onEdit}>Edit</NextButton>}
        {canEdit && (
          <PreviusButton onClick={onDelete} disabled={isDeleting}>
            {isDeleting ? "Deleting..." : "Delete"}
          </PreviusButton>
        )}
      </EditButtonsContainer>
    </div>
  );
}

export function IntroDisplay2({
  data,
  onEdit,
  onDelete,
  isDeleting,
}: {
  data: IntroPage;
  onEdit: () => void;
  onDelete: () => void;
  isDeleting: boolean;
}) {
  const { canEdit, canDelete } = useTopicContext();
  return (
    <div>
      <SectionHeader>{data.title}</SectionHeader>
      <TextBox>
        <ReactQuill value={data.content} theme={"bubble"} readOnly={true} />
      </TextBox>
      <EditButtonsContainer>
        {canEdit && (
          <Button type="primary" icon={<EditFilled rev={undefined} />} onClick={onEdit}>
            Edit
          </Button>
        )}
        {canDelete && (
          <Popconfirm
            title="Delete introduction page"
            description="Are you sure to delete this page?"
            okText="Yes"
            okButtonProps={{ loading: isDeleting }}
            cancelText="No"
            onConfirm={onDelete}
          >
            <Button
              type="default"
              style={{ marginLeft: "auto" }}
              icon={<DeleteOutlined rev={undefined} />}
              danger
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </EditButtonsContainer>
    </div>
  );
}
