import styled from "styled-components";

type Props = {
  highlighted?: boolean;
};

export default styled.h4<Props>`
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0;
  color: ${(props) => (props.highlighted ? "#2e6ea5" : "#555555")};
`;
