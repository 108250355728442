import { useNavigate, useParams } from "react-router-dom";
import { NextButton, PreviusButton } from "../../../../components/Button/Button";
import QuestionHeader from "../../../../components/QuestionHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import SectionHeader from "../../../../components/SectionHeader";
import { useGetStudentAssignmentQuery } from "../../../../features/api/student/assignment";
import { getExamplePage, getIntroPage, getProblemPage } from "../NavButtons";
import ExampleSteps from "./ExampleSteps";
import { ButtonsContainer, Container } from "./style";

export default function Example({ assignmentId }: { assignmentId: number }) {
  const params = useParams();
  const exampleNumber = Number(params.exampleNo);

  const {
    data: assignment,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentAssignmentQuery(assignmentId);

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Container>
        <SectionHeader style={{ marginTop: "70px" }}>Loading...</SectionHeader>
        <QuestionHeader>Loading...</QuestionHeader>
        <SecondaryHeader style={{ marginTop: "40px" }} highlighted>
          Solution
        </SecondaryHeader>
        Loading...
      </Container>
    );
  }

  if (isError) {
    return (
      <Container>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </Container>
    );
  }

  if (isSuccess) {
    const { examples, pages } = assignment;
    const exampleIndex = exampleNumber - 1;
    if (exampleIndex >= 0 && exampleIndex < examples.length) {
      const example = examples[exampleIndex];
      return (
        <Container style={{ display: "block" }}>
          <SectionHeader style={{ marginTop: "70px" }}>{example.title}</SectionHeader>
          <QuestionHeader>{example.question}</QuestionHeader>
          <SecondaryHeader style={{ marginTop: "40px" }} highlighted>
            Solution
          </SecondaryHeader>
          <ExampleSteps steps={example.steps} />
          <ButtonsContainer>
            <PreviusButton
              onClick={() => {
                const prev = exampleNumber - 1;
                if (prev > 0) navigate(getExamplePage(assignmentId, prev));
                else {
                  navigate(getIntroPage(assignmentId, pages.length));
                }
              }}
            >
              Back
            </PreviusButton>
            <NextButton
              onClick={() => {
                if (exampleNumber < examples.length) {
                  navigate(getExamplePage(assignmentId, exampleNumber + 1));
                } else {
                  navigate(getProblemPage(assignmentId, 1));
                }
              }}
            >
              Next
            </NextButton>
          </ButtonsContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          <h1>Error</h1>
          <p>Example problem not found</p>
        </Container>
      );
    }
  }

  return (
    <Container>
      <h1>Error</h1>
      <p>Unknown error!</p>
    </Container>
  );
}
