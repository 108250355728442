// KaTeX dependency
import katex from "katex";

import "katex/dist/katex.css";

// Quill dependency
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

// MathQuill dependency
import "mathquill/build/mathquill.css";
import "mathquill/build/mathquill.js";
import "../../../Test/jquery";

// mathquill4quill include
import mathquill4quill from "mathquill4quill";
import "mathquill4quill/mathquill4quill.css";

// demo page
import { forwardRef, useEffect } from "react";
import CustomToolbar from "../../../Test/CustomToolbar";

window.katex = katex;
const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["x^2", "^2"],
  ["x^n", "^{}"],
  // ["\\sum^{s}_{x}{d}", "\\sum"],
  // ["\\prod^{s}_{x}{d}", "\\prod"],
  // ["\\coprod^{s}_{x}{d}", "\\coprod"],
  // ["\\int^{s}_{x}{d}", "\\int"],
  // ["\\binom{n}{k}", "\\binom"]
];

function Editor({ initialValue }, ref) {
  useEffect(() => {
    if (ref.current) {
      const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
      enableMathQuillFormulaAuthoring(ref.current.editor, {
        displayHistory: true,
        historyCacheKey: "__my_app_math_history_cachekey_",
        historySize: 10,
        operators: CUSTOM_OPERATORS,
      });
    } else {
      console.error("Quill ref not set!");
    }
  }, [initialValue, ref]);


  return (
    <>
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <CustomToolbar />
        <ReactQuill
          ref={ref}
          modules={{
            formula: true,
            toolbar: {
              container: "#toolbar",
            },
          }}
          defaultValue={initialValue}
          theme="snow"
        />
      </div>
    </>
  );
}

export default forwardRef(Editor);
