import { MessageDisplay, TopicState } from "./types";

export const DEFAULT_EXPRESSION = "";
export const DEFAULT_MESSAGE_DISPLAY = "NONE" as MessageDisplay;

/**
 * Ensures that topic with specified id is initilized in state
 * @param state state of the topic slice
 * @param topicId id of topic
 */
export function ensureTopicDefinition(state: TopicState, topicId: string) {
  if (!state[topicId]) {
    state[topicId] = {
      id: topicId,
      problems: {},
    };
  }
}

/**
 * Ensures that problem with specified id is initialized in state
 * As a sideffect also ensures that topic is initialized
 * @param state state of the topic slice
 * @param topicId id of topic
 * @param problemId id of problem
 */
export function ensureProblemDefinition(
  state: TopicState,
  topicId: string,
  problemId: string
) {
  ensureTopicDefinition(state, topicId);
  if (!state[topicId].problems[problemId]) {
    state[topicId].problems[problemId] = {
      id: problemId,
    };
  }
}
