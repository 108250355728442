import { StaticMathField } from "react-mathquill";
import { StyleSet } from "../../features/topic/types";
import CheckMark from "./CheckMark";
import { StepBody, StepContainer, StepNumber, StepExpression } from "./style";
import MathField from "../Mathlive/MathField";

type Props = {
  id: number;
  expression: string;
  styling?: StyleSet;
};

export default function Step({ id, expression, styling }: Props) {
  return (
    <StepContainer styling={styling}>
      <StepNumber>Step {id + 1}</StepNumber>
      <StepBody>
        <StepExpression>
          {/* <StaticMathField>{expression}</StaticMathField>*/}
          <MathField defaultValue={expression} options={{ readOnly: true }} />
        </StepExpression>
        {styling === "primary" && <CheckMark />}
      </StepBody>
    </StepContainer>
  );
}
