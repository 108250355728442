import { NavLink, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import {
  useDeleteAssignmentMutation,
  useDeleteAssignmentSubmissionsMutation,
  useGetInstructorAssignmentQuery,
} from "../../../features/api/professor/assignment";
import { useGetCourseQuery } from "../../../features/api/professor/course";
import { useGetTopicQuery } from "../../../features/api/professor/topic";
import { getCoursePath, getDashboardPath, getTopicPath } from "../nav";
import {
  BodyContent,
  BottomSection,
  ButtonRow,
  CourseContainer,
  CourseCount,
  CourseName,
  CoursePanel,
  CoursePanelContent,
  CoursePanelMark,
  CourseSemester,
  CourseTopicPanel,
  DateFormat,
  DateRow,
  DateSideBox as DateSideBox,
  DatesPanel,
  DescriptionText,
  LeftPanel,
  LeftSubArea,
  RightPanel,
  RightSubArea,
  SectionTitle,
  Subtitle,
  TopicContainer,
  TopicCount,
  TopicName,
  TopicPanel,
  TopicPanelContent,
  TopSection,
} from "./style";
import DateMessages from "../../../components/utils/Dates/DateMenssages";
import { StudentAssignmentIcon, StudentIcon } from "../../../components/Course/Instructor/style";
import { App, Button, Popconfirm, Typography } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import React, { useState } from "react";
import AssignmentEdit from "./AssignmentEdit";
import AssignmentGrades from "./AssignmentGrades";

export default function AssignmentPage() {
  const navigate = useNavigate();
  const params = useParams();
  const assignmentId = params.assignmentId || "";

  const { message, modal } = App.useApp();

  const {
    data: assignment,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetInstructorAssignmentQuery(Number(assignmentId));

  const [deleteAssignment, { isLoading: isDeleting }] = useDeleteAssignmentMutation();
  const [deleteAssignmentSubmissions, { isLoading: isResetting }] =
    useDeleteAssignmentSubmissionsMutation();

  const [isEdit, setIsEdit] = useState(false);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isSuccess) {
    const handleDelete = () => {
      return deleteAssignment(assignment.id)
        .unwrap()
        .then(() => {
          message.success("Assignment deleted");
          navigate(
            assignment.course_id
              ? getCoursePath(assignment.course_id.toString())
              : getDashboardPath(),
          );
        })
        .catch((error) => {
          if (error.status === 409) {
            modal.warning({
              title: "Cannot delete assignment",
              content: (
                <>
                  <Typography.Text>{error.data.message}</Typography.Text>
                </>
              ),
            });
          } else if (error.status === 404) {
            modal.error({
              title: "Cannot delete assignment",
              content: (
                <>
                  <Typography.Text>
                    Assignment not found. It is probably already deleted. Refresh this page.
                  </Typography.Text>
                </>
              ),
            });
          } else {
            message.error("Failed to delete assignment");
          }
        });
    };

    const handleReset = () => {
      return deleteAssignmentSubmissions(assignment.id)
        .unwrap()
        .then(() => {
          message.success("Progress reset");
        })
        .catch(() => {
          message.error("Failed to reset progress");
        });
    };

    return (
      <>
        <PageHeader header={assignment.title} subheader={""}></PageHeader>
        <BodyContent>
          <TopSection>
            <LeftPanel>
              <Subtitle>Description</Subtitle>
              <DescriptionText>{assignment.description}</DescriptionText>
              <DatesPanel>
                <DateRow>
                  <Subtitle>Start date:&nbsp;</Subtitle>
                  <DateFormat>
                    {new Date(assignment.start_date).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </DateFormat>
                </DateRow>
                <DateRow>
                  <Subtitle>Due date:&nbsp;&nbsp;&nbsp;</Subtitle>
                  <DateFormat>
                    {new Date(assignment.end_date).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </DateFormat>
                </DateRow>
              </DatesPanel>
              <CourseTopicPanel>
                {assignment.course_id && (
                  <LeftSubArea>
                    <Subtitle>Course</Subtitle>
                    <Course id={assignment.course_id} />
                  </LeftSubArea>
                )}
                <RightSubArea>
                  <Subtitle>Topic</Subtitle>
                  <Topic id={assignment.topic_id} />
                </RightSubArea>
              </CourseTopicPanel>
            </LeftPanel>
            <RightPanel>
              <DateSideBox>
                <DateMessages start_date={assignment.start_date} end_date={assignment.end_date} />
                {new Date(assignment.end_date).toLocaleString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </DateSideBox>
            </RightPanel>
          </TopSection>
          <ButtonRow>
            <Button
              type="primary"
              icon={<EditFilled rev={undefined} />}
              onClick={() => setIsEdit(true)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Reset progress"
              description="All students progress will be reset"
              okText="Yes"
              okButtonProps={{ loading: isResetting }}
              cancelText="No"
              onConfirm={handleReset}
              style={{ marginLeft: "auto" }}
            >
              <Button type="default" style={{ marginLeft: "auto" }}>
                Reset
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Delete assignment"
              description="Are you sure to delete this assignment?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button type="default" icon={<DeleteOutlined rev={undefined} />} danger>
                Delete
              </Button>
            </Popconfirm>
            <AssignmentEdit
              assignmentId={isEdit ? assignment.id : null}
              onSubmit={() => setIsEdit(false)}
              onCancel={() => setIsEdit(false)}
            />
          </ButtonRow>

          <BottomSection>
            <SectionTitle>Grades</SectionTitle>
            <AssignmentGrades assignmentId={assignment.id} />
          </BottomSection>
        </BodyContent>
      </>
    );
  }

  if (isError) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }

  return <p>Unexpected Error</p>;
}

function Topic({ id: topicId }: { id: number }) {
  const { data: topic, isSuccess, isLoading, isError, error } = useGetTopicQuery(topicId);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isSuccess) {
    return (
      <>
        <NavLink to={getTopicPath(topicId)} style={{ textDecoration: "none", color: "#2E6EA5" }}>
          <TopicContainer>
            <TopicPanel>
              <TopicPanelContent>
                <TopicName>{topic.title}</TopicName>
                <TopicCount>
                  <StudentAssignmentIcon />
                  <span style={{ marginLeft: "4px" }}>
                    {topic.activeAssignmentCount + topic.scheduledAssignmentCount}
                  </span>
                </TopicCount>
              </TopicPanelContent>
            </TopicPanel>
          </TopicContainer>
        </NavLink>
      </>
    );
  }

  if (isError) {
    return <p>Error getting topic. {JSON.stringify(error)}</p>;
  }

  return <p>Unexpected Error</p>;
}

function Course({ id: courseId }: { id: number }) {
  const { data: course, isSuccess, isLoading, isError } = useGetCourseQuery(courseId);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isSuccess) {
    const { id, template, section, semester, year } = course;
    return (
      <NavLink to={getCoursePath(id)} style={{ textDecoration: "none", color: "black" }}>
        <CourseContainer>
          <CoursePanel courseColor={course.color || undefined}>
            <CoursePanelMark courseColor={course.color || undefined} />
            <CoursePanelContent>
              <div>
                <CourseName>
                  {template.name} {section}
                </CourseName>
                <CourseSemester>
                  {semester} {year}
                </CourseSemester>
              </div>
              <CourseCount>
                <StudentIcon />
                <span style={{ marginLeft: "4px" }}>{course.studentCount}</span>
              </CourseCount>
            </CoursePanelContent>
          </CoursePanel>
        </CourseContainer>
      </NavLink>
    );
  }

  if (isError) {
    return <p>Error getting course.</p>;
  }

  return <p>Unexpected Error</p>;
}
