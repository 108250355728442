import { Route, Routes, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { useDeleteTopicMutation, useGetTopicQuery, useUpdateTopicMutation } from "../../../features/api/professor/topic";
import { useTopicContext } from "./Context";
import ExampleManager from "./Example/Manager";
import IntroManager from "./Introduction/Manager";
import ProblemManager from "./Problem/Manager";
import TopicEdit from "./TopicEdit";
import TopicNav from "./TopicNav";
import { BodyContainer, Container, ContentContainer } from "./style";
import { Button, Popconfirm, message } from "antd";
import { DeleteOutlined, EditFilled, PlusOutlined } from "@ant-design/icons";
import { ButtonsContainer, ButtonsContainerTopic } from "../style";
import { ModalTopicEdit } from "./ModalTopicEdit"

export default function Topic() {
  const params = useParams();
  const topicId = params.topicId || "";
  const navigate = useNavigate();
  const { canEdit, canDelete } = useTopicContext();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    data: topic,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetTopicQuery(topicId);

  const [deleteTopic, { isLoading: isDeleting }] = useDeleteTopicMutation();
  const [updateTopic, { isLoading: isUpdating }] = useUpdateTopicMutation();
  const [isEdit, setIsEdit] = useState(false);

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <PageHeader header={"Loading..."} subheader={"Loading..."} />
        <BodyContainer>
          <TopicNav topicId={topicId} />
          <ContentContainer>Loading...</ContentContainer>
        </BodyContainer>
      </Container>
    );
  }

  if (isSuccess) {
    const handleDelete = () => {
      deleteTopic(topic.id).then(() => navigate("/prof/topic"));
    };
    return (
      <>
        <Container>
          <PageHeader
            header={topic.title}
            subheader={topic.published ? "Published" : "Not Published"}
          >
            <ButtonsContainerTopic>
              {canEdit && (
                <Button
                  type="primary"
                  icon={<EditFilled rev={undefined} />}
                  onClick={() => setIsEdit(true)}
                >
                  Edit
                </Button>
              )}
              {canDelete && (
                <Popconfirm
                  title="Delete Topic"
                  description="Are you sure to delete this topic?"
                  okText="Yes"
                  okButtonProps={{ loading: isDeleting }}
                  cancelText="No"
                  onConfirm={handleDelete}
                >
                  <Button style={{ marginTop: "1em", marginBottom: "-1em" }}
                    type="default"
                    icon={<DeleteOutlined rev={undefined} />}
                    danger
                  >
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </ButtonsContainerTopic>
          </PageHeader>
          <BodyContainer>
            <TopicNav topicId={topicId} />
            <ContentContainer>
              <Routes>
                {canEdit && <Route path="/edit" element={<TopicEdit />} />}
                <Route
                  path="intro/*"
                  element={<IntroManager topicId={topic.id} />}
                />
                <Route
                  path="example/*"
                  element={<ExampleManager topicId={topic.id} />}
                />
                <Route
                  path="problem/*"
                  element={<ProblemManager topicId={topic.id} />}
                />
              </Routes>
            </ContentContainer>
          </BodyContainer>
        </Container>
        <ModalTopicEdit
          open={isEdit}
          initialValues={topic}
          onSubmit={(values) => {
            updateTopic({
              ...values,
              id: topic.id,
            })
              .unwrap()
              .then(() => {
                setIsEdit(false);
                messageApi.success("Topic created");
              })
              .catch(() => {
                messageApi.error("Failed to create topic");
              });
          }}
          onCancel={() => setIsEdit(false)}
          isLoading={isUpdating}
        />
      </>
    );
  }

  return (
    <>
      <h1>Error</h1>
      <h2>Unknown error!</h2>
    </>
  );
}
