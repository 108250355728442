import styled, { keyframes } from "styled-components";
import { Button } from "../../components/Button";
import { Field, Form } from "formik";
import { FaLock, FaUser } from "react-icons/fa";

export const BackgroundPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3ca2e0;
  color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  width: 100%;
`;
export const LogoImg = styled.img`
  width: 160px;
  margin: 40px 0;
`;

export const LoginWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  //padding: 40px;
  border-radius: 15px;
  text-align: center;
  min-height: 1px;
  background-color: #fff;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  padding-top: 2%;
`;

export const ForgotPassForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  height: 100px;
  border-radius: 14px;
  padding: 50px;
  text-align: center;
`;

export const LoginHeading = styled.span`
  display: block;
  font-size: 28px;
  font-weight: 700;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
  color: black;
`;

export const BlockContainer = styled.div`
  display: flex;
  margin: 0 0 25px 0;
  position: relative;
  top: 5px;
  font-size: 17px;
  color: #c8c8c8;
  transition: all 0.5s ease 0s;
`;

export const LoginInput = styled(Field)`
  background: #f0f0f0;
  border: none;
  border-radius: 20px;
  box-shadow: none;
  padding: 0 20px 0 45px;
  height: 40px;
  transition: all 0.3s ease 0s;
`;

export const EditInputSmall = styled(Field)`
  text-align: center;
  box-sizing: border-box;
  height: 30px;
  width: 200px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
`;

export const LoginButton = styled(Button)`
  border: none;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 14px;
  border-radius: 30px;
`;

export const UserIcon = styled(FaUser)`
  position: absolute;
  top: 12px;
  left: 18px;
  font-size: 17px;
  color: #c8c8c8;
  transition: all 0.5s ease 0s;
`;

export const LockIcons = styled(FaLock)`
  position: absolute;
  top: 12px;
  left: 18px;
  font-size: 17px;
`;

const loadingAniamtion = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const ControlRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

export const LoadingRing = styled.span`
  margin: auto;
  width: 22px;
  height: 22px;
  border: 2px solid #363636;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${loadingAniamtion} 1s linear infinite;
`;
export const ToolTipError = styled.span`
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  visibility: hidden;
`;
export const ErrorMessagess = styled.div`
  color: red;
  /* font-size:10px ;
    position: absolute; */
  float: right;
`;
export const ToolTipText = styled.p`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
`;

// export const InputField = styled.input`
//   text-align: center;
//   box-sizing: border-box;
//   height: 30px;
//   width: 250px;
//   background: #ffffff;
//   border: 2px solid #c4c4c4;
//   border-radius: 6px;
// `;

export const EditLabel = styled.label`
  padding: 28px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 21px;
  margin: auto;
`;

export const FPButton = styled.button`
  border-radius: 8px;
  background-color: #2e6ea5;
  color: #ffffff;
  padding: 8px;
  border: none;
  font-size: 18px;
  overflow: visible;
  text-transform: none;
  &:hover,
  &:focus {
    background: #00549e;
  }
  &:disabled {
    background: #00549e65;
  }

  &:focus {
    outline: 2px solid #70a9db;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const StyleCSS = styled.style`
  .login-form {
    max-width: 300px;
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }
`;

export const LoginWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  border-radius: 15px;
  text-align: center;
  background-color: #fff;
  padding: 3em;
`;
