import { Form, Modal } from "antd";
import dayjs from "dayjs";
import React from "react";
import { CourseForm, OutputFormData } from "./CourseForm";

export interface EditFormData {
  semester: string;
  section: string;
  year: string;
  color: string;
  instructorId: number | null;
}

export interface ModalCourseEditProps {
  open: boolean;
  initialValues?: EditFormData;
  onSubmit: (values: EditFormData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalCourseEdit({
  open,
  initialValues,
  onCancel,
  onSubmit,
  isLoading,
}: ModalCourseEditProps) {
  const [form] = Form.useForm<OutputFormData>();

  return (
    <Modal
      open={open}
      title="Edit course"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmit({
              semester: values.semester.trim(),
              section: values.section.trim(),
              year: values.year.year().toString(),
              color: typeof values.color === "string" ? values.color : values.color.toHex(),
              instructorId: values.instructorId ? values.instructorId : null,
            });
          })
          .catch(() => {
            /* validation failed */
          });
      }}
      confirmLoading={isLoading}
    >
      <CourseForm
        form={form}
        initialValues={
          initialValues && {
            semester: initialValues.semester,
            section: initialValues.section,
            year: dayjs(initialValues.year),
            color: initialValues.color,
            instructorId: initialValues.instructorId,
          }
        }
      />
    </Modal>
  );
}
