import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import { useGetInstructorAssignmentsByCourseQuery } from "../../../features/api/professor/assignment";
import {
  useDeleteCourseMutation,
  useGetCourseQuery,
  useUpdateCourseMutation,
} from "../../../features/api/professor/course";
import {
  useCreateTopicMutation,
  useGetTopicsByCourseQuery,
} from "../../../features/api/professor/topic";
import { getAllCoursePath } from "../nav";
import { CoursePanelsLayout } from "../style";
import {
  ColorContainer,
  CourseControlContainer,
  CourseInfoContainer,
  CourseTabPanel,
  InstructorContainer,
  PanelBody,
  PanelHeader,
  Tab,
} from "./style";
import React, { Fragment, useState } from "react";
import CourseGradeReport from "./CourseGrades";
import { Course as CourseData } from "../../../features/api/professor/types";
import {
  App,
  Avatar,
  Button,
  Empty,
  message,
  Popconfirm,
  Space,
  Spin,
  theme,
  Typography,
} from "antd";
// import { TiUserOutline } from "react-icons/all";
import { DeleteOutlined, EditFilled, PlusOutlined, UserOutlined } from "@ant-design/icons";

import { ModalCourseEdit } from "./CourseEdit";
import CourseStudentsList from "./CourseStudentList";
import { useCourseContext } from "./CourseContext";
import AssignmentTile from "../../../components/Assignment/Instructor/InstructorAssignmentTile";
import { ModalAssignmentCreate } from "../Assignment/AssignmentCreate";
import { ModalTopicCreate } from "../Topic/TopicCreate";
import { TopicListContainer } from "../Topic/style";
import TopicTile from "../../../components/Topic/InstructorTopicTile";
import { UserRole } from "../../../features/api/types";
import { useGetMeQuery } from "../../../features/api/user-api";

export default function CoursePage() {
  const navigate = useNavigate();
  const params = useParams();
  const { token } = theme.useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const { canEdit, canDelete } = useCourseContext();
  const courseId = params.courseId || "";

  const { data: course, isSuccess, isLoading, isError, error } = useGetCourseQuery(courseId);
  const [deleteCourse, { isLoading: isDeleting }] = useDeleteCourseMutation();
  const [updateCourse, { isLoading: isUpdating }] = useUpdateCourseMutation();

  const [isEdit, setIsEdit] = useState(false);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isSuccess) {
    const { id, template, semester, year, section, color, instructor } = course;

    const subheader = `${template.name} | ${semester.toUpperCase()} ${year} | Section ${section}`;
    const handleDelete = () => {
      deleteCourse(id)
        .unwrap()
        .then()
        .catch()
        .finally(() => {
          navigate(getAllCoursePath());
        });
    };

    return (
      <div>
        {contextHolder}
        <PageHeader header="Course Manager" subheader={subheader} />
        <CourseControlContainer>
          {canEdit && (
            <Button
              type="primary"
              icon={<EditFilled rev={undefined} />}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </Button>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete course"
              description="Are you sure to delete this course?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button
                type="default"
                style={{ marginLeft: "auto" }}
                icon={<DeleteOutlined rev={undefined} />}
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          )}
          <ModalCourseEdit
            open={isEdit}
            initialValues={{
              section,
              semester: semester.toLocaleLowerCase(),
              year,
              color: color || token.colorPrimary,
              instructorId: instructor ? instructor.user ? instructor.user.id : null : null,
            }}
            onSubmit={(values) => {
              updateCourse({ id, ...values })
                .unwrap()
                .then(() => {
                  setIsEdit(false);
                  messageApi.success("Changes saved");
                })
                .catch(() => {
                  messageApi.error("Failed to save changes");
                });
            }}
            onCancel={() => {
              setIsEdit(false);
            }}
            isLoading={isUpdating}
          />
        </CourseControlContainer>
        <CourseInfoContainer>
          <Instructor data={instructor} />
          <Color value={color} />
        </CourseInfoContainer>

        <CourseTab courseId={id} />
      </div>
    );
  }

  if (isError) {
    return <p>This is the Error: {JSON.stringify(error)}</p>;
  }

  return <p>Unexpected Error</p>;
}

function Instructor({ data }: { data: CourseData["instructor"] }) {
  return (
    <InstructorContainer>
      <Typography.Title level={4}>Instructor</Typography.Title>
      <div className="instructor-info">
        <Space>
          <Avatar size={"large"} icon={<UserOutlined rev={undefined} />} />
          {data ? (
            <div className="instructor-name">
              <Typography.Text
                strong
              >{`${data.profile?.firstname} ${data.profile?.lastname}`}</Typography.Text>
              <Typography.Text>{data.user?.email}</Typography.Text>
            </div>
          ) : (
            <div className="instructor-name">
              <Typography.Text italic>Not assigned</Typography.Text>
            </div>
          )}
        </Space>
      </div>
    </InstructorContainer>
  );
}

function Color({ value }: { value: string | null }) {
  const { token } = theme.useToken();
  const color = value ? `#${value}` : token.colorPrimary;
  return (
    <ColorContainer>
      <Typography.Title level={4}>Color</Typography.Title>
      <div className="color-info">
        <Space>
          <div
            style={{
              width: token.sizeXL,
              height: token.sizeXL,
              borderRadius: token.borderRadius,
              backgroundColor: color,
            }}
          />
          {/* <Typography.Text>{color.toUpperCase()}</Typography.Text> */}
        </Space>
      </div>
    </ColorContainer>
  );
}

function Topics({ courseId }: { courseId: number }) {
  const { message } = App.useApp();

  const { data: me } = useGetMeQuery();
  const { data, isLoading, isFetching, isSuccess, isError } = useGetTopicsByCourseQuery(courseId);
  const topics = data?.filter((t) => t.published) || [];

  const [createTopic, { isLoading: isCreating }] = useCreateTopicMutation();
  const [isCreate, setIsCreate] = useState(false);
  const canCreate = (["ROLE_ADMIN", "ROLE_SUPER_PROFESSOR"] as UserRole[]).some((role) =>
    me?.user.roles.includes(role),
  );

  return (
    <>
      <div>
        {canCreate && (
          <Button
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
            onClick={() => setIsCreate(true)}
          >
            Create Topic
          </Button>
        )}
        <ModalTopicCreate
          open={isCreate}
          onSubmit={(values) => {
            const { ...otherValues } = values;
            createTopic({
              ...otherValues,
              type: "",
              authors: "",
            })
              .unwrap()
              .then(() => {
                setIsCreate(false);
                message.success("Topic created");
              })
              .catch(() => {
                message.error("Failed to create topic");
              });
          }}
          onCancel={() => setIsCreate(false)}
          isLoading={isCreating}
        />
      </div>
      <Spin spinning={isLoading}>
        {isSuccess && topics.length > 0 ? (
          <TopicListContainer>
            {topics.map((topic) => (
              <TopicTile key={topic.id} topic={topic} />
            ))}
          </TopicListContainer>
        ) : (
          <Empty
            style={{ margin: "40px 0" }}
            description={<span>No available topics. Please, create new topic.</span>}
          />
        )}
      </Spin>
    </>
  );
}

function Assignments({ courseId }: { courseId: number }) {
  const navigate = useNavigate();
  const { canCreateAssignment } = useCourseContext();

  const {
    data: assignments,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetInstructorAssignmentsByCourseQuery(courseId);

  const [isCreate, setIsCreate] = useState(false);

  return (
    <>
      {canCreateAssignment && (
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined rev={undefined} />}
            onClick={() => setIsCreate(true)}
          >
            Create Assignment
          </Button>
          <ModalAssignmentCreate
            open={isCreate}
            initialValues={{
              courseId: courseId,
            }}
            visibility={{ course: "hidden" }}
            onSubmit={() => setIsCreate(false)}
            onCancel={() => setIsCreate(false)}
          />
        </div>
      )}
      <Spin spinning={isLoading}>
        <CoursePanelsLayout>
          {isSuccess && assignments.length > 0 ? (
            assignments.map((assignment) => (
              <AssignmentTile data={assignment} key={assignment.id} />
            ))
          ) : (
            <Empty
              style={{ margin: "40px 0" }}
              description={<span>No available assignment. Please, create new assignment.</span>}
            />
          )}
        </CoursePanelsLayout>
      </Spin>
    </>
  );
}

type TabId = "STUDENT" | "CONTENT" | "ASSIGNMENT" | "GRADES";
type TabData = {
  id: TabId;
  label: string;
  content: JSX.Element;
};

function CourseTab({ courseId }: { courseId: number }) {
  const [activeTab, setActiveTab] = useState<TabId>("STUDENT");

  const tabs: TabData[] = [
    {
      id: "STUDENT",
      label: "STUDENTS",
      content: <CourseStudentsList courseId={courseId} />,
    },
    {
      id: "CONTENT",
      label: "CONTENT",
      content: <Topics courseId={courseId} />,
    },
    {
      id: "ASSIGNMENT",
      label: "ASSIGNMENTS",
      content: <Assignments courseId={courseId} />,
    },
    {
      id: "GRADES",
      label: "GRADES",
      content: <CourseGradeReport courseId={courseId} />,
    },
  ];

  const handleClick = (tabId: TabId) => {
    setActiveTab(tabId);
  };
  return (
    <CourseTabPanel>
      <PanelHeader>
        {tabs.map((tab) => (
          <Tab key={tab.id} active={activeTab === tab.id} onClick={() => handleClick(tab.id)}>
            {tab.label}
          </Tab>
        ))}
      </PanelHeader>
      <PanelBody>
        {tabs.map((tab) =>
          tab.id === activeTab ? <Fragment key={tab.id}>{tab.content}</Fragment> : null,
        )}
      </PanelBody>
    </CourseTabPanel>
  );
}
