import { Form } from "antd";
import { useUpdateProblemStepMutation } from "../../../../../features/api/professor/problem";
import { Step as ProblemStep } from "../../../../../features/api/professor/types";
import ProblemStepFrom from "../../components/ProblemStepForm";
import { OutputFormData, ProblemStepForm2 } from "../../components/ProblemStepForm2";
import { ModalProblemStepEdit } from "./ModalStepEdit";

export default function StepEdit({
  data: step,
  index,
  onDone,
  onCancel,
}: {
  data: ProblemStep;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [updateStep, { isLoading: isUpdating }] =
    useUpdateProblemStepMutation();

  return (
    <ProblemStepFrom
      index={index}
      initialData={{
        solution: step.solution,
        hint: step.hint || "",
      }}
      onCancel={onCancel}
      onSubmit={(data) => {
        updateStep({ id: step.id, ...data }).then(onDone);
      }}
      isLoading={isUpdating}
    />
  );
}

export function StepEdit2({
  data: step,
  index,
  onDone,
  onCancel,
}: {
  data: ProblemStep;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [updateStep, { isLoading: isUpdating }] =
    useUpdateProblemStepMutation();

  return (
    <ModalProblemStepEdit
      open={true}
      index={index}
      initialValues={{
        solution: step.solution,
        hint: step.hint || "",
      }}
      onCancel={onCancel}
      onSubmit={(data) => {
        updateStep({ id: step.id, ...data }).then(onDone);
      }}
      onDone={onDone}
      isLoading={isUpdating}
    />
  );
}

export function StepEdit3({
  data: step,
  index,
  onDone,
  onCancel,
}: {
  data: ProblemStep;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [updateStep, { isLoading: isUpdating }] =
    useUpdateProblemStepMutation();
    const [form] = Form.useForm<OutputFormData>();
    
  return (
    <ProblemStepForm2
    form={form}
      index={index}
      initialValues={{
        solution: step.solution,
        hint: step.hint || "",
      }}
      onCancel={onCancel}
      onSubmit={(data) => {
        updateStep({ id: step.id, ...data }).then(onDone);
      }}
    />
  );
}