import { StepStatus } from "../../../../features/api/types";
import { Tag } from "antd";

export interface StepStatusTagProps {
  numAttempts: number;
  status: StepStatus;
}

export default function StepStatusTag({ status, numAttempts }: StepStatusTagProps) {
  let tagStatus: TagStatus | undefined;
  if (status === "correct") {
    tagStatus = "correct";
  } else if (status === "incorrect") {
    tagStatus = "incorrect";
  } else if (status === "unanswered" && numAttempts > 0) {
    tagStatus = "inProgress";
  } else if (status === "unanswered" && numAttempts == 0) {
    tagStatus = "unanswered";
  }

  if (!tagStatus) {
    return null;
  }

  const settings = statusSettings[tagStatus];
  return <Tag color={settings.color || "default"}>{settings.text}</Tag>;
}

type TagStatus = "correct" | "incorrect" | "unanswered" | "inProgress";

export const statusSettings: Record<TagStatus, StatusSetting> = {
  correct: {
    color: "green",
    text: "correct",
  },
  incorrect: {
    color: "red",
    text: "incorrect",
  },
  unanswered: {
    text: "unanswered",
  },
  inProgress: {
    color: "gold",
    text: "in progress",
  },
};

export interface StatusSetting {
  color?: string;
  text: string;
}
