import styled from "styled-components";
import { ProblemStatus } from "../../../features/api/types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NavContainer = styled.nav`
  width: 300px;
  height: auto;
  border-right: 2px solid #d6d6d6;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
`;

export const ContentContainer = styled.div`
  flex: auto;
`;

const colorMap: Record<ProblemStatus, { color: string }> = {
  correct: {
    color: "#24DE58",
  },
  incorrect: {
    color: "#FF5353",
  },
  unanswered: {
    color: "#00000094",
  },
};

type ProblemNavProps = {
  problemStatus: ProblemStatus;
};

export const ProblemNavContent = styled.span<ProblemNavProps>`
  color: ${({ problemStatus }) => colorMap[problemStatus].color};
  & > span.progress {
    margin-left: 30px;
    font-weight: 300;
  }
`;

export const TopicLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopicLayoutControl = styled.div`
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 60px;
`;

export const TopicListContainer = styled.div`
  flex: auto;
  align-self: center;
  margin: 20px 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 30px;
`;