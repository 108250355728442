import { apiSlice } from "../api-slice";

interface MathTryResponse {
  result: boolean;
}

interface MathTryRequest {
  expressionA: string;
  expressionB: string;
}

export const mathApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    tryExpression: builder.query<MathTryResponse, MathTryRequest>({
      query: (data) => ({
        url: `instructor/topic/math/try`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useTryExpressionQuery } = mathApiSlice;
