import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetAllSlices } from "../util";
import { MessageDisplay, TopicState } from "./types";
import { ensureProblemDefinition } from "./util";

const initialState: TopicState = {};

const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {
    setProblemInput(
      state,
      action: PayloadAction<{
        topicId: string;
        problemId: string;
        expression: string;
      }>
    ) {
      const { topicId, problemId, expression } = action.payload;
      ensureProblemDefinition(state, topicId, problemId);
      state[topicId].problems[problemId].inputExpression = expression;
    },
    setProblemMessageDisplay(
      state,
      action: PayloadAction<{
        topicId: string;
        problemId: string;
        display: MessageDisplay;
      }>
    ) {
      const { topicId, problemId, display } = action.payload;
      ensureProblemDefinition(state, topicId, problemId);
      state[topicId].problems[problemId].messageDisplay = display;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllSlices, (state) => {
      state = initialState;
    });
  },
});

export const { setProblemInput, setProblemMessageDisplay } = topicSlice.actions;
export default topicSlice.reducer;
