export function getDashboardPath() {
  return "/dashboard";
}

export function getTopicPath(topicId: number | string) {
  return `/prof/topic/${topicId}`;
}

export function getProblemPath(problemId: number | string) {
  return `/prof/topic/problem/${problemId}`;
}

export function getExampleProblemPath(exampleId: number | string) {
  return `/prof/topic/example/${exampleId}`;
}

export function getIntroPagePath(pageId: number | string) {
  return `/prof/topic/page/${pageId}`;
}

export function getCreateIntroPagePath(topicId: number | string) {
  return `/prof/topic/page/new?topic_id=${topicId}`;
}

export function getCreateExamplePath(topicId: number | string) {
  return `/prof/topic/example/new?topic_id=${topicId}`;
}

export function getCreateProblemPath(topicId: number | string) {
  return `/prof/topic/problem/new?topic_id=${topicId}`;
}

export function getAllTopicsPath() {
  return "/prof/topic";
}
export function getCoursePath(courseId: number | string) {
  return `/prof/course/${courseId}`;
}

export function getCreateCoursePath() {
  return "/prof/course/new";
}

export function getEditCoursePath(courseId: number | string) {
  return `/prof/course/${courseId}/edit`;
}

export function getAllUsersPath() {
  return "/user";
}

export function getAllCourseTemplatePath() {
  return "/prof/course-template";
}

export function getCourseTemplatePath(templateId: number | string) {
  return `/prof/course-template/${templateId}`;
}

export function getCreateCourseTemplatePath() {
  return "/prof/course-template/new";
}

export function getEditCourseTemplatePath(templateId: number | string) {
  return `/prof/course-template/${templateId}/edit`;
}

export function getAllCoursePath() {
  return "/prof/course";
}

export function getAssignmentPath(assignmentId: number | string) {
  return `/prof/assignment/${assignmentId}`;
}

export function getEditAssignmentPath(assignmentId: number | string) {
  return `/prof/assignment/${assignmentId}/edit`;
}

export function getCreateAssignmentPath() {
  return "/prof/assignment/new";
}

export function getAllAssignmentPath() {
  return "/prof/assignment";
}
