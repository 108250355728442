import {
  useCreateTopicMutation,
  useGetTopicsSummaryQuery,
} from "../../../features/api/professor/topic";

import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { StyledSubHeader } from "../../../components/PageHeader/style";
import TopicTile from "../../../components/Topic/InstructorTopicTile";
import ErrorMessages from "../../../components/utils/ErrorMessages";
import { getAllCoursePath, getAllTopicsPath } from "../nav";
import { HWPanelContainer, SeeAllLink } from "../style";
import { TopicSessionContainer } from "./styles";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

export default function Topics() {
  const navigate = useNavigate();

  const { data: topics, isSuccess, isLoading, isError, error } = useGetTopicsSummaryQuery();

  const [isCreating, setCreateMode] = useState(false);
  const [createTopic, { isLoading: isCreatingLoading }] = useCreateTopicMutation();

  if (isError) {
    return (
      <>
        <PageHeader header="Error" subheader="" />
        <ErrorMessages error={error} />
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <PageHeader header="Instructor Dashboard" subheader="" />
        <p>Loading...</p>
      </>
    );
  }

  if (isSuccess) {
    return (
      <>
        <StyledSubHeader>Topics</StyledSubHeader>

        <TopicSessionContainer>
          {topics.length > 0 ? (
            <>
              <HWPanelContainer>
                {topics.slice(0, 3).map((topic) => (
                  <TopicTile key={topic.id} topic={topic} />
                ))}
              </HWPanelContainer>
              <Button
                type="text"
                size="large"
                style={{ marginLeft: "auto", marginRight: "5%" }}
                onClick={() => navigate(getAllTopicsPath())}
              >
                See all...
              </Button>
            </>
          ) : (
            <Empty style={{ margin: "40px 0" }}>
              <Button type="primary" ghost onClick={() => navigate(getAllTopicsPath())}>
                Manage Topics
              </Button>
            </Empty>
          )}
        </TopicSessionContainer>
      </>
    );
  }

  return <p>Unknown Error</p>;
}
