import { Form, message, Modal, Select } from "antd";
import React from "react";
import { TopicForm, OutputFormData, InputFormData } from "./TopicForm";
import { CreateFromData, ModalTopicCreateProps } from "./TopicCreate";

export interface ModalTopicEditProps {
  open: boolean;
  initialValues?: Partial<CreateFromData>;
  onSubmit: (values: CreateFromData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalTopicEdit({
  open,
  initialValues,
  onSubmit,
  onCancel,
  isLoading,
}: ModalTopicCreateProps) {
  const [topicForm] = Form.useForm<OutputFormData>();

  const { courseTemplateId: initialTopicTemplateId, ...initialTopicValues } = initialValues || {};

  const handleSubmit = async () => {
    try {
      const topicValues = await topicForm.validateFields();

      onSubmit({
        title: topicValues.title?.trim() || "",
        courseTemplateId: topicValues.courseTemplateId ? topicValues.courseTemplateId : 0,
        published: topicValues.published?.valueOf() || false,
      });
      topicForm.resetFields();
    } catch (info) {
      console.log("Validation Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="Edit Topic"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <TopicForm
        form={topicForm}
        initialValues={
          initialValues && {
            courseTemplateId: initialValues.courseTemplateId,
            title: initialValues.title,
            published: initialValues.published?.valueOf(),
          }
        }
      />
    </Modal>
  );
}
