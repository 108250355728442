import { App, Button, Dropdown, MenuProps, Table } from "antd";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import React, { useState } from "react";
import { Container, Controls, UsersContainer } from "./style";
import {
  ExclamationCircleFilled,
  LeftOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getDashboardPath } from "../nav";
import {
  useDeleteUserMutation,
  useGetMeQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../../features/api/user-api";
import { ColumnsType } from "antd/lib/table";
import { UserPublicWithProfile } from "../../../features/api/types";
import { getGeneralPermission, getTargetPermission } from "./permission";
import { ModalUserCreate } from "./UserCreate";
import UserEdit from "./UserEdit";
import { getColumn, UserColumns } from "../../../components/Table/columns";
import ChangePassword from "./ChangePassword";

export default function UsersList() {
  const navigate = useNavigate();
  const { message, modal } = App.useApp();

  const { data: me } = useGetMeQuery();
  const { data: students, isSuccess, isLoading, isFetching, isError } = useGetUsersQuery();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();

  const [isCreate, setIsCreate] = useState(false);
  const [editUser, setEditUser] = useState<number | null>(null);
  const [changePassUser, setChangePassUser] = useState<number | null>(null);

  const { canCreate } = getGeneralPermission(me);

  const handleEdit = (user: UserPublicWithProfile) => {
    setEditUser(user.user.id);
  };

  const handleChangePassword = (user: UserPublicWithProfile) => {
    setChangePassUser(user.user.id);
  };

  const handleActivate = (user: UserPublicWithProfile) => {
    const userId = user.user.id;
    const username = user.user.username;
    message.open({
      key: `activate-result-${userId}`,
      type: "loading",
      content: "Activating user...",
    });
    updateUser({
      id: userId,
      is_active: 1,
    })
      .unwrap()
      .then(() =>
        message.open({
          key: `activate-result-${userId}`,
          type: "success",
          content: `User ${username} activated`,
        }),
      )
      .catch(() =>
        message.open({
          key: `activate-result-${userId}`,
          type: "error",
          content: `Unable to activate user ${username}`,
        }),
      );
  };

  const handleDeactivate = (user: UserPublicWithProfile) => {
    const userId = user.user.id;
    const username = user.user.username;
    message.open({
      key: `deactivate-result-${userId}`,
      type: "loading",
      content: "Deactivating user...",
    });
    updateUser({
      id: userId,
      is_active: 0,
    })
      .unwrap()
      .then(() =>
        message.open({
          key: `deactivate-result-${userId}`,
          type: "success",
          content: `User ${username} deactivated`,
        }),
      )
      .catch(() =>
        message.open({
          key: `deactivate-result-${userId}`,
          type: "error",
          content: `Unable to deactivate user ${username}`,
        }),
      );
  };

  const handleDelete = (user: UserPublicWithProfile) => {
    const userId = user.user.id;
    const username = user.user.username;
    modal.confirm({
      title: "Are you sure to delete this user?",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: (
        <span>
          User <b>{username}</b> will be deleted. This action cannot be undone!
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        message.open({
          key: `delete-result-${userId}`,
          type: "loading",
          content: "Deleting user...",
        });
        deleteUser(userId)
          .unwrap()
          .then(() =>
            message.open({
              key: `delete-result-${userId}`,
              type: "success",
              content: `User ${username} deleted`,
            }),
          )
          .catch(() =>
            message.open({
              key: `delete-result-${userId}`,
              type: "error",
              content: `Unable to delete user ${username}`,
            }),
          );
      },
    });
  };

  const columns: ColumnsType<RowDataType> = [
    getColumn.isActive(),
    getColumn.institutionalId(),
    getColumn.email(),
    getColumn.firstName(),
    getColumn.lastName(),
    getColumn.roles(),
    {
      key: "actions",
      title: "Actions",
      render: (_, record) => (
        <UserActionsCell
          user={record}
          onEdit={handleEdit}
          onChangePassword={handleChangePassword}
          onActivate={handleActivate}
          onDeactivate={handleDeactivate}
          onDelete={handleDelete}
        />
      ),
    },
  ];

  let rows: RowDataType[] = [];
  try {
    rows = students?.map((s) => ({
      ...s,
      key: s.user.id,
      email: s.user.email,
      institutionalId: s.profile?.institutional_id || null,
      firstName: s.profile?.firstname || null,
      lastName: s.profile?.lastname || null,
      isActive: s.user.is_active === 1,
      roles: s.user.roles,
    })) || [];
  } catch (e) {
    console.error(e);
  }

  return (
    <>
      <PageHeader header="User Management" subheader="Create, edit, delete users" />
      <br />
      <Container>
        <Controls>
          <Button
            type="text"
            size="large"
            icon={<LeftOutlined rev={undefined} />}
            style={{ marginRight: "auto" }}
            onClick={() => {
              navigate(getDashboardPath());
            }}
          >
            Dashboard
          </Button>
          {canCreate && (
            <>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined rev={undefined} />}
                onClick={() => setIsCreate(true)}
              >
                Create
              </Button>
              <ModalUserCreate
                open={isCreate}
                onCancel={() => setIsCreate(false)}
                onSubmit={() => setIsCreate(false)}
              />
            </>
          )}
          <UserEdit
            userId={editUser}
            onSubmit={() => setEditUser(null)}
            onCancel={() => setEditUser(null)}
          />
          <ChangePassword
            userId={changePassUser}
            onSubmit={() => setChangePassUser(null)}
            onCancel={() => setChangePassUser(null)}
          />
        </Controls>
        <UsersContainer>
          <Table
            columns={columns}
            dataSource={rows}
            loading={isLoading || isFetching}
            scroll={{ x: "100%" }}
          />
        </UsersContainer>
      </Container>
    </>
  );
}

type RowDataType = { key: React.Key } & Pick<
  UserColumns,
  "institutionalId" | "email" | "firstName" | "lastName" | "isActive" | "roles"
> &
  UserPublicWithProfile;

function UserActionsCell({
  user,
  onEdit,
  onChangePassword,
  onActivate,
  onDeactivate,
  onDelete,
}: {
  user: UserPublicWithProfile;
  onEdit: (user: UserPublicWithProfile) => void;
  onChangePassword: (user: UserPublicWithProfile) => void;
  onActivate: (user: UserPublicWithProfile) => void;
  onDeactivate: (user: UserPublicWithProfile) => void;
  onDelete: (user: UserPublicWithProfile) => void;
}) {
  const { data: caller, isSuccess, isLoading, isFetching, isError } = useGetMeQuery();

  const { canEdit, canDelete } = getTargetPermission({ caller, target: user });

  const isActive = !!user.user.is_active;

  const items: MenuProps["items"] = [
    { key: "edit" as UserActions, label: "Edit", disabled: !canEdit },
    { key: "changePassword" as UserActions, label: "Change Password", disabled: !canEdit },
    ...(isActive
      ? [{ key: "deactivate" as UserActions, label: "Deactivate", disabled: !canEdit }]
      : [{ key: "activate" as UserActions, label: "Activate", disabled: !canEdit }]),
    { key: "delete" as UserActions, label: "Delete", danger: true, disabled: !canDelete },
  ];

  const handleItemClick: MenuProps["onClick"] = ({ key }) => {
    switch (key as UserActions) {
      case "edit":
        onEdit(user);
        break;
      case "changePassword":
        onChangePassword(user);
        break;
      case "activate":
        onActivate(user);
        break;
      case "deactivate":
        onDeactivate(user);
        break;
      case "delete":
        onDelete(user);
        break;
    }
  };

  return (
    <Dropdown menu={{ items, onClick: handleItemClick }} trigger={["click"]}>
      <Button type="default" icon={<MoreOutlined rev={undefined} />} />
    </Dropdown>
  );
}

type UserActions = "edit" | "changePassword" | "activate" | "deactivate" | "delete";
