import {
  Down,
  HomeIcon,
  SidebarBackdrop,
  SidebarEntry,
  SidebarHeader,
  SidebarList,
  SidebarLists,
  SidebarListsDropdown,
  UpIcon,
} from "./style";
import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { hideLeftSidebar } from "../../../features/nav/nav-slice";

type Prop = {
  isActive: boolean;
  entries: LeftNavEntries[];
  onEntryClick: () => void;
};

export type LeftNavEntries = LinkEntryProps | GroupEntryProps;

export enum EntryType {
  LINK,
  GROUP,
}

export type LinkEntryProps = {
  type: EntryType.LINK;
  title: string;
  link: string;
};

export type GroupEntryProps = {
  type: EntryType.GROUP;
  title: string;
  subEntries: LinkEntryProps[];
};

export default function LeftSidePanel({ isActive, entries, onEntryClick }: Prop) {
  return (
    <SidebarBackdrop sidebar={isActive}>
      <SidebarList>
        <SidebarHeader>Site Navigation</SidebarHeader>
        <SidebarLists>
          <SidebarEntry to={"/dashboard"} onClick={onEntryClick}>
            <div>
              Dashboard <HomeIcon />
            </div>
          </SidebarEntry>
        </SidebarLists>
        {entries.map((entry, index) => {
          switch (entry.type) {
            case EntryType.LINK:
              return (
                <SidebarLists key={index}>
                  <SidebarEntry to={entry.link} onClick={onEntryClick}>
                    <div>{entry.title}</div>
                  </SidebarEntry>
                </SidebarLists>
              );
            case EntryType.GROUP:
              return <GroupEntry key={index} onEntryClick={onEntryClick} {...entry} />;
          }
        })}
      </SidebarList>
    </SidebarBackdrop>
  );
}

function GroupEntry({
  title,
  subEntries,
  onEntryClick,
}: GroupEntryProps & { onEntryClick: () => void }) {
  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <SidebarLists
        onClick={() => {
          setDropdown(!dropdown);
        }}
      >
        {title} {dropdown ? <UpIcon /> : <Down />}
      </SidebarLists>
      {dropdown &&
        subEntries.map((entry, index) => (
          <SidebarListsDropdown key={index}>
            <SidebarEntry to={entry.link} onClick={onEntryClick}>
              <div>{entry.title}</div>
            </SidebarEntry>
          </SidebarListsDropdown>
        ))}
    </>
  );
}
