import { StyledCheckMark } from "./style";

export default function CheckMark() {
  return (
    <StyledCheckMark>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.24 33.85">
        <polygon points="18.74 33.85 0 15.11 5.35 9.77 18.74 23.16 41.9 0 47.24 5.35 18.74 33.85" />
      </svg>
    </StyledCheckMark>
  );
}
