import { Bars } from "../style";
import LeftSidePanel, { LeftNavEntries } from "./LeftSidePanel";
import { ClickAwayListener } from "@mui/base";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { hideLeftSidebar, toggleLeftSidebar } from "../../../features/nav/nav-slice";
import { selectLeftNav } from "../../../features/nav/nav-selectors";

export default function LeftSideNav({ entries }: { entries: LeftNavEntries[] }) {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => selectLeftNav(state));

  const closeSidebar = () => {
    dispatch(hideLeftSidebar());
  };

  const toggleSidebar = () => {
    dispatch(toggleLeftSidebar());
  };

  return (
    <ClickAwayListener onClickAway={closeSidebar}>
      <div style={{ margin: "" }}>
        <Bars onClick={toggleSidebar} />
        <LeftSidePanel isActive={isActive} entries={entries} onEntryClick={closeSidebar} />
      </div>
    </ClickAwayListener>
  );
}
