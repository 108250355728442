import { MathField } from "react-mathquill";
import { StyleSet } from "../../features/topic/types";
import CheckMark from "./CheckMark";
import {
  StepBody,
  StepContainer,
  StepNumber,
  StepExpression,
  ProblenStepInputField,
} from "./style";

type Props = {
  index: number;
  expression: string;
  onChange: (expression: MathField) => void;
  styling?: StyleSet;
};

export default function SolutionStepEdit({
  index,
  expression,
  onChange,
  styling,
}: Props) {
  return (
    <StepContainer styling={"primary"}>
      <StepNumber>Step {index + 1}</StepNumber>
      <StepBody>
        <StepExpression>
          <ProblenStepInputField latex={expression} onChange={onChange} />
        </StepExpression>
      </StepBody>
    </StepContainer>
  );
}
