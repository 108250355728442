import { Route, Routes, useNavigate } from "react-router-dom";
import ExampleCreate, { ExampleCreate2 } from "./ExampleCreate";
import ExamplePage from "./ExamplePage";
import { useTopicContext } from "../Context";

export default function ExampleManager({ topicId }: { topicId: number }) {
  const navigate = useNavigate();
  const { canEdit } = useTopicContext();
  return (
    <Routes>
      <Route
        path=":exampleId/*"
        element={<ExamplePage afterDelete={() => navigate("..")} />}
      />
      {canEdit && (
        <Route
          path="new"
          element={
            <ExampleCreate2
              topicId={topicId}
              onDone={(exampleId) => navigate(`${exampleId}`)}
              onCancel={() => navigate("..")}
            />
          }
        />
      )}
    </Routes>
  );
}
