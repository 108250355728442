import React, { useRef, useState } from "react";
import { Button, Form, FormInstance } from "antd";
import ReactQuill from "react-quill";
import { SaveFilled } from "@ant-design/icons";
import Editor2 from "./Editor2";
import {
  StepBody,
  StepContainer,
  StepExpression,
  StepNumber,
} from "../../../../components/SolutionStep/style";
import MathField from "../../../../components/Mathlive/MathField";

export interface InputFormData {
  expression: string;
  explanation: string;
}

export interface OutputFormData {
  expression: string;
  explanation: string;
}

export function ExampleStepForm2({
  form,
  initialValues,
  index,
  onSubmit,
  onCancel,
  disabled = false,
}: {
  form: FormInstance<OutputFormData>;
  initialValues?: Partial<InputFormData>;
  index: number;
  onSubmit: (values: OutputFormData) => void;
  onCancel: () => void;
  disabled?: boolean;
}) {
  const [explanation, setExplanation] = useState(
    initialValues ? (initialValues.explanation ? initialValues.explanation : "") : "",
  );
  const quillRef = useRef<ReactQuill>(null);

  const handleSubmit = async () => {
    try {
      const stepValues = await form.validateFields();
      console.log(stepValues);
      onSubmit({
        expression: stepValues.expression.trim(),
        explanation: stepValues.explanation.trim(),
      });
      form.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Form
      form={form}
      name="step_form"
      layout="vertical"
      initialValues={initialValues}
      disabled={disabled}
    >
      {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
        <StepContainer styling={"primary"}>
          <StepNumber>Step {index + 1}</StepNumber>
          <StepBody>
            <StepExpression>
              <Form.Item
                name="expression"
                label="Expression"
                rules={[{ required: true, message: "Please input expression!" }]}
              >
                <MathField
                  defaultValue={initialValues?.expression || ""}
                  style={{ minWidth: "200px", maxWidth: "500px" }}

                />
              </Form.Item>
            </StepExpression>
          </StepBody>
        </StepContainer>

        <ExplanationInput
          explanation={explanation}
          setExplanation={setExplanation}
          quillRef={quillRef}
        />
      {/* </div> */}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            icon={<SaveFilled rev={undefined} />}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

// interface ExpressionStepEditProps {
//   index: number;
//   expression: string;
//   onChange: (expression: MathField) => void;
// }
//
// function ExpressionStepInput({ index, expression, onChange }: ExpressionStepEditProps) {
//   return (
//     <Form.Item
//       name="expression"
//       label="Expression"
//       rules={[{ required: true, message: "Please input expression!" }]}
//     >
//       <EditableMathField
//         latex={expression}
//         onChange={onChange}
//         style={{ minWidth: "20em", maxWidth: "100%" }}
//       />
//     </Form.Item>
//   );
// }

interface ExplanationInputProps {
  explanation: string;
  setExplanation: React.Dispatch<React.SetStateAction<string>>;
  quillRef: any;
}

function ExplanationInput({ explanation, setExplanation, quillRef }: ExplanationInputProps) {
  return (
    <Form.Item
      name="explanation"
      label="Explanation"
    // rules={[{ required: true, message: "Please input explanation!" }]}
    >
      <Editor2
        initialValue={explanation}
        ref={quillRef}
        onChange={setExplanation}
        reference={quillRef}
      />
    </Form.Item>
  );
}
