import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Button, PreviusButton } from "../../../../components/Button/Button";
import SolutionStepEdit from "../../../../components/SolutionStep/SolutionStepEdit";
import { StepExplanation } from "../../../Student/Assignment/Example/style";
import Editor from "./Editor";

interface FromData {
  expression: string;
  explanation: string;
}

export default function ExampleStepForm({
  initialData,
  index,
  onCancel,
  onSubmit,
  isLoading,
}: {
  initialData: FromData;
  index: number;
  onCancel: () => void;
  onSubmit: (data: FromData) => void;
  isLoading: boolean;
}) {
  const [expression, setExpression] = useState(initialData.expression);
  const explanationEditorRef = useRef<ReactQuill>(null);

  return (
    <tr key={index}>
      <td>
        <SolutionStepEdit
          index={index}
          expression={expression}
          onChange={(field) => setExpression(field.latex())}
        />
      </td>
      <td>
        <StepExplanation>
          <Editor initialValue={initialData.explanation} ref={explanationEditorRef} />
        </StepExplanation>
      </td>
      <td>
        <div>
          <PreviusButton className="problemStepEdit" onClick={onCancel}>
            Cancel
          </PreviusButton>
          <Button
            className="problemStepEdit"
            onClick={(event) => {
              event.preventDefault();
              onSubmit({
                expression,
                explanation:
                  explanationEditorRef.current?.value.toString() || initialData.explanation,
              });
            }}
          >
            Save
          </Button>
        </div>
      </td>
      <div style={{ width: "20px", paddingLeft: "10px" }}></div>
    </tr>
  );
}
