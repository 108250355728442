import { useState } from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { useGetCoursesQuery } from "../../features/api/student/course";
import { Course } from "../../features/api/student/types";
import {
  CourseDetailed,
  CourseDetailPt2,
  CoursePanel,
  CoursePanelFootr,
  CoursePanelHeader,
  LinkAllCourse,
  StudentAssignmentIcon,
} from "./style1";
import { getAllCoursePath, getAssignmentsByCourse, getCoursePath } from "./nav";
import {
  CoursePanelsLayout,
} from "./style";

interface StudentCoursesProps {
  isDashboard: boolean,
}

export default function StudentCourses({ isDashboard }: StudentCoursesProps) {
  const {
    data: courses,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetCoursesQuery();

  if (isLoading) {
    return (
      <div>
        <h1>Courses</h1>
        <p>Loading...</p>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div>
        {isDashboard && (
        <CoursePanelsLayout>
          {courses.slice(0, 3).map((course) => CourseElement(course))}
          <LinkAllCourse to={getAllCoursePath()}>View all...</LinkAllCourse>
        </CoursePanelsLayout>
        )}
        {!isDashboard && (
        <CoursePanelsLayout>
          {courses.map((course) => CourseElement(course))}
        </CoursePanelsLayout>
        )}
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <h1>Courses</h1>
        <p>Error: {JSON.stringify(error)}</p>
      </div>
    );
  }

  return (
    <div>
      <h1>Courses</h1>
      <p>Unexpected error</p>
    </div>
  );
}
function CourseElement(course: Course) {
  const totalAssignment =
    course.activeAssignmentCount +
    course.scheduledAssignmentCount +
    course.closedAssignmentCount;

  return (
    <NavLink
      to={getAssignmentsByCourse(course.id)}
      style={{ textDecoration: "none", padding: "1em" }}
    >
      <CoursePanel key={course.id}>
        <CoursePanelHeader>
          <p
            style={{
              margin: "auto",
              bottom: "0",
              position: "absolute",
              left: "50%",
              transform: " translateX(-50%)",
            }}
          >
            {course.template.name}
          </p>
        </CoursePanelHeader>
        <CoursePanelFootr>
          <CourseDetailed>
            Section: <strong>{course.section} </strong>
            <br />
            Semester: <strong>{course.semester} </strong>
            <br />
            Year: <strong>{course.year} </strong>
            <br />
          </CourseDetailed>
          <CourseDetailPt2>
            <StudentAssignmentIcon /> {totalAssignment}
          </CourseDetailPt2>
        </CoursePanelFootr>
      </CoursePanel>
    </NavLink>
  );
}
