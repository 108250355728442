import { useForm } from "antd/es/form/Form";
import { useCreateProblemStepMutation } from "../../../../../features/api/professor/problem";
import ProblemStepFrom from "../../components/ProblemStepForm";
import { OutputFormData, ProblemStepForm2 } from "../../components/ProblemStepForm2";
import { ModalProblemStepCreate } from "./ModalStepCreate";

export default function StepCreate({
  problemId,
  index,
  onDone,
  onCancel,
}: {
  problemId: number;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [createStep, { isLoading: isCreating }] =
    useCreateProblemStepMutation();

  return (
    <ProblemStepFrom
      initialData={{
        solution: "",
        hint: "",
      }}
      index={index}
      onCancel={onCancel}
      onSubmit={(data) => {
        createStep({ ...data, problem_id: problemId }).then(onDone);
      }}
      isLoading={false}
    />
  );
}

export function StepCreate2({
  problemId,
  index,
  onDone,
  onCancel,
}: {
  problemId: number;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [createStep, { isLoading: isCreating }] = useCreateProblemStepMutation();

  return (
    <ModalProblemStepCreate
      open={true}
      problemId={problemId}
      index={index}
      onCancel={onCancel}
      onSubmit={(data) => {
        createStep({ ...data, problem_id: problemId }).then(onDone);
      }}
      isLoading={false}
    />
  );
}

export function StepCreate3({
  problemId,
  index,
  onDone,
  onCancel,
}: {
  problemId: number;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [createStep, { isLoading: isCreating }] = useCreateProblemStepMutation();
  const [form] = useForm<OutputFormData>();

  return (
    <ProblemStepForm2
      form={form}
      index={index}
      onCancel={onCancel}
      onSubmit={(data) => {
        // String.prototype.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
        createStep({ ...data, problem_id: problemId }).then(onDone);
      }}
    />
  );
}