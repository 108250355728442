import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { RootState } from "../../app/reducers";
import { API_URL } from "./api-url";
import { Tag } from "./types";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const tokenData = (getState() as RootState).auth.tokenData;
    if (tokenData) {
      headers.set("Authorization", `Bearer ${tokenData.token}`);
    }
    return headers;
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: [...Object.values(Tag)],
  endpoints: () => ({}),
  baseQuery: baseQuery,
});

// TODO: comeup with better solution to inject endpoints and record all endpoints for middleware matcher
// export const endpoints = [];

// const wrap = <T extends Array<any>, U>(fn: (...args: T) => U, run: (...args: T) => void) => {
//   run(...args);
//   return (...args: T): U => fn(...args);
// };

// export const injectSlice = wrap(apiSlice.injectEndpoints, (a) => {
//   console.log("WRAAAP");
// });
