import Divider from "./Divider";
import Header from "./Header";
import Subheader from "./Subheader";
import { HeaderContainer } from "./style";

type Props = {
  header: string;
  subheader: string;
  children?: React.ReactNode;
};

export default function PageHeader({ header, subheader, children }: Props) {
  return (
    <HeaderContainer>
      <Header>{header}</Header>
      <Subheader>{subheader}</Subheader>
      <br />
      <Divider />
      {children}
    </HeaderContainer>
  );
}
