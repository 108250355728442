import { apiSlice } from "./api-slice";
import { CREDENTIALS } from "./api-url";
import {
  Tag,
  UserPrivateWithProfile,
  UserPrivateWithProfileRaw,
  UserPublicWithProfile,
  UserPublicWithProfileRaw,
  UserRole,
} from "./types";
import { transformUserPrivate, transformUserPublic } from "./util";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<UserPrivateWithProfile, void>({
      query() {
        return {
          url: "users/me",
          credentials: CREDENTIALS ? "include" : undefined,
        };
      },
      transformResponse: (result: { data: UserPrivateWithProfileRaw }) =>
        transformUserPrivate(result.data),
      providesTags: (result) => [
        Tag.MY_DATA,
        ...(result ? [{ type: Tag.USER_DATA, id: result.user.id }] : []),
      ],
    }),
    getUsers: builder.query<UserPublicWithProfile[], GetUsersProps | void>({
      query: (args) => ({
        url: "users",
        params: {
          role: args?.role,
        },
      }),
      transformResponse: (result: { data: UserPublicWithProfileRaw[] }): UserPublicWithProfile[] =>
        {
          const resultTransformed = result.data.map(transformUserPublic);
          return resultTransformed;
        },
      providesTags: (result) => [
        { type: Tag.USER_DATA, id: "LIST" },
        ...(result?.map((u) => ({
          type: Tag.USER_DATA,
          id: u.user.id,
        })) || []),
      ],
    }),
    getUser: builder.query<UserPublicWithProfile, number>({
      query: (userId) => ({
        url: `users/${userId}`,
      }),
      transformResponse: (result: { data: UserPublicWithProfileRaw }): UserPublicWithProfile =>
        {
          const resultTransformed = transformUserPublic(result.data);
          return resultTransformed;
        },
      providesTags: (result) => (result ? [{ type: Tag.USER_DATA, id: result.user.id }] : []),
    }),
    updateUser: builder.mutation<UserPublicWithProfile, UserUpdateParams>({
      query: ({ id, roles, ...data }) => ({
        url: `users/${id}`,
        method: "PUT",
        body: { ...data, roles: JSON.stringify(roles) },
      }),
      transformResponse: (res: { data: UserPublicWithProfileRaw }) => 
      {
        const resTransformed = transformUserPublic(res.data);
        return resTransformed;
      },
      invalidatesTags: (result) => (result ? [{ type: Tag.USER_DATA, id: result.user.id }] : []),
    }),
    deleteUser: builder.mutation<UserPublicWithProfile, number>({
      query: (userId) => ({
        url: `users/${userId}`,
        method: "DELETE",
      }),
      transformResponse: (res: { data: UserPublicWithProfileRaw }) => transformUserPublic(res.data),
      invalidatesTags: (result) => (result ? [{ type: Tag.USER_DATA, id: result.user.id }] : []),
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;

export interface GetUsersProps {
  role?: UserRole;
}

export interface UserUpdateParams {
  id: number;
  firstname?: string;
  lastname?: string;
  affiliation?: string;
  institutional_id?: number;
  user_bio?: string;
  profile_picture_url?: string;
  username?: string;
  email?: string;
  password?: string;
  roles?: UserRole[];
  is_active?: number;
}
