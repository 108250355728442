import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from "@reduxjs/toolkit";

import { registerLogoutMiddleware } from "../features/api/logout-middleware";
import { registerAuthMiddleware } from "../features/auth/auth-middleware";
import type { RootState } from "./reducers";
import type { AppDispatch } from "./store";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

registerLogoutMiddleware(startAppListening);
registerAuthMiddleware(startAppListening);
