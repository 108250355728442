import { AssignmentSummary } from "../../features/api/student/assignment";
import { getAssignmentPathById } from "../Course/nav";
import {
  AssignmentCourse,
  AssignmentDate,
  AssignmentInnerPanel,
  AssignmentPanel,
  AssignmentPanelMark,
  AssignmentsContainer,
  AssignmentTitle,
  StyledLi,
} from "./style";
import DateMessages from "../utils/Dates/DateMenssages";
import { NavLink, useNavigate } from "react-router-dom";
import React from "react";
import { useGetCourseQuery } from "../../features/api/professor/course";
import { SummaryPie } from "./AssigmentSummaryPie";
import { App } from "antd";

interface AssignmentTileProps {
  assignment: AssignmentSummary;
}

export default function StudentAssignmentTile({ assignment }: AssignmentTileProps) {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { data: course, isSuccess, isLoading, isError } = useGetCourseQuery(assignment.courseId);

  const handleNavigate = () => {
    const startDate = new Date(assignment.startDate);
    if (Date.now() > startDate.getTime()) {
      navigate(getAssignmentPathById(assignment.id));
    } else {
      message.info("Wait unit assignment starts");
    }
  };

  return (
    <StyledLi onClick={handleNavigate}>
      <AssignmentsContainer>
        <AssignmentPanel>
          <AssignmentPanelMark courseColor={course?.color ? `#${course.color}` : "transparent"} />
          <AssignmentInnerPanel>
            <AssignmentTitle>{assignment.title}</AssignmentTitle>
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <AssignmentCourse courseColor={course?.color ? `#${course.color}` : "black"}>
                  {isSuccess ? (
                    <>
                      <span>
                        {course.template.name} {course.section}
                      </span>
                      <span style={{ display: "block", fontSize: "0.8em" }}>
                        {course.semester.toUpperCase()} {course.year}
                      </span>
                    </>
                  ) : isLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <span>N/A</span>
                  )}
                </AssignmentCourse>
                <AssignmentDate>
                  <DateMessages
                    start_date={assignment.startDate.toString()}
                    end_date={assignment.dueDate.toString()}
                  />
                </AssignmentDate>
              </div>
              <div style={{ margin: "6px 20px 6px 0" }}>
                <AverageReport
                  assignment={assignment}
                  width={138}
                  height={138}
                  fontSizeA="32px"
                  fontSizeT="22px"
                  centerX={22}
                  centerY={40}
                  innerRadius={64}
                />
              </div>
            </div>
          </AssignmentInnerPanel>
        </AssignmentPanel>
      </AssignmentsContainer>
    </StyledLi>
  );
}
interface AverageReportProps {
  assignment: AssignmentSummary;
  width: number;
  height: number;
  fontSizeA: string;
  fontSizeT: string;
  centerX: number;
  centerY: number;
  innerRadius: number;
}

function AverageReport({
  assignment,
  width,
  height,
  fontSizeA,
  fontSizeT,
  centerX,
  centerY,
  innerRadius,
}: AverageReportProps) {
  const earnedPoints = assignment.earnedPoints;
  const lostPoints = assignment.lostPoints;
  const maxPoints = assignment.maxPoints;
  const unansweredPoints = maxPoints - earnedPoints - lostPoints;
  const progress = (earnedPoints / maxPoints) * 100;
  const colors = ["#55A5E9", "#C4C4C4", "#ED3B3B"];

  const pieData: { x: string | number; y: number }[] = [
    { x: "Earned Points", y: earnedPoints },
    { x: "Unanswered", y: unansweredPoints },
    { x: "Lost Points", y: lostPoints },
  ];

  return (
    <SummaryPie
      pieData={pieData}
      earnedAverage={earnedPoints}
      maxPoints={maxPoints}
      width={width}
      height={height}
      colors={colors}
      fontSizeA={fontSizeA}
      fontSizeT={fontSizeT}
      centerX={centerX}
      centerY={centerY}
      innerRadius={innerRadius}
    />
  );
}

function validDates(start_date: Date, end_date: Date): boolean {
  const now = new Date();
  return start_date <= now;
}
