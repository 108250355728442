import styled from "styled-components";

export const HeaderContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const StyledHeader = styled.h1`
  margin: 0;
  font-size: 22px;
  text-align: center;
`;

export const StyledSubHeader = styled.h3`
  text-align: center;
  margin: 0;
`;

export const StyledSubheader = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin: 8px 0 8px 0;
  text-align: center;
  color: #3d3d3d;
`;

export const StyledDivider = styled.hr`
  width: 90%;
  border: 1px solid #d6d6d6;
  margin: auto;
`;
