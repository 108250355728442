import CourseTemplateFormNew, { CourseTemplateFormData } from "./CourseTemplateFormNew";
import { App, Form, Modal } from "antd";
import { useCreateCourseTemplateMutation } from "../../../features/api/professor/course";

export interface CourseTemplateCreateProps {
  open: boolean;
  initialValues?: Partial<CourseTemplateFormData>;
  onSubmit: () => void;
  onCancel: () => void;
}

export default function CourseTemplateCreateNew({
  open,
  initialValues,
  onSubmit,
  onCancel,
}: CourseTemplateCreateProps) {
  const { message } = App.useApp();
  const [form] = Form.useForm<CourseTemplateFormData>();
  const [createCourseTemplate, { isLoading: isCreating }] = useCreateCourseTemplateMutation();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        createCourseTemplate({
          name: values.name,
          description: values.description || "",
          affiliation: values.affiliation || "",
        })
          .unwrap()
          .then(() => {
            message.success("Subject created");
            onSubmit();
            form.resetFields();
          })
          .catch(() => {
            message.error("Failed to create subject");
          });
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Create Subject"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={isCreating}
    >
      <CourseTemplateFormNew
        initialValues={initialValues || { name: "", affiliation: "", description: "" }}
        form={form}
      />
    </Modal>
  );
}
