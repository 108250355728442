import styled, { css } from "styled-components";
import ReactQuill from "react-quill";
import Editor from "./Editor";
import { EditableMathField } from "react-mathquill";

export const TransparentBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #8a898972;
`;

export const EditContainer = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  & div.ql-snow.ql-container {
    height: 400px;
  }
  /* width: 70%; */
  height: 716px;
  transition: 4ms;
  z-index: 2;

  /* @media (max-width: 800px) {
    width: 500px;
    height: 600px;
  } */
`;

export const EditText = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin: auto;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.7);
`;

export const EditTitleLabel = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HintLabel = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & div.ql-snow.ql-container {
    height: 250px;
  }
`;

export const EditTitleInput = styled.input`
  box-sizing: border-box;
  text-align: center;
  width: 500px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
  @media (max-width: 800px) {
    width: 445px;
  }
`;
export const EditMathInput = styled(EditableMathField)`
  box-sizing: border-box;
  text-align: center;
  width: 500px;
  height: 40px;
  left: 498px;
  top: 369px;

  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;

  min-width: 445px;
`;

export const EditContentInput = styled(Editor)`
  width: 800px;
  height: 350px;
  padding-left: 5%;
  padding-right: 5%;
  transition: 1ms;
  @media (max-width: 800px) {
    width: 400px;
  }
`;

export const HintInput = styled(Editor)`
  width: 800px;
  padding-left: 5%;
  padding-right: 5%;
  transition: 1ms;
`;

export const EditButtonsContainer = styled.div`
  margin-bottom: 2%;
  /* padding-top: 2%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  overflow: "hidden";
`;

{
  /**      Slate Style          */
}

export const Toolbar = styled.div`
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`;

export const Menu = styled.div`
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`;

type Buttontypes = {
  active?: boolean;
  reversed?: boolean;
};

export const SlateButton = styled.button<Buttontypes>`
  cursor: pointer;

  color: ${({ reversed, active }) =>
    reversed ? (active ? "white" : "#aaa") : active ? "black" : "#ccc"};
`;

export const Icon = styled.span`
  font-size: 18px;
  vertical-align: text-bottom;
`;

{
  /**  Notebook Style */
}

export const NoteBookViewStyle = styled.div`
  background: #f5f5f5;
  display: grid;
  grid-template-columns: auto;
  /* height: 100vh;
  width: 100vw; */
`;
