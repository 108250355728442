import PageHeader from "../../../components/PageHeader";
import React, { useState } from "react";
import { Container, Controls, CourseTemplatesContainer } from "./style";
import { App, Button, Dropdown, MenuProps, Table, Typography } from "antd";
import {
  ExclamationCircleFilled,
  LeftOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getDashboardPath } from "../nav";
import { useNavigate } from "react-router-dom";
import { useCourseTemplateContext } from "./CourseTemplateContext";
import { ColumnsType } from "antd/lib/table";
import { Columns, getColumnSearchProps } from "../../../components/Table/columns";
import {
  useDeleteCourseTemplateMutation,
  useGetCourseTemplatesQuery,
} from "../../../features/api/professor/course";
import CourseTemplateCreateNew from "./CourseTemplateCreateNew";
import CourseTemplateEditNew from "./CourseTemplateEditNew";

export default function CourseTemplateList() {
  const navigate = useNavigate();
  const { message, modal } = App.useApp();
  const { canCreate, canEdit, canDelete } = useCourseTemplateContext();

  const {
    data: subjects,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetCourseTemplatesQuery();

  const [deleteCourseTemplate, { isLoading: isDeleting }] = useDeleteCourseTemplateMutation();

  const [isCreate, setIsCreate] = useState(false);
  const [editSubject, setEditSubject] = useState<number | null>(null);

  const handleEdit = (row: RowDataType) => {
    setEditSubject(row.id);
  };

  const handleDelete = (row: RowDataType) => {
    const id = row.id;
    const name = row.courseTemplateName;
    modal.confirm({
      title: "Are you sure to delete this subject?",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: (
        <span>
          Subject <b>{name}</b> will be deleted. This action cannot be undone!
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        message.open({
          key: `delete-course-template-${id}`,
          type: "loading",
          content: "Deleting subject...",
        });
        deleteCourseTemplate(id)
          .unwrap()
          .then(() =>
            message.open({
              key: `delete-course-template-${id}`,
              type: "success",
              content: `Subject ${name} deleted`,
            }),
          )
          .catch((error) => {
            message.destroy(`delete-course-template-${id}`);
            if (error.status === 409) {
              modal.warning({
                title: "Cannot delete subject",
                content: (
                  <>
                    <Typography.Text>{error.data.message}</Typography.Text>
                  </>
                ),
              });
            } else if (error.status === 404) {
              modal.error({
                title: "Cannot delete subject",
                content: (
                  <>
                    <Typography.Text>
                      Assignment not found. It is probably already deleted. Refresh this page.
                    </Typography.Text>
                  </>
                ),
              });
            } else {
              message.open({
                key: `delete-course-template-${id}`,
                type: "error",
                content: "Failed to delete subject",
              });
            }
          });
      },
    });
  };

  const affiliations = new Set(subjects?.map((s) => s.affiliation) || []);
  const columns: ColumnsType<RowDataType> = [
    {
      title: "Subject",
      dataIndex: "courseTemplateName",
      sorter: (a, b) => a.courseTemplateName.localeCompare(b.courseTemplateName),
      ...getColumnSearchProps("courseTemplateName"),
    },
    {
      title: "Affiliation",
      dataIndex: "affiliation",
      sorter: (a, b) => a.affiliation.localeCompare(b.affiliation),
      filters: Array.from(affiliations).map((s) => ({
        text: s,
        value: s,
      })),
      onFilter: (value, record) => record.affiliation == record.affiliation,
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, record) => (
        <CourseTemplateActionsCell data={record} onEdit={handleEdit} onDelete={handleDelete} />
      ),
    },
  ];

  const rows: RowDataType[] =
    subjects?.map((s) => ({
      key: s.id,
      id: s.id,
      courseTemplateName: s.name,
      description: s.description,
      affiliation: s.affiliation,
    })) || [];

  return (
    <>
      <PageHeader header="Subject Management" subheader="Create, edit, delete subjects" />
      <Container>
        <Controls>
          <Button
            type="text"
            size="large"
            icon={<LeftOutlined rev={undefined} />}
            style={{ marginRight: "auto" }}
            onClick={() => {
              navigate(getDashboardPath());
            }}
          >
            Dashboard
          </Button>
          {canCreate && (
            <>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined rev={undefined} />}
                onClick={() => setIsCreate(true)}
              >
                Create
              </Button>
              <CourseTemplateCreateNew
                open={isCreate}
                onSubmit={() => setIsCreate(false)}
                onCancel={() => setIsCreate(false)}
              />
            </>
          )}
          <CourseTemplateEditNew
            id={editSubject}
            onSubmit={() => setEditSubject(null)}
            onCancel={() => setEditSubject(null)}
          />
        </Controls>
        <CourseTemplatesContainer>
          <Table
            columns={columns}
            dataSource={rows}
            loading={isLoading || isFetching}
            scroll={{ x: "100%" }}
          />
        </CourseTemplatesContainer>
      </Container>
    </>
  );
}

type RowDataType = { key: React.Key; id: number } & Pick<
  Columns,
  "courseTemplateName" | "description" | "affiliation"
>;

interface CourseTemplateActionsProps {
  data: RowDataType;
  onEdit: (row: RowDataType) => void;
  onDelete: (row: RowDataType) => void;
}

function CourseTemplateActionsCell({ data, onEdit, onDelete }: CourseTemplateActionsProps) {
  const { canEdit, canDelete } = useCourseTemplateContext();

  const items: MenuProps["items"] = [
    {
      key: "edit" as CourseTemplateActionType,
      label: "Edit",
      disabled: !canEdit,
    },
    {
      key: "delete" as CourseTemplateActionType,
      label: "Delete",
      danger: true,
      disabled: !canDelete,
    },
  ];

  const handleItemClick: MenuProps["onClick"] = ({ key }) => {
    switch (key as CourseTemplateActionType) {
      case "edit":
        onEdit(data);
        break;
      case "delete":
        onDelete(data);
        break;
    }
  };

  return (
    <Dropdown menu={{ items, onClick: handleItemClick }} trigger={["click"]}>
      <Button type="default" icon={<MoreOutlined rev={undefined} />} />
    </Dropdown>
  );
}

type CourseTemplateActionType = "edit" | "delete";
