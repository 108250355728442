import { Item, Section, SideNav } from "../../../components/SideNav";
import { useGetStudentAssignmentQuery } from "../../../features/api/student/assignment";
import { NavContainer, ProblemNavContent } from "./style";

export default function AssignmentNav({ assignmentId }: { assignmentId: number }) {
  const {
    data: assignment,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentAssignmentQuery(assignmentId);

  if (isError) {
    return (
      <NavContainer>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </NavContainer>
    );
  }

  if (isLoading) {
    return (
      <NavContainer>
        <p>Loading...</p>
      </NavContainer>
    );
  }

  if (isSuccess) {
    const { pages, examples, problems } = assignment;

    const sections: Section[] = [
      {
        title: "Introduction",
        items: pages.map(
          (page, pageIdx): Item => ({
            content: page.title,
            href: `intro/${pageIdx + 1}`,
          }),
        ),
      },
      {
        title: "Examples",
        items: examples.map(
          (example, exampleIdx): Item => ({
            content: example.title,
            href: `example/${exampleIdx + 1}`,
          }),
        ),
      },
      {
        title: "Problems",
        items: problems.map((problem, problemIdx): Item => {
          const problemsCompleted =
            problem.status === "correct" ? problem.totalSteps : problem.steps.length - 1;

          return {
            content: (
              <ProblemNavContent problemStatus={problem.status}>
                <span className="title"></span>Problem #{problemIdx + 1}
                <span className="progress">
                  {/* {problemsCompleted}/{problem.totalSteps} */}
                  {Math.round((problemsCompleted / problem.totalSteps) * 100)}%
                </span>
              </ProblemNavContent>
            ),
            href: `problem/${problemIdx + 1}`,
          };
        }),
      },
    ];

    return (
      <NavContainer>
        <SideNav sections={sections} />
      </NavContainer>
    );
  }

  return <p>Something went wrong</p>;
}
