import { ColumnsType } from "antd/lib/table";
import { UserPublicWithProfile } from "../../../../features/api/types";
import { ProblemScoreReport, StudentScoreReport } from "../../../../features/api/professor/grades";
import { Table } from "antd";
import ProblemsTable from "./ProblemsTable";
import { getColumn, GradeColumns, UserColumns } from "../../../../components/Table/columns";
import React from "react";

export interface StudentsTableProps {
  data: CompleteStudentScoreReport[];
  loading: boolean;
}

export type CompleteStudentScoreReport = UserPublicWithProfile & { report?: StudentScoreReport };

export default function StudentsTable({ data, loading }: StudentsTableProps) {
  const rows: RowDataType[] = data.map((d) => ({
    key: d.user.id,
    institutionalId: d.profile?.institutional_id || null,
    email: d.user.email,
    firstName: d.profile?.firstname || null,
    lastName: d.profile?.lastname || null,
    earnedPoints: d.report?.earnedPoints || null,
    lostPoints: d.report?.lostPoints || null,
    unansweredPoints: d.report
      ? d.report.maxPoints - d.report.earnedPoints - d.report.lostPoints
      : null,
    maxPoints: d.report?.maxPoints || null,
    problems: d.report?.problems || null,
  }));

  const columns: ColumnsType<RowDataType> = [
    getColumn.institutionalId(),
    getColumn.email(),
    getColumn.firstName(),
    { ...getColumn.lastName(), defaultSortOrder: "ascend" },
    getColumn.earnedPoints(),
    getColumn.unansweredPoints(),
    getColumn.totalPoints(),
  ];

  return (
    <Table
      columns={columns}
      dataSource={rows}
      expandable={{
        expandedRowRender: (record) => <ProblemsTable data={record.problems || []} />,
        rowExpandable: (record) => !!record.problems,
      }}
      loading={loading}
      pagination={false}
      size="large"
      bordered
      style={{ marginTop: "60px" }}
    />
  );
}

type RowDataType = {
  key: React.Key;
  problems: ProblemScoreReport[] | null;
} & Pick<UserColumns, "institutionalId" | "email" | "firstName" | "lastName"> &
  Pick<GradeColumns, "earnedPoints" | "lostPoints" | "unansweredPoints" | "maxPoints">;
