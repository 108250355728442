import { Outlet, useOutletContext } from "react-router-dom";

export type CourseContextType = {
  canEdit: boolean;
  canCreate: boolean;
  canDelete: boolean;
  canAddStudent: boolean;
  canRemoveStudent: boolean;
  canCreateAssignment: boolean;
};

export default function CourseContext(context: CourseContextType) {
  return <Outlet context={context} />;
}

export function useCourseContext() {
  return useOutletContext<CourseContextType>();
}
