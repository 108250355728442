import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import { Assignment, useGetStudentAssignmentQuery } from "../../../features/api/student/assignment";
import Example from "./Example";
import Introduction from "./Introduction";
import Problem from "./Problem";
import AssignmentNav from "./AssignmentNav";
import {
  BodyContainer,
  Container,
  ContentContainer,
  DateContainer,
  HeaderContainer,
  ScoreContainer,
  Title,
} from "./style";
import React, { useEffect } from "react";
import { Tooltip, Typography } from "antd";
import { getExamplePage, getIntroPage, getProblemPage } from "./NavButtons";

export default function AssignmentPage() {
  const params = useParams();
  const assignmentId = Number(params.topicId);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: assignment,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetStudentAssignmentQuery(assignmentId);

  useEffect(() => {
    // Check if the "pageNo", "exampleNo" and "problemNo" parameters are set
    const pageNo = location.pathname.match(/\/stud\/topic\/[0-9]*\/intro\/(\d+)/) || undefined;
    const exampleNo = location.pathname.match(/\/example\/(\d+)/) || undefined;
    const problemNo = location.pathname.match(/\/problem\/(\d+)/) || undefined;

    if (!pageNo && !exampleNo && !problemNo) {
      const timeoutId = setTimeout(() => {
        if (assignment?.pages.length && assignment?.pages.length > 0) {
          navigate(getIntroPage(assignmentId, 1));
        } else {
          if (assignment?.examples.length && assignment?.examples.length > 0) {
            navigate(getExamplePage(assignmentId, 1));
          } else {
            if (assignment?.problems.length && assignment?.problems.length > 0) {
              navigate(getProblemPage(assignmentId, 1));
            }
          }
        }
      }, 100);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [location.pathname, assignment]);

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <PageHeader header={"Loading..."} subheader={"Loading..."} />
        <BodyContainer>
          <AssignmentNav assignmentId={assignmentId} />
          <ContentContainer>Loading...</ContentContainer>
        </BodyContainer>
      </Container>
    );
  }

  if (isSuccess) {
    return (
      <Container>
        <AssignmentHeader id={assignment.id} />
        <BodyContainer>
          <AssignmentNav assignmentId={assignment.id} />
          <ContentContainer>
            <Routes>
              <Route path="intro/:pageNo" element={<Introduction assignmentId={assignment.id} />} />
              <Route path="example/:exampleNo" element={<Example assignmentId={assignment.id} />} />
              <Route path="problem/:problemNo" element={<Problem assignmentId={assignment.id} />} />
            </Routes>
          </ContentContainer>
        </BodyContainer>
      </Container>
    );
  }

  return (
    <>
      <h1>Error</h1>
      <h2>Unknown error!</h2>
    </>
  );
}

function AssignmentHeader({ id }: { id: number }) {
  const { data: assignment, isLoading, isSuccess } = useGetStudentAssignmentQuery(id);

  if (isSuccess) {
    return (
      <HeaderContainer>
        <ScoreDisplay data={assignment} />
        <Title>{assignment.title}</Title>
        <DateDisplay dueDate={new Date(assignment.dueDate)} />
      </HeaderContainer>
    );
  }

  if (isLoading) {
    return (
      <HeaderContainer>
        <Title>Loading../</Title>
      </HeaderContainer>
    );
  }

  return null;
}

function DateDisplay({ dueDate }: { dueDate: Date }) {
  const current = new Date();

  return (
    <DateContainer>
      <div>
        <span style={{ fontSize: "medium" }}>Due at </span>
        <span style={{ fontSize: "larger", fontWeight: "600" }}>
          {dueDate.toLocaleString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
        {dueDate < current && (
          <Tooltip title="Further submissions will not be accepted" placement="left">
            <Typography.Text type="danger" strong style={{ display: "block" }}>
              Closed
            </Typography.Text>
          </Tooltip>
        )}
      </div>
    </DateContainer>
  );
}

function ScoreDisplay({ data }: { data: Assignment }) {
  const earnedPoints = data.problems.reduce((points, problem) => points + problem.earnedPoints, 0);
  const maxPoints = data.problems.reduce((points, problem) => points + problem.maxPoints, 0);
  return (
    <ScoreContainer>
      <Tooltip title="Score" placement="right">
        {/* <span style={{ fontSize: "large", fontWeight: "600" }}>Score: </span> */}
        <span style={{ fontSize: "xx-large", fontWeight: "bold", color: "#55A5E9" }}>
          {earnedPoints.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}
        </span>
        <span>
          /
          {maxPoints.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}
        </span>
      </Tooltip>
    </ScoreContainer>
  );
}
