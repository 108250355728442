import { setProblemInput, setProblemMessageDisplay } from "../../topic/topic-slice";
import { apiSlice } from "../api-slice";
import { Tag } from "../types";

export const studentAssignmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get all assignments
    getStudentAssignments: builder.query<
      AssignmentSummary[],
      { status?: AssignmentStatus } | undefined | null | void
    >({
      query: (args) => ({
        url: "student/assignment",
        params: {
          status: args?.status,
        },
      }),
      transformResponse: (response: { data: AssignmentSummary[] }) => response.data,
      providesTags: (result) => [
        ...(result?.map((summary) => ({
          type: Tag.STUDENT_ASSIGNMENT_SUMMARY,
          id: summary.id,
        })) || []),
      ],
    }),
    // get recent assignments
    getRecentStudentAssignments: builder.query<AssignmentSummary[], void>({
      query: () => ({ url: "student/assignment/recent" }),
      transformResponse: (response: { data: AssignmentSummary[] }) => response.data,
      providesTags: (result) => [
        ...(result?.map((summary) => ({
          type: Tag.STUDENT_ASSIGNMENT_SUMMARY,
          id: summary.id,
        })) || []),
      ],
    }),
    // get assignment
    getStudentAssignment: builder.query<Assignment, number>({
      query: (id) => ({ url: `student/assignment/${id}` }),
      transformResponse: (response: { data: Assignment }) => response.data,
    }),
    // get problem
    getStudentAssignmentProblem: builder.query<
      Problem,
      { assignmentId: number; problemId: number }
    >({
      query: ({ assignmentId, problemId }) => ({
        url: `student/assignment/${assignmentId}/problem/${problemId}`,
      }),
      transformResponse: (response: { data: Problem }) => response.data,
    }),
    // submit attempt
    submitAttempt: builder.mutation<
      Problem,
      {
        assignmentId: number;
        problemId: number;
        stepId: number;
        expression: string;
      }
    >({
      query: ({ assignmentId, problemId, ...patch }) => ({
        url: `student/assignment/${assignmentId}/problem/${problemId}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, payload) => [
        { type: Tag.STUDENT_ASSIGNMENT_SUMMARY, id: payload.assignmentId },
      ],
      transformResponse: (response: { data: Problem }) => response.data,
      async onQueryStarted({ assignmentId, problemId }, { dispatch, queryFulfilled }) {
        try {
          const updatedProblem = (await queryFulfilled).data;
          dispatch(
            studentAssignmentApiSlice.util.updateQueryData(
              "getStudentAssignment",
              assignmentId,
              (draft) => {
                const problemIndex = draft.problems.findIndex(
                  (problem) => problem.id === problemId,
                );
                draft.problems[problemIndex] = updatedProblem;
              },
            ),
          );

          const lastStep = updatedProblem.steps.slice(-1)[0];
          if (lastStep.attempts.length === 0) {
            // clear expression input
            dispatch(
              setProblemInput({
                topicId: assignmentId.toString(),
                problemId: problemId.toString(),
                expression: "",
              }),
            );
            // hide message display
            dispatch(
              setProblemMessageDisplay({
                topicId: assignmentId.toString(),
                problemId: problemId.toString(),
                display: "NONE",
              }),
            );
          } else if (lastStep.attempts.length > 0) {
            // display feedback message
            dispatch(
              setProblemMessageDisplay({
                topicId: assignmentId.toString(),
                problemId: problemId.toString(),
                display: "MISTAKE",
              }),
            );
          }
        } catch {
          // TODO: handle errors
        }
      },
    }),
  }),
});

export const {
  useGetStudentAssignmentsQuery,
  useGetRecentStudentAssignmentsQuery,
  useGetStudentAssignmentQuery,
  useGetStudentAssignmentProblemQuery,
  useSubmitAttemptMutation,
} = studentAssignmentApiSlice;

export function getAssignmentStatus(data: AssignmentAttributes) {
  const now = Date.now();
  const startDate = new Date(data.startDate).getTime();
  const endDate = new Date(data.dueDate).getTime();
  if (now < startDate) return AssignmentStatus.PLANNED;
  if (startDate < now && now < endDate) return AssignmentStatus.OPEN;
  else return AssignmentStatus.CLOSED;
}

export interface AssignmentAttributes {
  id: number;
  title: string;
  courseId: number;
  startDate: string;
  dueDate: string;
  allowedAttempts?: number;
  allowedStepsAhead?: number;
  similarExpTolerance?: boolean;
}

export interface AssignmentSummary extends AssignmentAttributes {
  earnedPoints: number;
  lostPoints: number;
  maxPoints: number;
  status: AssignmentStatus;
}

export interface Assignment extends AssignmentSummary {
  pages: IntroPage[];
  examples: ExampleProblem[];
  problems: Problem[];
}

export interface IntroPage {
  title: string;
  content: string;
}

export interface ExampleProblem {
  id: number;
  title: string;
  question: string;
  steps: ExampleStep[];
}

export interface ExampleStep {
  expression: string;
  explanation: string;
}

export interface Problem {
  id: number;
  question: string;
  totalSteps: number;
  steps: Step[];
  status: ProblemStatus;
  earnedPoints: number;
  lostPoints: number;
  maxPoints: number;
}

export interface Step {
  id: number;
  status: StepStatus;
  attempts: Attempt[];
  hint: string;
}

export interface Attempt {
  expression: string;
  explanation: string;
  observation: string;
}

export enum AssignmentStatus {
  PLANNED = "planned",
  OPEN = "open",
  CLOSED = "closed",
}

export type ProblemStatus = "correct" | "incorrect" | "unanswered";
export type StepStatus = "correct" | "incorrect" | "unanswered";
