import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectRightNav } from "../../../features/nav/nav-selectors";
import { hideRightSidebar, toggleRightSidebar } from "../../../features/nav/nav-slice";
import { ClickAwayListener } from "@mui/base";
import { UserIcon } from "../style";
import RightSidePanel from "./RightSidePanel";

export default function RightSideNav() {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => selectRightNav(state));

  const closeSidebar = () => {
    dispatch(hideRightSidebar());
  };

  const toggleSidebar = () => {
    dispatch(toggleRightSidebar());
  };

  return (
    <ClickAwayListener onClickAway={closeSidebar}>
      <div style={{ margin: "" }}>
        <UserIcon onClick={toggleSidebar} />
        {isActive && <RightSidePanel onEntryClick={closeSidebar} />}
      </div>
    </ClickAwayListener>
  );
}
