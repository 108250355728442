import { Step } from "../../../../../components/SolutionStep";
import { useDeleteExampleProblemStepMutation } from "../../../../../features/api/professor/example";
import { ExampleStep } from "../../../../../features/api/professor/types";
import { useTopicContext } from "../../Context";
import { Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { ExplanationHeader } from "../../../../../components/QuestionHeader/QuestionHeader";

export function StepView({
  data: step,
  index,
  onEdit,
  disableEdit = false,
  disableDelete = false,
}: {
  data: ExampleStep;
  index: number;
  onEdit: () => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
}) {
  const { canEdit, canDelete } = useTopicContext();
  const [deleteStep, { isLoading: isDeleting }] = useDeleteExampleProblemStepMutation();

  const handleDelete = () => {
    deleteStep(step.id);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", padding: "1em 0 1em 0" }}>
        <div style={{ width: "30%" }}>
          <Step id={index} expression={step.expression} />
        </div>
        {/* <div style={{ width: "60%" }} dangerouslySetInnerHTML={{ __html: step.explanation }} /> */}
        <div style={{ width: "60%" }}>
          <ExplanationHeader>{step.explanation}</ExplanationHeader>
        </div>
        <div style={{ width: "7em", display: "flex", flexDirection: "row" }}>
          {canEdit && (
            <Tooltip title="Edit">
              <Button
                type="primary"
                ghost
                icon={<EditFilled rev={undefined} />}
                onClick={() => {
                  if (!disableEdit) onEdit();
                }}
              />
            </Tooltip>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete step"
              description="Are you sure to delete this step?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Tooltip title="Delete">
                <Button
                  type="default"
                  style={{ marginLeft: "auto" }}
                  icon={<DeleteOutlined rev={undefined} />}
                  danger
                />
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      </div>
    </>
  );
}

export function StepView2({
  data: step,
  index,
  onEdit,
  disableEdit = false,
  disableDelete = false,
}: {
  data: ExampleStep;
  index: number;
  onEdit: () => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
}) {
  const { canEdit, canDelete } = useTopicContext();

  const [deleteStep, { isLoading: isDeleting }] = useDeleteExampleProblemStepMutation();

  const handleDelete = () => {
    deleteStep(step.id);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", padding: "1em 0 1em 0" }}>
        <div style={{ width: "30%" }}>
          <Step id={index} expression={step.expression} />
        </div>
        {/* <div
          style={{ width: "60%" }}
          dangerouslySetInnerHTML={{ __html: step.explanation ? step.explanation : "" }}
        /> */}
        <div style={{ width: "60%" }}>
          <ExplanationHeader>{step.explanation}</ExplanationHeader>
        </div>
        <div style={{ width: "10%", display: "flex", flexDirection: "row" }}>
          {canEdit && (
            <Tooltip title="Edit">
              <Button
                type="primary"
                ghost
                icon={<EditFilled rev={undefined} />}
                onClick={() => {
                  if (!disableEdit) onEdit();
                }}
              />
            </Tooltip>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete step"
              description="Are you sure to delete this step?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Tooltip title="Delete">
                <Button
                  type="default"
                  style={{ marginLeft: "auto" }}
                  icon={<DeleteOutlined rev={undefined} />}
                  danger
                />
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      </div>
    </>
  );
}
