import { useState } from "react";
import QuestionHeader from "../../../../components/QuestionHeader";
import SectionHeader from "../../../../components/SectionHeader";
import {
  useDeleteExampleProblemMutation,
  useGetExampleProblemQuery,
} from "../../../../features/api/professor/example";
import { ButtonsContainer } from "../../style";
import { StepView2 } from "./Step/StepView";
import { useTopicContext } from "../Context";
import { App, Button, Popconfirm } from "antd";
import { DeleteOutlined, EditFilled, PlusOutlined } from "@ant-design/icons";
import { StepCreate3 } from "./Step/StepCreate";
import { StepEdit3 } from "./Step/StepEdit";

export function ExampleView({
  exampleId,
  afterDelete,
  onEdit,
}: {
  exampleId: number;
  afterDelete?: () => void;
  onEdit?: () => void;
}) {
  const { canEdit, canDelete } = useTopicContext();
  const { message } = App.useApp();

  const {
    data: example,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetExampleProblemQuery(exampleId);

  const [deleteExample, { isLoading: isDeleting }] = useDeleteExampleProblemMutation();

  // TODO: move to redux state
  const [currentEdit, _setCurrentEdit] = useState<number | null>(null);
  const [currentCreate, _setCurrentCreate] = useState<number | null>(null);

  // mutual exclusion for state changes
  // only one edit/create dialog can be open at a time
  const isOpenState = currentEdit === null && currentCreate === null;
  const setCurrentEdit = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentEdit(newState);
  };
  const setCurrentCreate = (newState: number | null) => {
    if (newState === null || isOpenState) _setCurrentCreate(newState);
  };

  if (isSuccess) {
    const handleDelete = () => {
      deleteExample(example.id).then(afterDelete);
    };

    return (
      <>
        <SectionHeader>{example.title}</SectionHeader>
        <QuestionHeader>{example.question}</QuestionHeader>

        <ButtonsContainer>
          {canEdit && (
            <Button type="primary" icon={<EditFilled rev={undefined} />} onClick={onEdit}>
              Edit
            </Button>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete Example"
              description="Are you sure to delete this example?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button
                type="default"
                style={{ marginLeft: "auto" }}
                icon={<DeleteOutlined rev={undefined} />}
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </ButtonsContainer>
        <hr />
        <div>
          {example.steps.map((step, index) => {
            if (canEdit && currentEdit === step.id) {
              return (
                <StepEdit3
                  key={index}
                  data={step}
                  index={index}
                  onDone={() => setCurrentEdit(null)}
                  onCancel={() => setCurrentEdit(null)}
                />
              );
            } else {
              return (
                <StepView2
                  key={index}
                  index={index}
                  onEdit={() => {
                    setCurrentEdit(step.id);
                  } }
                  disableEdit={!isOpenState}
                  disableDelete={!isOpenState}
                  data={step}
                />
              );
            }
          })}
          {canEdit && currentCreate === 1 && (
            <StepCreate3
              exampleId={example.id}
              index={example.steps.length}
              onDone={() => setCurrentCreate(null)}
              onCancel={() => setCurrentCreate(null)}
            />
          )}
        </div>
        {canEdit && (
          <div style={{ paddingBottom: "5em" }}>
            <Button
              type="dashed"
              style={{ marginLeft: "auto" }}
              icon={<PlusOutlined rev={undefined} />}
              onClick={() => setCurrentCreate(1)}
              disabled={!isOpenState}
            >
              Add Step
            </Button>
          </div>
        )}
      </>
    );
  }

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{error}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return <h1>Unexpected Error</h1>;
}
