import ReactQuill from "react-quill";
import { Step } from "../../../../../components/SolutionStep";
import { useDeleteProblemStepMutation } from "../../../../../features/api/professor/problem";
import { Step as ProblemStep } from "../../../../../features/api/professor/types";
import { StepExplanation } from "../../../../Student/Assignment/Example/style";
import { EditIcon, EditToolTip, IconWrapper, TrashcanIcon } from "../../../style";
import { useTopicContext } from "../../Context";
import { Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { ExplanationHeader } from "../../../../../components/QuestionHeader/QuestionHeader";

export default function StepView({
  data: step,
  index,
  onEdit,
  disableEdit = false,
  disableDelete = false,
}: {
  data: ProblemStep;
  index: number;
  onEdit: () => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
}) {
  const { canEdit } = useTopicContext();

  const [deleteStep, { isLoading: isDeleting }] = useDeleteProblemStepMutation();

  const handleDelete = () => {
    if (
      window.confirm("Are you sure you want to DELETE this step? This action cannot be undone!")
    ) {
      deleteStep(step.id);
    }
  };

  return (
    <tr key={step.id} style={{ verticalAlign: "middle" }}>
      <td>
        <Step id={index} expression={step.solution} />
      </td>
      <td>
        <StepExplanation>
          <ReactQuill value={step.hint} theme={"bubble"} readOnly={true} />
        </StepExplanation>
      </td>
      <td>
        <div style={{ display: "inline-block" }}>
          {canEdit && (
            <IconWrapper>
              <EditIcon
                onClick={() => {
                  if (!disableEdit) onEdit();
                }}
              />
              <EditToolTip>Edit</EditToolTip>
            </IconWrapper>
          )}
          {canEdit && (
            <IconWrapper>
              <TrashcanIcon
                onClick={() => {
                  if (!disableDelete) handleDelete();
                }}
              // disabled={isDeleting || isStepDeleting}
              />
              <EditToolTip>Delete</EditToolTip>
            </IconWrapper>
          )}
        </div>
      </td>
    </tr>
  );
}

export function StepView2({
  data: step,
  index,
  onEdit,
  disableEdit = false,
  disableDelete = false,
}: {
  data: ProblemStep;
  index: number;
  onEdit: () => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
}) {
  const { canEdit, canDelete } = useTopicContext();

  const [deleteStep, { isLoading: isDeleting }] = useDeleteProblemStepMutation();

  const handleDelete = () => {
    deleteStep(step.id);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", padding: "1em 0 1em 0" }}>
        <div style={{ flex: "1 1 0", minWidth: "300px" }}>
          <Step id={index} expression={step.solution} />
        </div>
        {/* <div
          style={{ width: "60%" }}
          dangerouslySetInnerHTML={{ __html: step.hint ? step.hint : "" }}
        /> */}
        <div style={{ flex: "2 1 0" }}>
          <ExplanationHeader>{step.hint}</ExplanationHeader>
        </div>
        <div style={{ flex: "1 1 0", maxWidth: "auto", display: "flex", flexDirection: "row" }}>
          {canEdit && (
            <Tooltip title="Edit">
              <Button
                type="primary"
                ghost
                icon={<EditFilled rev={undefined} />}
                onClick={() => {
                  if (!disableEdit) onEdit();
                }}
              />
            </Tooltip>
          )}
          {canDelete && (
            <Popconfirm
              title="Delete step"
              description="Are you sure to delete this step?"
              okText="Yes"
              okButtonProps={{ loading: isDeleting }}
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Tooltip title="Delete">
                <Button
                  type="default"
                  style={{ marginLeft: "auto" }}
                  icon={<DeleteOutlined rev={undefined} />}
                  danger
                />
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      </div>
    </>
  );
}
