import { Form } from "antd";
import {
  useGetProblemQuery,
  useUpdateProblemMutation,
} from "../../../../features/api/professor/problem";
import { TransparentBackground } from "../components/EditModeStyle";
import ProblemForm from "../components/ProblemForm";
import { OutputFormData, ProblemForm2 } from "../components/ProblemForm2";

export default function ProblemEdit({
  problemId,
  onDone,
  onCancel,
}: {
  problemId: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const { data: problem, isSuccess } = useGetProblemQuery(problemId);

  const [updateProblem, { isLoading: isUpdating }] = useUpdateProblemMutation();

  if (isSuccess) {
    return (
      <ProblemForm
        initialData={{ question: problem.question }}
        onCancel={onCancel}
        onSubmit={(data) => {
          updateProblem({ id: problem.id, ...data }).then(onDone);
        }}
        isLoading={isUpdating}
      />
    );
  }
  return null;
}

export function ProblemEdit2({
  problemId,
  onDone,
  onCancel,
}: {
  problemId: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const { data: problem, isSuccess } = useGetProblemQuery(problemId);

  const [updateProblem, { isLoading: isUpdating }] = useUpdateProblemMutation();
  const [exampleForm] = Form.useForm<OutputFormData>();

  if (isSuccess) {
    return (
      <ProblemForm2
        form={exampleForm}
        initialValues={{ question: problem.question }}
        onCancel={onCancel}
        onSubmit={(data) => {
          updateProblem({ id: problem.id, ...data }).then(onDone);
        }}
        onDone={onDone}
        isLoading={isUpdating}
      />
    );
  }
  return null;
}