import { apiSlice } from "../api-slice";
import { Tag } from "../types";
import {
  CreateExampleProblem,
  CreateExampleStep,
  ExampleProblem,
  ExampleStep,
} from "./types";

export const exampleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExampleProblem: builder.query<ExampleProblem, string | number>({
      query: (exampleId) => ({
        url: `instructor/topic/example/${exampleId}`,
      }),
      providesTags: (result, error, exampleId) => {
        return [
          { type: Tag.EXAMPLE_PROBLEM, id: exampleId },
          ...(result?.steps?.map((step) => ({
            type: Tag.EXAMPLE_STEP,
            id: step.id,
          })) || []),
        ];
      },
    }),
    updateExampleProblem: builder.mutation<
      ExampleProblem,
      Partial<ExampleProblem>
    >({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/topic/example/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(
        baseQueryReturnValue: { data: ExampleProblem },
        meta,
        arg
      ) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, example) => [
        { type: Tag.EXAMPLE_PROBLEM, id: example.id },
      ],
    }),
    createExampleProblem: builder.mutation<
      ExampleProblem,
      CreateExampleProblem
    >({
      query(data) {
        return {
          url: `instructor/topic/example`,
          method: "POST",
          body: data,
        };
      },
      transformResponse(
        baseQueryReturnValue: { data: ExampleProblem },
        meta,
        arg
      ) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: [Tag.EXAMPLE_PROBLEM],
    }),
    deleteExampleProblem: builder.mutation<ExampleProblem, number | string>({
      query: (exampleId) => ({
        url: `instructor/topic/example/${exampleId}`,
        method: "DELETE",
      }),
      invalidatesTags: [Tag.EXAMPLE_PROBLEM],
    }),
    createExampleProblemStep: builder.mutation<ExampleStep, CreateExampleStep>({
      query(data) {
        return {
          url: `instructor/topic/example-step`,
          method: "POST",
          body: data,
        };
      },
      transformResponse(
        baseQueryReturnValue: { data: ExampleStep },
        meta,
        arg
      ) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, step) => [
        { type: Tag.EXAMPLE_PROBLEM, id: step.example_problem_id },
      ],
    }),
    updateExampleProblemStep: builder.mutation<
      ExampleStep,
      Partial<ExampleStep>
    >({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/topic/example-step/${id}`,
          method: "PUT",
          body,
        };
      },
      transformResponse(
        baseQueryReturnValue: { data: ExampleStep },
        meta,
        arg
      ) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, exampleStep) => [
        { type: Tag.EXAMPLE_STEP, id: exampleStep.id },
      ],
    }),
    deleteExampleProblemStep: builder.mutation<void, number | string>({
      query: (stepId) => ({
        url: `instructor/topic/example-step/${stepId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, stepId) => [
        {
          type: Tag.EXAMPLE_STEP,
          id: stepId,
        },
      ],
    }),
  }),
});

export const {
  useGetExampleProblemQuery,
  useUpdateExampleProblemMutation,
  useCreateExampleProblemMutation,
  useDeleteExampleProblemMutation,
  useCreateExampleProblemStepMutation,
  useUpdateExampleProblemStepMutation,
  useDeleteExampleProblemStepMutation,
} = exampleApiSlice;
