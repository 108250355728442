import { Form, Modal } from "antd";
import { ProblemStepForm2, OutputFormData } from "../../components/ProblemStepForm2";

export interface EditProblemStepData {
  problemId?: number;
  solution: string;
  hint: string;
}

export interface ModalProblemStepEditProps {
  open: boolean;
  initialValues?: EditProblemStepData;
  index: number;
  onSubmit: (values: EditProblemStepData) => void;
  onCancel: () => void;
  onDone: (problemId: number) => void;
  isLoading: boolean;
}

export function ModalProblemStepEdit({
  open,
  initialValues,
  index,
  onCancel,
  onSubmit,
  onDone,
  isLoading,
}: ModalProblemStepEditProps) {
  const [form] = Form.useForm<OutputFormData>();

  const handleSubmit = async () => {
    try {
      const stepValues = await form.validateFields();
      onSubmit({
        solution: stepValues.solution.trim(),
        hint: stepValues.hint.trim(),
      });
      form.resetFields();
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="Edit Problem Step"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      // onOk={handleSubmit}
      footer={null}
      confirmLoading={isLoading}
    >
      <ProblemStepForm2
        form={form}
        index={index}
        initialValues={
          initialValues && {
            solution: initialValues.solution,
            hint: initialValues.hint,
          }
        }
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  );
}
