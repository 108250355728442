import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../components/Button";

export const CourseControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 12px;
  max-width: 600px;
  margin: 40px auto 20px auto;
`;

export const CourseInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 600px;
  margin: 40px auto;
`;

export const InstructorContainer = styled.div`
  flex: 1;

  & .instructor-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .instructor-name {
    display: inline-flex;
    flex-direction: column;
    font-size: 14px;
  }
`;

export const ColorContainer = styled.div`
  flex: 1;

  & .color-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StudentTable = styled.table`
  font-family: "Roboto", sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 10px;

  border-radius: 10px;

  margin-top: 29px;
  background-color: #d9d9d9;

  &tbody {
    border-radius: 10px;
    overflow: hidden;
  }
  & td,
  th {
    padding: 8px;
    border-radius: 10px;
  }
  /* tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  & tr:hover {
    background-color: #ddd;
  } */

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #d9d9d9;
  }
`;

export const CheckBoxInput = styled.input``;

export const AddStudentLabel = styled.label`
  font-family: "Roboto";
  border-collapse: collapse;

  & ~ & {
    background-color: #f2f2f2;
  }
`;

export const AddStudentButton = styled(Button)``;

// ButtonsContainer
export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center;
  justify-content: space-evenly; */

  max-width: 800px;

  margin-top: 10px;
`;

export const AddFileInput = styled.input.attrs({
  type: "file",
})`
  margin-left: 10px;
`;

export const LinkAllCourse = styled(NavLink)`
  text-align: end;
  color: grey;
  text-decoration: none;
`;

export const CourseLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: stretch;
  //align-items: center;
`;

export const CourseLayoutControl = styled.div`
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 60px;
  //min-width: 800px;
  //width: 100%;
`;

export const CourseListContainer = styled.div`
  flex: auto;
  align-self: center;
  margin: 20px 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 30px;
`;

export const PreviousButtonContainer = styled.div`
  display: flex;
  margin: 10px;
  position: sticky;
  bottom: 20px;
`;

// type ColorPickerProps = {
//   value: string;
//   onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   onClose: () => void;
// };

export const ColorPickerContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ColorPickerInput = styled.input`
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const ColorPickerButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const AddStudentInput = styled.input`
  box-sizing: border-box;
  text-align: center;
  width: 250px;
  height: 35px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 6px;
  margin: 10px;
  transition: all 0.3s ease 0s;
`;

export const NewStudentLabel = styled.label`
  font-family: "Roboto";
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

// CourseTab Management

export const CourseTabPanel = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1000px;
  margin: 100px 50px 20px 50px;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.div<TabProps>`
  cursor: pointer;
  /* background-color: #d9d9d9; */
  background-color: ${(props) => (props.active ? "#2E6EA5" : "#d9d9d9")};
  color: ${(props) => (props.active ? "white" : "black")};
  padding: 20px;
  font-weight: 700;
  display: flex;
  border-radius: 9px;
`;

export const TabContent = styled.div`
  padding: 10px;
  border: 1px solid gray;
  border-top: none;
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  /* padding: 20px; */
`;
export const PanelBody = styled.div`
  width: 100%;
  max-width: 720px;
  box-sizing: border-box;
  padding: 20px;
`;

// table-div
export const ResponsiveTable = styled.ul`
  padding: 0;
  li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #d9d9d9;

    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }
  .col-1 {
    flex-basis: 20%;
  }
  .col-2 {
    flex-basis: 25%;
  }
  .col-3 {
    flex-basis: 20%;
  }
  .col-4 {
    flex-basis: 30%;
  }

  @media all and (max-width: 900px) {
    .table-header {
      display: none;
    }
    .table-row {
    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
`;

// background-color: ${(props) => (props.active ? "gray" : "lightgray")};
// color: ${(props) => (props.active ? "white" : "black")};
