import UserForm, { UserFromData } from "./UserForm";
import { Form, message, Modal, Result, Spin } from "antd";
import { useGetUserQuery, useUpdateUserMutation } from "../../../features/api/user-api";
import { LoadingOutlined } from "@ant-design/icons";

export type UserEditProps = {
  userId: number | null;
  onSubmit: () => void;
  onCancel: () => void;
};

export default function UserEdit({ userId: _userId, onSubmit, onCancel }: UserEditProps) {
  const isOpen = _userId != null;
  const userId = _userId || 0;

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<UserFromData>();

  const {
    data: user,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetUserQuery(userId, { skip: !isOpen });
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  if (isSuccess) {
    form.setFieldsValue({
      institutionalId: user.profile?.institutional_id,
      email: user.user.email,
      firstName: user.profile?.firstname,
      lastName: user.profile?.lastname,
      roles: user.user.roles.slice(-1)[0],
      affiliation: user.profile?.affiliation,
      biography: user.profile?.user_bio,
    });
  }
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateUser({
          id: userId,
          email: values.email,
          username: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          institutional_id: values.institutionalId,
          roles: ["ROLE_USER", values.roles],
          affiliation: values.affiliation,
          user_bio: values.biography,
        })
          .unwrap()
          .then(() => {
            messageApi.success("Changes saved");
            onSubmit();
            form.resetFields();
          })
          .catch((e) => {
            messageApi.error("Failed to save changes");
          });
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title="Edit user"
        okText="Save"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={handleSubmit}
        confirmLoading={isUpdating}
        okButtonProps={{ disabled: !isSuccess }}
      >
        {isError ? (
          <Result
            status="error"
            title="Failed to fetch data for this user"
            subTitle="User does not exist or system is temporary down"
          />
        ) : (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin rev={undefined} />}
            spinning={isLoading}
          >
            <UserForm
              form={form}
              visibility={{
                password: "hidden",
              }}
              disabled={!isSuccess}
            />
          </Spin>
        )}
      </Modal>
    </>
  );
}
