/*
    This component will be used for media querying and stylizing forms 
    of the Login.jsx and ContentForm.jsx. 
*/
export const Devices = {
    mobileS: `(min-width: 576px)`, // Small mobile devices, i.e. smartphones
    mobileM: `(min-width: 768px)`, // Medium mobile devices, i.e. tablets
    mobileL: `(min-width: 922px)`, // Large devices, i.e. desktops
    desktopL: `(min-width: 1200px)`, // Extra large devices, i.e. large desktops
};
