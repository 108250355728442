import { App, Form, Modal, Result, Spin } from "antd";
import {
  useGetInstructorAssignmentQuery,
  useUpdateAssignmentMutation,
} from "../../../features/api/professor/assignment";
import AssignmentFrom, { InputFormData, OutputFormData } from "./AssignmentFrom";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

export interface AssignmentEditProps {
  assignmentId: number | null;
  onSubmit: () => void;
  onCancel: () => void;
}

export default function AssignmentEdit({
  assignmentId: _assignmentId,
  onSubmit,
  onCancel,
}: AssignmentEditProps) {
  const isOpen = _assignmentId != null;
  const assignmentId = _assignmentId || 0;

  const { message } = App.useApp();
  const [form] = Form.useForm<OutputFormData>();

  const {
    data: assignment,
    isSuccess,
    isError,
    isLoading,
    isFetching,
  } = useGetInstructorAssignmentQuery(assignmentId, {
    skip: !isOpen,
  });
  const [updateAssignment, { isLoading: isUpdating }] = useUpdateAssignmentMutation();

  let formInitialValues: InputFormData | undefined;
  if (isSuccess) {
    formInitialValues = {
      courseId: assignment.course_id,
      topicId: assignment.topic_id,
      title: assignment.title,
      date: [dayjs(assignment.start_date), dayjs(assignment.end_date)],
      weight: assignment.weight,
      description: assignment.description,
    };
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        updateAssignment({
          id: assignmentId,
          title: values.title.trim(),
          description: values.description?.trim() || "",
          start_date: values.date[0].toISOString(),
          end_date: values.date[1].toISOString(),
          weight: values.weight,
        })
          .unwrap()
          .then(() => {
            message.success("Changes saved");
            onSubmit();
            form.resetFields();
          })
          .catch(() => {
            message.error("Failed to save changes");
          });
      })
      .catch((info) => {
        /* validation failed */
        console.log("validation", info);
      });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      open={isOpen}
      title="Edit assignment"
      okText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={isUpdating}
      okButtonProps={{ disabled: !isSuccess }}
    >
      {isError ? (
        <Result
          status="error"
          title="Failed to retrieve data for this assignment"
          subTitle="Assignment does not exist or system is temporary down"
        />
      ) : (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin rev={undefined} />}
          spinning={isLoading}
        >
          <AssignmentFrom
            form={form}
            initialValues={formInitialValues}
            visibility={{
              topic: "hidden",
              course: "hidden",
            }}
            disabled={!isSuccess}
          />
        </Spin>
      )}
    </Modal>
  );
}
