import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  NextButton,
  PreviusButton,
} from "../../../../components/Button/Button";
import {
  EditButtonsContainer,
  EditContainer,
  EditContentInput,
  EditText,
  EditTitleInput,
  EditTitleLabel,
} from "./EditModeStyle";

export interface FormData {
  title: string;
  question: string;
}

export default function ExampleForm({
  initialData,
  onCancel,
  onSubmit,
  isLoading,
}: {
  initialData: FormData;
  onCancel: () => void;
  onSubmit: (data: FormData) => void;
  isLoading: boolean;
}) {
  const [title, setTitle] = useState(initialData.title);
  const questionEditorRef = useRef<ReactQuill>(null);

  return (
    <form style={{ overflow: "hidden" }}>
      <EditTitleLabel>
        <EditText>Example Title:</EditText>
        <EditTitleInput
          type="text"
          onChange={(event) => setTitle(event.target.value)}
          value={title}
          disabled={isLoading}
        />
      </EditTitleLabel>
      <br />
      <EditTitleLabel>
        <EditText>Question:</EditText>
        <EditContentInput
          initialValue={initialData.question}
          ref={questionEditorRef}
        />
      </EditTitleLabel>
      <br />
      <EditButtonsContainer>
        <PreviusButton onClick={onCancel} disabled={isLoading}>
          Cancel
        </PreviusButton>
        <NextButton
          onClick={(event) => {
            event.preventDefault();
            onSubmit({
              title,
              question:
                questionEditorRef.current?.value.toString() ||
                initialData.question,
            });
          }}
          disabled={isLoading}
        >
          Save
        </NextButton>
      </EditButtonsContainer>
    </form>
  );
}
