import { EditableMathField } from "react-mathquill";
import styled from "styled-components";

export const SheetContainer = styled.div`
  background-color: antiquewhite;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 500px;
  text-align: center;
`;

export const ExpressionContainer = styled.div`
  margin-left: 60px;
  font-family: "Roboto";
  font-size: larger;
`;

export const EditMathInput = styled(EditableMathField)`
  box-sizing: border-box;
  text-align: center;

  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 4px;

  min-width: 445px;
`;
