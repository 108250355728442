import { Link } from "react-router-dom";
import styled from "styled-components";
import { HiHome } from "react-icons/hi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

type MenuProp = {
  sidebar: boolean;
};
// export const SidebarBackdrop = styled.div<MenuProp>`
//   display: ${({ active }) => (active ? "block" : "none")};
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   max-width: 250px;
//   background-color: #337ab7;
//   left: 0;
//   top: 0;
//   overflow: auto;
// `;

export const SidebarBackdrop = styled.div<MenuProp>`
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 250px;
  background-color: #337ab7;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  top: 0;
  transition: 350ms;
  overflow: auto;
  z-index: 3;
`;

export const SidebarBackdropR = styled.div`
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 250px;
  background-color: #337ab7;
  right: 0;
  top: 0;
  overflow: auto;
  z-index: 3;
`;

export const SidebarList = styled.ul`
  padding: 0;
  list-style: none;
  text-indent: 20px;
`;

export const HomeIcon = styled(HiHome)`
  float: right;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  min-width: 30%;
`;

export const Down = styled(FaAngleDown)`
  float: right;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  min-width: 30%;
`;

export const UpIcon = styled(FaAngleUp)`
  float: right;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  min-width: 30%;
`;

export const SidebarLists = styled.li`
  font-size: 15px;
  color: #fff;
  line-height: 40px;
  text-indent: 25px;
  text-decoration: none;
  &:hover {
    color: #fff;
    background: #2d3e63;
    border-left: 3px solid #e99d1a;
  }
  &:hover a ${HomeIcon} {
    border-left: 3px solid #e99d1a;
  }
  a:hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const SidebarHeader = styled.span`
  text-indent: 20px;
  font-size: 12px;
  color: #fc0;
  line-height: 40px;
  text-transform: uppercase;
`;

export const SidebarEntry = styled(Link)`
  font-size: 15px;
  color: #fff;
  line-height: 40px;
  text-indent: 25px;
  text-decoration: none;
`;

// export const SidebarDropdown = styled(Link)`
//   font-size: 15px;
//   color: #000000;
//   line-height: 40px;
//   text-indent: 25px;
//   text-decoration: none;
//   background: aquamarine;
// `;

export const SidebarListsDropdown = styled.li`
  font-size: 15px;
  color: #000000;
  line-height: 40px;
  text-indent: 25px;
  text-decoration: none;
  background: #86a2bbe1;
  border-left: 3px solid #2d3e63;
  border-bottom: 1px solid #337ab7;
  &:hover {
    color: #fff;
    background: #2d3e63;
    border-left: 3px solid #e99d1a;
  }
`;
