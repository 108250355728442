import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetAllSlices } from "../util";

export interface TokenData {
  token: string;
  expiresIn: number;
}

export interface Auth {
  tokenData?: TokenData;
}

const initialState: Auth = {
  tokenData: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<TokenData>) => {
      const { token, expiresIn } = action.payload;
      state.tokenData = {
        token,
        expiresIn,
      };
    },
    resetAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllSlices, (state) => {
      state = initialState;
    });
  },
});

export default authSlice.reducer;

export const { setAuth, resetAuth } = authSlice.actions;
