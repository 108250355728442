import { RootState } from "../../app/reducers";
import { createSelector } from "@reduxjs/toolkit";

const Nav = (state: RootState) => state.nav;

export const selectLeftNav = createSelector(
  [Nav, (state: RootState, args: void) => args],
  (nav) => {
    return nav.leftDropdown;
  },
);

export const selectRightNav = createSelector(
  [Nav, (state: RootState, args: void) => args],
  (nav) => {
    return nav.rightDropdown;
  },
);
