import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Devices as device } from "../utils/Devices";

export const PanelBody = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding: 15px;
  // font-size: 16px;
  class: "normal-text";
  background-color: rgb(236, 240, 241);
  color: rgb(52, 73, 94);
`;

export const PanelBodyContentSpace = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
`;
export const PanelBodyContent = styled.div`
  // font-size: 18px;
  class: "large-text";
`;

export const PanelFooter = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: all 0.25s ease 0s;
  :hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
`;

export const PanelFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 10px 15px 10px 15px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  :hover {
    color: rgb(52, 73, 94);
    background-color: rgb(241, 196, 15);
  }
`;

export const PanelFooterLabel = styled.span`
  float: left;
  // font-size: 16px;
  class: "normal-text";
`;

export const PanelFooterIcon = styled.span`
  float: right;
`;

export const CoursePanelDiv = styled.div`
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 7%;
`;

export const CoursePanelBorder = styled.div`
  border: thin solid rgb(52, 73, 94);
  border-radius: 4px;
`;

export const PanelHeading = styled.div`
  color: rgb(255, 255, 255);
  background-color: rgb(52, 73, 94);
  padding: 3% 4%;
`;

export const CourseTitleStyle = styled.div`
  font-size: 18px;
  color: rgb(89, 255, 13);
  margin-bottom: 2%;
`;

export const CourseAffiliationStyle = styled.small`
  // font-size: 16px;
  class: "normal-text";
  color: rgb(255, 213, 27);
`;

export const CoursePanelsLayout = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2%;
  flex-flow: wrap;
  align-items: center;
  @media (min-width: 300px) {
    justify-content: center;
  }
`;

export const CoursePanelWrapper = styled.div`
  @media (min-width: 300px) {
    width: 100%;
  }
  @media ${device.mobileS} {
    width: 50%;
  }
  @media ${device.desktopL} {
    width: 33.3%;
  }
`;

export const PostAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: 300px) {
    flex-flow: column;
  }
  @media ${device.desktopL} {
    flex-flow: row;
  }
`;

export const PostPanelDiv = styled.div`
  margin-bottom: 3%;
  margin-top: 3%;
  color: rgb(52, 73, 94);
  @media (min-width: 300px) {
    width: 100%;
    padding: 0 5%;
  }
  @media ${device.desktopL} {
    width: 50%;
    padding: 0 2%;
  }
`;

export const PostPanelBorder = styled.div`
  border: #337ab7 thin solid;
  border-radius: 4px;
`;

export const PostPanelHeader = styled.div`
  color: #000000;
  background-color: #337ab7;
  padding: 10px 15px;
  font-size: 16px;
`;

/**
 * *****************Student Dashboard********************
 */
export const BodyContent = styled.div`
  margin: auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 1em;
`;

export const StyledUl = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  align-items: center; /* center vertically */
  justify-content: left; /* center horizontally */
  gap: 30px 30px;
  width: 100%;
  padding-left: 0;
`;

export const StyledLi = styled.li`
  //padding: 0.5em;
  // margin-right: 0.5%;
  align-self: center;
  cursor: pointer;
`;

export const AssignmentsContainer = styled.div``;

export const AssignmentPanel = styled.div`
  box-sizing: border-box;
  border: 1px solid #c0bbbb;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 450px;
  height: 220px;
  display: flex;
  flex-direction: column;
  overflow: clip;
`;

interface CourseColor {
  courseColor: string;
}

export const AssignmentPanelMark = styled.div<CourseColor>`
  height: 9px;
  background: ${({ courseColor }) => courseColor};
`;

export const AssignmentInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
`;

export const AssignmentTitle = styled.div`
  font-size: large;
  font-weight: bold;
  white-space: nowrap;
  margin: 18px 0 0 20px;
`;

export const AssignmentCourse = styled.div<CourseColor>`
  font-size: medium;
  font-weight: 500;
  margin: 24px 0 0 40px;
  color: ${({ courseColor }) => courseColor};
`;

export const AssignmentDate = styled.div`
  font-size: 12px;
  margin: auto 0 10px 24px;
`;

export const DonutBoxItem = styled.div``;

interface ProgressDoughnutProps {
  progress: number;
}

export const ProgressDonut = styled.div<ProgressDoughnutProps>`
  position: absolute;
  margin-left: 220px;
  margin-top: -20px;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.35s;

  background: conic-gradient(#b5b5b5 ${(props) => props.progress * -3.6 + 360}deg, #55a5e9 0deg);
  &::before {
    content: "";
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background-color: white;
  }
`;

export const LostPointsMarkup = styled.div<ProgressDoughnutProps>`
  background: conic-gradient(#ff0000 ${(props) => props.progress * 3.6}deg, #ededed11 0deg);
  position: relative;
  height: 180px;
  width: 180px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.35s;

  &::before {
    content: "";
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;

export const ProgressValue = styled.div`
  position: absolute;
  font-size: 45px;
  font-weight: 600;
  color: #2e6ea5;
  &.percentage-text {
    font-size: 18px;
    margin: 0;
  }
`;

// #55a5e9 ${(props) => props.progress * 3.6}deg,
