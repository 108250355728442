import { useGetCourseStudentsQuery } from "../../../features/api/professor/course";
import {
  StudentScoreReportAttrs,
  useGetGradesForCourseQuery,
} from "../../../features/api/professor/grades";
import { UserPublicWithProfile } from "../../../features/api/types";
import downloadAndSaveFile from "../../../features/api/save-file";
import { API_URL } from "../../../features/api/api-url";
import { Button, message, Space, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React from "react";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { useGetInstructorAssignmentsByCourseQuery } from "../../../features/api/professor/assignment";
import { getColumn, UserColumns } from "../../../components/Table/columns";

export default function CourseGradeReport({ courseId }: { courseId: number }) {
  const [messageApi, contextHolder] = message.useMessage();

  const queries = {
    grades: useGetGradesForCourseQuery(courseId),
    students: useGetCourseStudentsQuery(courseId),
    assignments: useGetInstructorAssignmentsByCourseQuery(courseId),
  };

  const isSuccess = Object.values(queries).every((q) => q.isSuccess);
  const isLoading = Object.values(queries).some((q) => q.isLoading);
  const isFetching = Object.values(queries).some((q) => q.isFetching);
  const isError = Object.values(queries).some((q) => q.isError);

  const handleReportDownload = () => {
    downloadAndSaveFile({
      url: `${API_URL}/instructor/course/${courseId}/score-report/download`,
      defaultFileName: `grade-report-${courseId}.csv`,
    }).then((result) => {
      if (!result.success) {
        messageApi.error("Unable to download example");
      }
    });
  };

  const columns: ColumnsType<RowDataType> = [
    getColumn.institutionalId(),
    getColumn.email(),
    getColumn.firstName(),
    { ...getColumn.lastName(), defaultSortOrder: "ascend" },
  ];

  const studentGrades: Record<number, RowDataType> = {};

  if (isSuccess) {
    const grades = queries.grades.data!;
    const students = queries.students.data!;
    const assignments = queries.assignments.data!;

    // column for each assignment
    columns.push(
      ...grades.assignments.map(
        ({ assignmentId }): ColumnType<RowDataType> => ({
          title:
            assignments.find((a) => a.id === assignmentId)?.title || `Assignment ${assignmentId}`,
          dataIndex: ["assignments", assignmentId, "earnedPoints"],
          align: "right",
          render: (_, row) => {
            if (row.assignments[assignmentId]) {
              const { earnedPoints, maxPoints } = row.assignments[assignmentId];
              return `${earnedPoints.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}/${maxPoints.toLocaleString("en-US", { maximumFractionDigits: 2 })}`;
            } else {
              return "-";
            }
          },
        }),
      ),
    );

    for (const student of students) {
      studentGrades[student.user.id] = {
        key: student.user.id,
        email: student.user.email,
        institutionalId: student.profile?.institutional_id || null,
        firstName: student.profile?.firstname || null,
        lastName: student.profile?.lastname || null,
        assignments: {},
      };
    }

    for (const a of grades.assignments) {
      for (const s of a.students) {
        if (studentGrades[s.studentId]) {
          studentGrades[s.studentId].assignments[a.assignmentId] = s;
        } else {
          // TODO: new student in course, update all data
        }
      }
    }
  }

  if (isError) {
    messageApi.open({ key: "QUERY_ERROR", type: "error", content: "Error retrieving data" });
  }

  return (
    <>
      {contextHolder}
      <Space direction="vertical" size="large">
        <Button
          type="primary"
          shape="round"
          icon={<DownloadOutlined rev={undefined} />}
          onClick={handleReportDownload}
        >
          Download Report
        </Button>
        <Table
          columns={columns}
          dataSource={Object.values(studentGrades)}
          loading={isLoading || isFetching}
        />
      </Space>
    </>
  );
}

type RowDataType = {
  key: React.Key;
  assignments: Record<number, StudentScoreReportAttrs>;
} & Pick<UserColumns, "institutionalId" | "email" | "firstName" | "lastName">;
