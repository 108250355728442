import { NavLink, useNavigate } from "react-router-dom";
import { NextButton } from "../../../components/Button/Button";
import PageHeader from "../../../components/PageHeader";
import DateMessages from "../../../components/utils/Dates/DateMenssages";
import { useGetInstructorAssignmentsQuery } from "../../../features/api/professor/assignment";
import { useGetCourseQuery } from "../../../features/api/professor/course";
import { useGetTopicQuery } from "../../../features/api/professor/topic";
import { LoadingRing } from "../../Login/style";
import { getAssignmentPath, getCreateAssignmentPath } from "../nav";
import { HWDate, HWDeco, HWDescription, HWPanel, HWTitle } from "../style";

export default function AssignmentLayout() {
  const {
    data: assignments,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetInstructorAssignmentsQuery();

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div>
        <h1>Assignments</h1>
        <LoadingRing />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <PageHeader header="Assignments" subheader="things to do" />

        <p>Error: {JSON.stringify(error)}</p>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <>
        <PageHeader header="Instructor Dashboard" subheader="" />
        <br />
        {assignments.map((assignment) => {
          return (
            <NavLink
              key={assignment.id}
              to={getAssignmentPath(assignment.id)}
              style={{ textDecoration: "none", color: "black" }}
            >
              <HWPanel key={assignment.id}>
                <HWDeco />
                <HWTitle>{assignment.title}</HWTitle>
                <HWDescription>
                  Course:{" "}
                  {assignment.course_id && (
                    <b>
                      <Course id={assignment.course_id} />
                    </b>
                  )}
                  <br />
                  Topic:{" "}
                  {assignment.topic_id && (
                    <b>
                      <Topic id={assignment.topic_id} />
                    </b>
                  )}
                  <br />
                </HWDescription>{" "}
                <HWDate>
                  <DateMessages start_date={assignment.start_date} end_date={assignment.end_date} />
                </HWDate>
              </HWPanel>
            </NavLink>
          );
        })}
        <NextButton onClick={() => navigate(getCreateAssignmentPath())}>Create</NextButton>
      </>
    );
  }
  return (
    <div>
      <h1>Assignments</h1>
      <p>Unexpected error</p>
    </div>
  );
}
function Topic({ id: topicId }: { id: number }) {
  const { data: topic, isSuccess, isLoading, isError, error } = useGetTopicQuery(topicId);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isSuccess) {
    return <span>{topic.title}</span>;
  }

  return <span>N/A</span>;
}

function Course({ id: courseId }: { id: number }) {
  const { data: course, isSuccess, isLoading, isError } = useGetCourseQuery(courseId);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isSuccess) {
    const { id, template, section, semester, year } = course;
    return (
      <span>
        {template.name} {section} {semester} {year}
      </span>
    );
  }
  return <span>N/A</span>;
}
