import { Navigate, Outlet } from "react-router-dom";
import { history } from "../app/history";
import { useAppSelector } from "../app/hooks";

export default function PrivateRoute() {
  const auth = useAppSelector((state) => state.auth.tokenData);

  if (!auth) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return <Outlet />;
}
