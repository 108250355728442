type ApiType = "mock" | "real";

export const CREDENTIALS = false;
// export const API_URL = "http://localhost:18000/api/v1";
// export const API_URL = "https://math-cyle.cs.fiu.edu/api/v1";
//export const API_URL = "https://math-cyle-demo.cs.fiu.edu/api/v1";
const API_URL_STR = `${
  process.env.REACT_APP_MATH_CYLE_API_HOST
    ? process.env.REACT_APP_MATH_CYLE_API_HOST
    : "http://localhost"
}:
    ${
      process.env.REACT_APP_MATH_CYLE_API_PORT
        ? process.env.REACT_APP_MATH_CYLE_API_PORT
        : "18000"
    }
    ${
      process.env.REACT_APP_MATH_CYLE_BASE_PATH
        ? process.env.REACT_APP_MATH_CYLE_BASE_PATH
        : "/api/v1"
    }`;
export const API_URL = API_URL_STR.trim().replace(/\s+/g, "");
export const API_TYPE: ApiType = "real";
