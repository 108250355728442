import { Route, Routes } from "react-router-dom";
import CourseTemplateContext, { CourseTemplateContextType } from "./CourseTemplateContext";
import CourseTemplateList from "./CourseTemplateList";

export default function CourseTemplateManager({
  canCreate = false,
  canEdit = false,
  canDelete = false,
}: Partial<CourseTemplateContextType>) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <CourseTemplateContext canCreate={canCreate} canEdit={canEdit} canDelete={canDelete} />
          }
        >
          <Route index element={<CourseTemplateList />} />
        </Route>
      </Routes>
    </>
  );
}
