import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteIntroPageMutation,
  useGetIntroPageQuery,
} from "../../../../features/api/professor/page";
import { Container } from "./style";
import { IntroDisplay2 } from "./IntroDisplay";
import { IntroEdit2 } from "./IntroEdit";
import { useTopicContext } from "../Context";

export default function IntroPage({
  afterDelete,
}: {
  afterDelete: () => void;
}) {
  const params = useParams();
  const pageId = params.pageId || "";
  const { canEdit, canDelete } = useTopicContext();
  const navigate = useNavigate();

  const {
    data: page,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetIntroPageQuery(pageId);

  const [deleteIntroPage, { isLoading: isDeleting }] = useDeleteIntroPageMutation();

  if (isSuccess) {
    const { id, title, content } = page;
    const handleDelete = () => {
      deleteIntroPage(id)
        .unwrap()
        .then()
        .catch()
        .finally(afterDelete);
    };

    return (
      <>
        <Container>
          <Routes>
            <Route
              index
              element={
                <>
                  <IntroDisplay2
                    data={page}
                    onEdit={() => navigate("edit")}
                    onDelete={handleDelete}
                    isDeleting={isDeleting}
                  />
                </>
              }
            />
            {canEdit && (
              <Route
                path="edit"
                element={
                  <IntroEdit2
                    pageId={page.id}
                    onDone={() => navigate("")}
                    onCancel={() => navigate("")}
                  />
                }
              />
            )}
          </Routes>

        </Container>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <h1>Error</h1>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }
  return <h1>Unexpected Error</h1>;
}
