import styled from "styled-components";
import { Devices as device } from "../../components/utils/Devices";
import { Link, NavLink } from "react-router-dom";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { Button } from "../../components/Button";

{
  /** ************ Panel Footer ******************/
}
export const PanelFooter = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: all 0.25s ease 0s;
  :hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
`;

export const PanelFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10px 15px 10px 15px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  :hover {
    color: rgb(52, 73, 94);
    background-color: #dedb24;
  }
`;

export const PanelFooterLabel = styled.span`
  float: left;
  font-size: 16px;
`;

{
  /** ************ Course ******************/
}

export const CoursePanelBorder = styled.div`
  border: thin solid rgb(52, 73, 94);
  border-radius: 4px;
`;

export const PanelHeading = styled.div`
  color: rgb(255, 255, 255);
  background-color: rgb(52, 73, 94);
  padding: 3% 4%;
`;

export const CourseTitleStyle = styled.div`
  font-size: 18px;
  margin-bottom: 2%;
`;

export const CourseAffiliationStyle = styled.small`
  font-size: 16px;
  color: #c4c4c4;
  display: block;
`;

export const CoursePanelsLayout = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  gap: 30px 30px;
  flex-flow: wrap;
  align-items: center;
  @media (min-width: 300px) {
    justify-content: center;
  }
  /* @media ${device.desktopL} {
    justify-content: space-between;
} */
`;

export const CoursePanelWrapper = styled.div`
  @media (min-width: 300px) {
    width: 100%;
  }
  @media ${device.mobileS} {
    width: 50%;
  }
  @media ${device.desktopL} {
    width: 33.3%;
  }
`;

{
  /** ************ Topic ******************/
}
export const TopicSummaryHeadings = styled.div`
  color: rgb(255, 255, 255);
  background-color: #2e6ea5;
  text-align: center;
  padding: 2% 3%;
  border-radius: 8px;
  font-size: larger;
`;

export const TopicPanelsLayout = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2%;

  flex-flow: wrap;
  align-items: center;
  @media (min-width: 300px) {
    justify-content: center;
  }
`;

export const TopicsPanelWrapper = styled.div`
  @media (min-width: 300px) {
    width: 100%;
  }
  @media ${device.mobileS} {
    width: 70%;
  }
  @media ${device.desktopL} {
    width: 54.3%;
  }
`;

export const TopicOrderedList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const TopicList = styled.li`
  list-style-type: none;
`;

export const TopicLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  padding-bottom: 2%;
  padding: 3%;
  border-radius: 4px;
  &:hover,
  :focus {
    background-color: #319bff2b;
    cursor: pointer;
    text-decoration: none;
    transform: scale(1.1);
  }
`;

export const TopicContainer = styled.div`
  border-radius: 8px;
  padding: 3%;
`;
{
  /** ************ miscellaneous ******************/
}

export const PlusIcon = styled(FaPlus)`
  height: 18px;
  font-size: large;
  transition: all 0.1s ease-in-out;

  :hover {
    text-decoration: none;
    color: inherit;
    transform: scale(1.1);
  }
`;
export const TopicPlusIcon = styled(FaPlus)`
  color: #c8c8c8;
  height: 50px;
  padding-left: 8.21px;
  font-size: x-large;
  transition: all 0.1s ease-in-out;
  :hover {
    text-decoration: none;
    color: inherit;
    transform: scale(1.1);
  }
`;
export const EditToolTip = styled.span`
  visibility: hidden;
  width: 61px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
  font-size: small;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
`;

export const EditIcon = styled(MdModeEditOutline)`
  padding-left: 10px;
  transition: all 0.3s ease-in-out;
  font-size: large;
  display: inline-flex;
  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.4);
  }
`;

export const QuestionEditIcon = styled(MdModeEditOutline)`
  padding-left: 5px;
  font-size: initial;
  display: inline-flex;
`;

export const IconWrapper = styled.div`
  padding-left: 5px;
  position: relative;
  display: inline-block;
  &:hover ${EditToolTip} {
    visibility: visible;
  }
`;

export const TrashcanIcon = styled(FaTrashAlt)`
  margin-left: 29px;
  transition: all 0.3s ease-in-out;
  font-size: large;
  display: inline-flex;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #c50909;
    transform: scale(1.4);
  }
`;

export const QuestionTrashcanIcon = styled(FaTrashAlt)`
  padding-left: 5px;
  font-size: initial;
  display: inline-flex;
`;

export const ButtonsContainer = styled.div`
  bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ButtonsContainerTopic = styled.div`
  display: flex;
  flex-direction: column;
  width: 6em;
  padding: 0 5% 0 0;
  position: relative;
  align-items: left;
  margin-left: auto;
  top: -7em;
`;

{
  /** *********Assignment*********/
}

export const HWSessionContainer = styled.div`
  position: relative;
  margin: 10px 50px 50px 50px;
  /* justify-content: space-around; */

  /* top: 603px; */

  background: rgba(194, 192, 192, 0.24);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const HWPanel = styled.div`
  box-sizing: border-box;
  width: 455px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #c0bbbb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const HWPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const HWDeco = styled.div`
  /* width: 502 px; */
  height: 9px;
  left: 0px;
  top: 1px;
  border-radius: 10px 10px 0 0;
  background: #34495e;
`;

export const HWTitle = styled.div`
  margin: 25px 0 10px 20px;
  font-size: x-large;
`;

export const HWDescription = styled.div`
  margin: 10px 0 10px 35px;
  font-size: large;
`;

export const HWDate = styled.div`
  text-align: end;
  display: flex;
  align-self: flex-end;
  margin-right: 10px;
`;

export const SeeAllLink = styled(NavLink)`
  text-align: end;
  text-decoration: none;
  width: 90%;
  margin-right: 10%;
  color: grey;
`;
