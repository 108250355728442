import { Form } from "antd";
import { useUpdateExampleProblemStepMutation } from "../../../../../features/api/professor/example";
import { ExampleStep } from "../../../../../features/api/professor/types";
import ExampleStepForm from "../../components/ExampleStepForm";
import { ExampleStepForm2, OutputFormData } from "../../components/ExampleStepForm2";

export default function StepEdit({
  data: step,
  index,
  onDone,
  onCancel,
}: {
  data: ExampleStep;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [updateStep, { isLoading: isUpdating }] =
    useUpdateExampleProblemStepMutation();

  return (
    <ExampleStepForm
      index={index}
      initialData={{
        expression: step.expression,
        explanation: step.explanation,
      }}
      onCancel={onCancel}
      onSubmit={(data) => {
        updateStep({ id: step.id, ...data }).then(onDone);
      }}
      isLoading={isUpdating}
    />
  );
}

export function StepEdit3({
  data: step,
  index,
  onDone,
  onCancel,
}: {
  data: ExampleStep;
  index: number;
  onDone: () => void;
  onCancel: () => void;
}) {
  const [updateStep, { isLoading: isUpdating }] =
    useUpdateExampleProblemStepMutation();
    const [form] = Form.useForm<OutputFormData>();

  return (
    <ExampleStepForm2
      form={form}
      index={index}
      initialValues={{
        expression: step.expression,
        explanation: step.explanation,
      }}
      onCancel={onCancel}
      onSubmit={(data) => {
        updateStep({ id: step.id, ...data }).then(onDone);
      } }
    />
  );
}