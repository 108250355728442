import React from "react";
import { AssignmentSummary } from "../../features/api/student/assignment";
import {
  AssignmentPanel,
  AssignmentPanelMark,
  AssignmentsContainer,
  AssignmentInnerPanel,
  DonutBoxItem,
  LostPointsMarkup,
  ProgressDonut,
  ProgressValue,
  StyledLi,
  StyledUl,
  AssignmentTitle,
  AssignmentCourse,
  AssignmentDate,
} from "./style";
import { useGetCourseQuery } from "../../features/api/professor/course";
import weekday from "../utils/Dates/weekday";
import { NavLink } from "react-router-dom";
import { getAssignmentPathById } from "../Course/nav";
import DateMessages from "../utils/Dates/DateMenssages";
import { SummaryPie } from "./AssigmentSummaryPie";
import { displayName } from "react-quill";
import StudentAssignmentTile from "./StudentAssignmentTile";

interface Props {
  assignments: AssignmentSummary[];
}

/**
 * Component displays a Summary box of the pending student's assignments
 *
 * @param AssignmentSummary[]
 * @returns
 */
export default function StudentAssignmentSummary({ assignments }: Props) {
  return (
    <>
      <div>
        <StyledUl>
          {assignments.map((assignment) => (
            <StudentAssignmentTile key={assignment.id} assignment={assignment} />
          ))}
        </StyledUl>
      </div>
    </>
  );
}
