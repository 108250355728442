import {
  AssignmentScoreReportDetailed,
  useGetGradesForAssignmentQuery,
} from "../../../features/api/professor/grades";
import { Result } from "antd";
import React from "react";
import { useGetCourseStudentsQuery } from "../../../features/api/professor/course";
import StudentsTable, { CompleteStudentScoreReport } from "./GradeReport/StudentsTable";
import { PieContainer, StudentProgressContainer, Subtitle } from "./style";
import AverageReport from "./GradeReport/AverageReport";
import StudentProgress from "./GradeReport/StudentProgress";

export default function AssignmentGrades({ assignmentId }: { assignmentId: number }) {
  const gradesQuery = useGetGradesForAssignmentQuery(assignmentId);
  const studentsQuery = useGetCourseStudentsQuery(gradesQuery.data?.courseId || 0, {
    skip: !gradesQuery.isSuccess,
  });

  const isSuccess = gradesQuery.isSuccess && studentsQuery.isSuccess;
  const isLoading = gradesQuery.isLoading || studentsQuery.isLoading;
  const isError = gradesQuery.isError || studentsQuery.isError;

  if (isError) {
    return (
      <Result
        status="error"
        title="Unable to retrieve data"
        subTitle="Please check you connection and try again. Contact system administrator if error persists."
      />
    );
  }

  const data: CompleteStudentScoreReport[] = [];
  if (isSuccess) {
    const students = studentsQuery.data;
    const grades = gradesQuery.data.students;
    for (const student of students) {
      data.push({
        ...student,
        report: grades.find((g) => g.studentId === student.user.id),
      });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "2em",
        minHeight: "1000px",
        marginBottom: "400px",
      }}
    >
      {isSuccess ? <Infographics data={gradesQuery.data} /> : null}
      <StudentsTable data={data} loading={isLoading} />
    </div>
  );
}

function Infographics({ data }: { data: AssignmentScoreReportDetailed }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", paddingTop: "1em" }}>
          <Subtitle>Average Result</Subtitle>
          <PieContainer>
            <AverageReport
              data={data}
              width={200}
              height={200}
              fontSizeA="40px"
              fontSizeT="24px"
              centerX={35}
              centerY={70}
              innerRadius={90}
            />
          </PieContainer>
        </div>
        <div style={{ width: "50%", paddingTop: "1em" }}>
          <Subtitle>Student Progress</Subtitle>
          <div style={{ paddingTop: "2em" }}>
            <StudentProgressContainer>
              <StudentProgress data={data} />
            </StudentProgressContainer>
          </div>
        </div>
      </div>
    </>
  );
}
