import { Form } from "antd";
import PageHeader from "../../../../components/PageHeader";
import Header from "../../../../components/PageHeader/Header";
import { useCreateProblemMutation } from "../../../../features/api/professor/problem";
import { CreateProblemAttributes } from "../../../../features/api/professor/types";
import ProblemForm from "../components/ProblemForm";
import { OutputFormData, ProblemForm2 } from "../components/ProblemForm2"
import { Container } from "./style";

export default function ProblemCreate({
  topicId,
  onDone,
  onCancel,
}: {
  topicId: number;
  onDone: (problemId: number) => void;
  onCancel: () => void;
}) {
  const [createProblem, { isLoading: isCreating }] = useCreateProblemMutation();

  return (
    <>
      <Header>Create a New Problem</Header>
      <ProblemForm
        initialData={{ question: "" }}
        onCancel={onCancel}
        onSubmit={(data) => {
          createProblem({ ...data, topic_id: Number(topicId) })
            .unwrap()
            .then((problem) => onDone(problem.id))
            .catch(onCancel);
        }}
        isLoading={isCreating}
      />
    </>
  );
}

export function ProblemCreate2({
  topicId,
  onDone,
  onCancel,
}: {
  topicId: number;
  onDone: (problemId: number) => void;
  onCancel: () => void;
}) {
  const [createProblem, { isLoading: isCreating }] = useCreateProblemMutation();
  const [form] = Form.useForm<OutputFormData>();

  return (
    <>
      <Container>
        <Header>Create a New Problem Form2</Header>
        <ProblemForm2
          form={form}
          initialValues={{ question: "" }}
          onCancel={onCancel}
          onSubmit={(data) => {
            createProblem({ ...data, topic_id: Number(topicId) })
              .unwrap()
              .then((problem) => onDone(problem.id))
              .catch(onCancel);
          }}
          onDone={onDone}
          isLoading={isCreating}
        />
      </Container>
    </>
  );
}