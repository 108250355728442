import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: 60px auto 0 auto;
`;

export const TextBox = styled.div`
  margin: 0 20px;
  & > p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    color: #1e1e1e;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
`;
