//DOCS: https://github.com/reduxjs/reselect
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/reducers";
import { DEFAULT_EXPRESSION, DEFAULT_MESSAGE_DISPLAY } from "./util";

const allTopics = (state: RootState) => state.topic;

export const selectInputExpression = createSelector(
  [
    allTopics,
    (state: RootState, args: { topicId: string; problemId: string }) => args,
  ],
  (topics, args) => {
    const { topicId, problemId } = args;
    const currentProblem = topics[topicId]?.problems[problemId];
    if (currentProblem) {
      return currentProblem.inputExpression || DEFAULT_EXPRESSION;
    }
    return "";
  }
);

export const selectMessageDisplay = createSelector(
  [
    allTopics,
    (state: RootState, args: { topicId: string; problemId: string }) => args,
  ],
  (topics, args) => {
    const { topicId, problemId } = args;
    const currentProblem = topics[topicId]?.problems[problemId];
    if (currentProblem) {
      return currentProblem.messageDisplay || DEFAULT_MESSAGE_DISPLAY;
    }
    return DEFAULT_MESSAGE_DISPLAY;
  }
);
