import { LoadingRing, StyledSubmitButton } from "./style";

type Props = {
  isIncorrect: boolean;
  isLoading: boolean;
};

export default function SubmitButton({ isIncorrect, isLoading }: Props) {
  return (
    <StyledSubmitButton
      type="submit"
      isIncorrect={isIncorrect}
      disabled={isLoading}
    >
      {isLoading ? (
        <LoadingRing></LoadingRing>
      ) : (
        <svg
          viewBox="0 0 21.482231 22.982298"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <g transform="translate(-83.576787,-25.817251)">
            <path
              d="M 37.269531 0.01953125 A 5.6692913 5.6692913 0 0 0 33.755859 1.6582031 A 5.6692913 5.6692913 0 0 0 33.755859 9.6738281 L 61.837891 37.761719 L -0.001953125 37.761719 L -0.001953125 49.101562 L 61.835938 49.101562 L 33.755859 77.181641 A 5.6692913 5.6692913 0 0 0 33.755859 85.199219 A 5.6692913 5.6692913 0 0 0 41.771484 85.199219 L 79.529297 47.439453 A 5.6698583 5.6698583 0 0 0 79.529297 39.423828 L 41.771484 1.6582031 A 5.6692913 5.6692913 0 0 0 37.269531 0.01953125 z "
              transform="matrix(0.26458333,0,0,0.26458333,83.576787,25.817251)"
            />
          </g>
        </svg>
      )}
    </StyledSubmitButton>
  );
}
