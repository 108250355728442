import { Route, Routes, useNavigate } from "react-router-dom";
import ProblemCreate, { ProblemCreate2 } from "./ProblemCreate";
import ProblemPage, {ProblemPage2, ProblemPage3} from "./ProblemPage";
import { useTopicContext } from "../Context";

export default function ProblemManager({ topicId }: { topicId: number }) {
  const navigate = useNavigate();
  const { canEdit } = useTopicContext();

  return (
    <Routes>
      <Route
        path=":problemId/*"
        element={<ProblemPage3 afterDelete={() => navigate("..")} />}
      />
      {canEdit && (
        <Route
          path="new"
          element={
            <ProblemCreate2
              topicId={topicId}
              onDone={(problemId) => navigate(`${problemId}`)}
              onCancel={() => navigate("..")}
            />
          }
        />
      )}
    </Routes>
  );
}
