import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/reducers";

const Auth = (state: RootState) => state.auth;

export const hasAuth = createSelector(
  [Auth, (state: RootState, args: void) => args],
  (auth, args) => {
    return !!auth.tokenData;
  }
);
