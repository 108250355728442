import PageHeader from "../../../components/PageHeader";
import Courses from "./Courses";
import Topics from "./Topics";

export default function SuperProfessorDashboard() {
  return (
    <>
      <PageHeader header="Super Instructor Dashboard" subheader="" />
      <br />
      {/* ------------- Display Course ------------- */}
      <Courses />
      <br />

      <br />
      <Topics />
      <br />

      <br />

      {/* ------------- Display Assignment ------------- */}

      {/* <Assignments /> */}
      <br />
    </>
  );
}
