import { Course } from "../../../features/api/professor/types";
import { NavLink } from "react-router-dom";
import { getCoursePath } from "../../../pages/Professor/nav";
import {
  CourseDetailed,
  CourseDetailPt2,
  CoursePanel,
  CoursePanelFootr,
  CoursePanelHeader,
  StudentAssignmentIcon,
  StudentIcon,
} from "./style";

export default function InstructorCourseTile({ course }: { course: Course }) {
  const totalAssignments =
    course.activeAssignmentCount + course.scheduledAssignmentCount + course.closedAssignmentCount;

  return (
    <NavLink to={getCoursePath(course.id)} style={{ textDecoration: "none" }}>
      <CoursePanel panelColor={`#${course.color || "2e6ea5"}`}>
        <CoursePanelHeader>
          <p>{course.template.name}</p>
        </CoursePanelHeader>
        <CoursePanelFootr>
          <CourseDetailed>
            Section: <strong>{course.section} </strong>
            <br />
            Semester: <strong>{course.semester.toUpperCase()} </strong>
            <br />
            Year: <strong>{course.year} </strong>
            <br />
          </CourseDetailed>
          <CourseDetailPt2>
            <StudentIcon /> {course.studentCount}
            <br />
            <StudentAssignmentIcon /> {totalAssignments}
          </CourseDetailPt2>
        </CoursePanelFootr>
      </CoursePanel>
    </NavLink>
  );
}
