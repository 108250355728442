import { Route, Routes } from "react-router-dom";
import CourseList from "./CourseList";
import CoursePage from "./CoursePage";
import CourseContext, { CourseContextType } from "./CourseContext";

export default function CourseManager({
  canCreate = false,
  canEdit = false,
  canDelete = false,
  canAddStudent = false,
  canRemoveStudent = false,
  canCreateAssignment = false,
}: Partial<CourseContextType>) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <CourseContext
            canCreate={canCreate}
            canEdit={canEdit}
            canDelete={canDelete}
            canAddStudent={canAddStudent}
            canRemoveStudent={canRemoveStudent}
            canCreateAssignment={canCreateAssignment}
          />
        }
      >
        <Route index element={<CourseList />} />
        <Route path="/:courseId/*" element={<CoursePage />} />
      </Route>
    </Routes>
  );
}
