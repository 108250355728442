import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../features/api/auth-api";
import React from "react";
import { App, Button, Form, Input } from "antd";
import { BackgroundPage, LoginHeading, LoginWrapperDiv, LogoImg } from "./style";
import logo from "../../components/companyLogo.png";

interface FromDataType {
  newPassword: string;
  newPasswordConfirmation: string;
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const id = Number(searchParams.get("id"));

  const [form] = Form.useForm<FromDataType>();
  const [resetPassword, { isLoading: isResetting }] = useResetPasswordMutation();

  const handleSubmit = (values: FromDataType) => {
    resetPassword({
      token: token ? token : " ",
      userId: id,
      password: values.newPassword,
    })
      .unwrap()
      .then(() => {
        form.resetFields();
        navigate("/login");
        message.open({
          type: "success",
          content: "Password reset",
          duration: 6,
        });
      })
      .catch(() => {
        message.open({
          type: "error",
          content: "Failed to reset password",
          duration: 5,
        });
      });
  };

  return (
    <BackgroundPage>
      <LogoImg src={logo} alt="logo" />
      <LoginWrapperDiv>
        <LoginHeading>Reset Password</LoginHeading>
        <Form
          form={form}
          name="reset_password_form"
          layout="vertical"
          style={{ width: "100%", maxWidth: "230px" }}
          initialValues={{
            newPassword: "",
            newPasswordConfirmation: "",
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="newPassword"
            label="New password"
            rules={[{ required: true, message: "Please input your new password!" }]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPasswordConfirmation"
            label="Confirm new password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match!"),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isResetting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </LoginWrapperDiv>
    </BackgroundPage>
  );
}
