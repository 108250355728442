import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetTopicQuery,
  useUpdateTopicMutation,
} from "../../../features/api/professor/topic";
import { TransparentBackground } from "./components/EditModeStyle";
import TopicForm from "./components/TopicForm";

export default function TopicEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const topicId = params.topicId || "";
  const { data: topic, isSuccess } = useGetTopicQuery(topicId);

  const [updateTopic, { isLoading: isUpdating }] = useUpdateTopicMutation();

  const exit = () => navigate("..");

  if (isSuccess) {
    return (
      <TransparentBackground style={{ position: "fixed" }}>
        <TopicForm
          initialData={{ title: topic.title, published: topic.published }}
          onCancel={exit}
          onSubmit={(data) => {
            updateTopic({ id: topic.id, ...data }).then(exit);
          }}
          isProcessing={isUpdating}
        />
      </TransparentBackground>
    );
  }
  return null;
}
